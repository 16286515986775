import { connect } from 'react-redux';

import TaskModal from '../components/TaskModal';

import { deselectTask, postPlanTrack } from '../modules/user/actions';
import * as userSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    dayKey: userSelectors.getSelectedPlanDayKey(state),
    task: userSelectors.getSelectedTask(state),
    planTrack: userSelectors.getPlanTrack(state),
  };
}

const mapDispatchToProps = {
  deselectTask,
  postPlanTrack,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);
