import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { Input, Button } from 'reactstrap';
import moment from 'moment';
import cx from 'classnames';
import get from 'lodash/get';
import flatMap from 'lodash/flatMap';

import priorityChoices from '../config/priority';

import styles from '../styles/myEvents.module.scss';
import getIconForEventName from '../assets/images/myEventsIcons';

import dateIcon from '../assets/images/events-date-icon@2x.png';
import teamIcon from '../assets/images/events-team-icon@2x.png';
import menuIcon from '../assets/images/events-menu-icon@2x.png';
import deleteEventIcon from '../assets/images/my-events-delete-event-icon@2x.png';
import editEventIcon from '../assets/images/my-events-edit-event-icon@2x.png';
import completeEventIcon from '../assets/images/my-events-complete-event-icon@2x.png';

import useOnClickOutside from '../hooks/useOnClickOutside';

function renderOptions(options) {
  return options.map(option => (
    <option value={typeof option === 'string' ? option : option.id}>
      {typeof option === 'string' ? option : option.name}
    </option>
  ));
}


const uniqueFilter = (v, i, a) => a.indexOf(v) === i;

const dropdownConfig = [
  {
    icon: deleteEventIcon,
    title: 'Delete Event',
    link: '#',
  },
  {
    icon: editEventIcon,
    title: 'Edit Event',
    link: '#',
  },
  {
    icon: completeEventIcon,
    title: 'Complete Event',
    link: '#',
  },
];

export function EventCard({
  name,
  firstName,
  lastName,
  priority,
  category,
  teams,
  start,
  end,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setDropdownOpen(false));

  return (
    <div className={styles.eventCard}>
      <div className={styles.header}>
        <div className={styles.tag}>
          <img src={getIconForEventName(category)} />
          {category}
        </div>
        <div
          className={styles.cardMenu}
          ref={dropdownRef}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <img src={menuIcon} title="Menu" />
          <div className={cx(styles.dropdownContainer, dropdownOpen && styles.show)}>
            <div className={styles.dropdownMenu}>
              {dropdownConfig.map(({ link, title, icon, ...rest }) => (
                <a href={link || '#'} {...rest}>
                  <img src={icon} />
                  {title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <h3 className={styles.cardTitle}>{name}</h3>
      <div className={styles.nameTag}>
        <div className={styles.initials}>JS</div>
        <span className={styles.name}>{`${firstName} ${lastName}`}</span>
      </div>
      <div className={styles.date}>
        <img src={dateIcon} />
        {`${moment.utc(start).format('DD/MM/YY')} - ${moment.utc(end).format('DD/MM/YY')}`}
      </div>
      <div className={styles.team}>
        <img src={teamIcon} />
        {teams}
      </div>
      <div className={styles.footer}>
        <div className={styles.progress}>0%</div>
        {priority === 1 && <div className={styles.urgentness}>Urgent</div>}
      </div>
    </div>
  );
}

export default function MyEvents({ title, events, tasks, getEvents, getTasks }) {
  useEffect(() => {
    getTasks();
    getEvents();
  }, []);

  const initialState = {
    type: null,
    priority: null,
    status: null,
    assignedTo: null,
  };

  const [filters, setFilters] = useState(initialState);

  const eventsWithTasks = events.map(event => ({
    ...event,
    tasks: tasks.filter(task => task.parent === event._id),
  }));

  const orderedEvents = [...eventsWithTasks].sort((a, b) => new Date(b.start) - new Date(a.start));
  const mappedEvents = orderedEvents.map((event) => {
    let { name, createdBy = {}, type, tasks, priority, start, end } = event;
    const { firstName, lastName } = createdBy;
    type = type || {};
    const { name: category } = type;
    const teams = flatMap(tasks, task => task.teamAssigned.map(team => get(team, 'name'))).filter(Boolean).filter(uniqueFilter).join(', ');

    return {
      name,
      firstName,
      lastName,
      priority,
      category,
      teams,
      start,
      end,
    };
  });

  const typeOptions = mappedEvents
    .map(event => event.category)
    .filter(Boolean)
    .filter(uniqueFilter);

  const assignedToOptions = flatMap(
    orderedEvents,
    event => flatMap(event.tasks, task => task.teamAssigned.map(team => get(team, 'name'))).filter(Boolean),
  ).filter(uniqueFilter);

  const eventsToRender = mappedEvents
    .filter(event => (filters.type ? event.category === filters.type : true))
    .filter(event => (filters.priority ? event.priority === Number(filters.priority) : true))
    .filter(event => (filters.assignedTo ? event.teams.includes(filters.assignedTo) : true));

  return (
    <div className={styles.container}>
      <div className={styles.filterBar}>
        <Formik
          initialValues={initialState}
          onSubmit={values => setFilters(values)}
          render={({
            values,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <Input
                type="select"
                name="type"
                value={values.type}
                onChange={handleChange}
              >
                <option value="">Event Type</option>
                {renderOptions(typeOptions)}
              </Input>
              <Input
                type="select"
                name="priority"
                value={values.priority}
                onChange={handleChange}
              >
                <option value="">Priority</option>
                {renderOptions(priorityChoices)}
              </Input>
              <Input
                type="select"
                name="status"
                value={values.status}
                onChange={handleChange}
              >
                <option value="">Status</option>
              </Input>
              <Input
                type="select"
                name="assignedTo"
                value={values.assignedTo}
                onChange={handleChange}
              >
                <option value="">Assigned To</option>
                {renderOptions(assignedToOptions)}
              </Input>
              <Button className={styles.button} onClick={handleSubmit}>Apply Filters</Button>
            </>
          )}
        />
      </div>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.grid}>
        {eventsToRender.map(event => <EventCard {...event} />)}
      </div>
    </div>
  );
}
