import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Tether from 'react-tether';
import Slider from 'rc-slider';
import moment from 'moment';
import cx from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';

import styles from '../styles/timeline.module.scss';
import '../styles/slider.scss';

import TaskPanel from './TaskPanel';
import DayNav from './DayNav';
import MeasureScale from './MeasureScale';
import InlineDonut from './charts/InlineDonut';

import { disableTouchScroll, enableTouchScroll } from '../util/disableTouchScroll';

import showLaneInfoIcon from '../assets/images/timeline-user-info-caret@3x.png';
import laneBackArrow from '../assets/images/lane-back-arrow@3x.png';
import laneForwardArrow from '../assets/images/lane-forward-arrow@3x.png';
import closeIcon from '../assets/images/modal-close-icon@3x.png';


const TIME_OFFSET = 1000 * 60 * 60 * 1;
const SLIDER_MIN = 0;
const SLIDER_MAX = 1000;
const MIN_NOW_JUMP = 1000 * 60;
const MIN_LANE_WIDTH = 190;

const interpolate = (value, originalLower, originalUpper, lower, upper) => {
  const ratio = (value - originalLower) / (originalUpper - originalLower);
  return lower + ratio * (upper - lower);
};

function reduceTotalTime(total, col) {
  return total + col.duration;
}

function getTitleClassName(time) {
  const totalTimePerDay = 60 * 7;
  const percentage = Math.round((time / totalTimePerDay) * 100);
  let type;
  if (percentage === 0) {
    type = 'inactive';
  } else if (percentage < 30 || percentage > 95) {
    type = 'danger';
  } else if (percentage >= 30 && percentage <= 60) {
    type = 'mid';
  } else {
    type = 'regular';
  }
  return `runway-title-name-${type}`;
}

function getMorningAndNextDay(dayKey) {
  const morning = moment(dayKey)
    .hour(7)
    .startOf('hour');
  const nextDay = morning.clone().add(1, 'days');
  return [morning.toDate(), nextDay.toDate()];
}

export default class Runway extends Component {
  constructor(props) {
    super(props);
    this.mapSigns = this.mapSigns.bind(this);
    this.mapToBins = this.mapToBins.bind(this);
    this.renderHorizontalTimeGrid = this.renderHorizontalTimeGrid.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.toggleNow = this.toggleNow.bind(this);
    this.goToNow = this.goToNow.bind(this);
    this.checkNow = this.checkNow.bind(this);
    this.renderLaneInfo = this.renderLaneInfo.bind(this);
    this.renderTitles = this.renderTitles.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.renderDays = this.renderDays.bind(this);
    this.renderLaneSwitchArrows = this.renderLaneSwitchArrows.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.mql = window.matchMedia('(max-width: 480px)');
    const isMobile = this.mql.matches;
    this.state = {
      dragging: false,
      isNowOn: true,
      selectedLaneInfo: null,
      activeLanes: [0, isMobile ? 0 : 6],
      isMobile,
      perspectiveScale: 0.5,
    };
  }

  componentDidMount() {
    const {
      dayKey, getPlan, getPlanTrack, setRunwayBounds, selectPlanDay,
    } = this.props;
    const now = moment().subtract((TIME_OFFSET * 2) / 10, 'ms');
    const newDayKey = now.format('YYYY-MM-DD');
    selectPlanDay(newDayKey);
    getPlan(newDayKey);
    getPlanTrack(newDayKey);
    setRunwayBounds(now.toDate(), now.add(TIME_OFFSET * 2, 'ms').toDate());
    this.nowTimer = setTimeout(this.checkNow, MIN_NOW_JUMP);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.mql.addListener(this.mediaQueryChanged);
  }

  componentWillReceiveProps(nextProps) {
    const {
      dayKey, getPlan, getPlanTrack, setRunwayBounds,
    } = this.props;
    if (nextProps.dayKey !== dayKey) {
      let morning;
      if (moment().isSame(nextProps.dayKey, 'day')) {
        morning = moment().subtract((TIME_OFFSET * 2) / 10, 'ms');
        this.setState({ isNowOn: true });
      } else {
        morning = moment(nextProps.dayKey)
          .hour(7)
          .startOf('hour');
        this.setState({ isNowOn: false });
      }
      setRunwayBounds(morning.toDate(), morning.add(TIME_OFFSET * 2, 'ms').toDate());
      getPlan(nextProps.dayKey);
      getPlanTrack(nextProps.dayKey);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nowTimer);
    window.removeEventListener('resize', this.handleResize);
    this.mql.removeListener(this.mediaQueryChanged);
  }

  onSliderChange(value) {
    const [lowerTime, upperTime] = getMorningAndNextDay(this.props.dayKey);
    const interpolatedValue = interpolate(
      value,
      SLIDER_MIN,
      SLIDER_MAX,
      lowerTime.getTime(),
      upperTime.getTime(),
    );
    const newLowerBound = new Date(parseInt(interpolatedValue - TIME_OFFSET, 10));
    const newUpperBound = new Date(parseInt(interpolatedValue + TIME_OFFSET, 10));
    if (!this.state.setWithMonth) {
      this.setState({ isNowOn: false });
      this.props.setRunwayBounds(newLowerBound, newUpperBound);
    } else {
      this.setState({ setWithMonth: false });
    }
  }

  onMouseDown(event) {
    const {
      bounds: { lower },
    } = this.props;
    this.startY = event.nativeEvent.pageY;
    this.startLower = lower;
    const { height } = event.nativeEvent.target.getBoundingClientRect();
    this.runwayHeight = height;
    this.setState({ dragging: true });
  }

  onMouseMove(event) {
    event.preventDefault();
    const { setRunwayBounds } = this.props;
    const diff = event.nativeEvent.pageY - this.startY;
    const lowerTime =
      new Date(this.startLower).getTime() + (diff / this.runwayHeight) * (TIME_OFFSET * 2);
    const upperTime = lowerTime + TIME_OFFSET * 2;
    this.setState({ isNowOn: false });
    setRunwayBounds(new Date(lowerTime), new Date(upperTime));
  }

  onMouseUp() {
    this.setState({ dragging: false });
  }

  onTouchStart(e) {
    disableTouchScroll();
    this.onMouseDown({ nativeEvent: e.nativeEvent.changedTouches[0] });
  }

  onTouchMove(e) {
    this.onMouseMove({
      nativeEvent: e.nativeEvent.changedTouches[0],
      preventDefault: e.nativeEvent.preventDefault.bind(e.nativeEvent),
    });
  }

  onTouchEnd(e) {
    this.onMouseUp({ nativeEvent: e.nativeEvent.changedTouches[0] });
    enableTouchScroll();
  }

  /* eslint-disable */
  handleResize() {
    const maxLanes = Math.floor(window.innerWidth / MIN_LANE_WIDTH);
    this.setState(({ isMobile }) => ({
      activeLanes: [0, isMobile ? 0 : maxLanes - 1],
    }));
  }

  renderDays(selectedDay, goToDay) {
    const dayOffsets = [0, 1, 2, 3, 4, 5, 6];
    const days = dayOffsets.map(offset => {
      const dayMoment = moment().add(offset, 'day');
      const dayKey = dayMoment.format('YYYY-MM-DD');
      const active = selectedDay === dayKey;
      return {
        name: dayMoment.format(this.state.isMobile ? 'ddd' : 'dddd'),
        onClick: () => goToDay(dayKey),
        active,
      };
    });

    const now = [
      {
        name: 'Now',
        onClick: () => this.toggleNow(),
        active: this.state.isNowOn,
        activeClassName: styles.activeNow,
      },
    ];

    return <DayNav items={now.concat(days)} />;
  }

  renderTitles(person, index) {
    const {
      activeLanes: [minLane, maxLane],
      selectedLaneInfo,
    } = this.state;
    if (index < minLane || index > maxLane) return null;

    const numTasks = person.tasks.length;
    const numAlerts = person.tasks.filter(task => moment.utc(task.endTime).isBefore(moment.utc()))
      .length;
    const showLaneInfo = () =>
      this.setState(prevState => ({
        selectedLaneInfo: prevState.selectedLaneInfo === index ? null : index,
      }));
    const isLaneInfoShown = selectedLaneInfo === index;
    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div className={styles.title} ref={ref}>
              <span>{person.name}</span>
              <img src={showLaneInfoIcon} onClick={showLaneInfo} />
            </div>
          )}
        </Reference>
        {ReactDOM.createPortal(
          <Popper placement="top-start">
            {({ ref, style, arrowProps }) =>
              isLaneInfoShown
                ? this.renderLaneInfo(person, ref, style, arrowProps)
                : null
            }
          </Popper>,
          document.body,
        )}
      </Manager>
    );
  }

  mapSigns(items, lowerDate, upperDate) {
    const { plan, selectTask, deselectTask, selectedTask, planTrack = {} } = this.props;
    const {
      activeLanes: [minLane, maxLane],
      perspectiveScale,
    } = this.state;
    const numLanes = Math.min(maxLane - minLane + 1, plan.length);
    return items.map(item => {
      const dateTime = new Date(item.startTime).getTime();
      const lowerDateTime = new Date(lowerDate).getTime();
      const upperDateTime = new Date(upperDate).getTime();

      if (dateTime < lowerDateTime || dateTime > upperDateTime) return null;

      const top = interpolate(dateTime, lowerDateTime, upperDateTime, 100, 4);
      const scale = interpolate(dateTime, lowerDateTime, upperDateTime, 1, perspectiveScale);
      const transform = `scale(${scale}) translateY(calc(100% * (1 - ${scale}) / 2))`;
      this.panelRefs = [];
      return (
        <TaskPanel
          ref={panel => this.panelRefs.push(panel)}
          key={item._id}
          top={top}
          item={item}
          track={planTrack[item._id]}
          transform={transform}
          onMouseWheel={this.onMouseWheel}
          selectedTask={selectedTask}
          selectTask={selectTask}
          deselectTask={deselectTask}
          openPopoverCallback={() => {
            this.panelRefs.forEach(panel => panel && panel.closePopover());
          }}
          numLanes={numLanes}
        />
      );
    });
  }

  onMouseWheel(event) {
    const {
      bounds: { lower },
      setRunwayBounds,
    } = this.props;
    this.setState({ isNowOn: false });
    event.preventDefault();
    event.stopPropagation();
    const lowerTime = new Date(lower).getTime() + event.nativeEvent.deltaY * 1000 * 2;
    const upperTime = lowerTime + TIME_OFFSET * 2;
    setRunwayBounds(new Date(lowerTime), new Date(upperTime));
  }

  mediaQueryChanged() {
    this.setState({ isMobile: this.mql.matches });
  }

  calcSliderValue(lower, upper, lowerMax, upperMax) {
    const lowerTime = new Date(lower).getTime();
    const upperTime = new Date(upper).getTime();
    const sliderTime = (upperTime - lowerTime) / 2 + lowerTime;
    return interpolate(sliderTime, lowerMax, upperMax, SLIDER_MIN, SLIDER_MAX);
  }

  toggleNow() {
    this.setState(({ isNowOn }) => {
      this.setState({ isNowOn: !isNowOn });
      this.goToNow();
    });
  }

  goToNow() {
    const { selectPlanDay, getPlan, getPlanTrack, setRunwayBounds, dayKey } = this.props;
    const now = moment().subtract((TIME_OFFSET * 2) / 10, 'ms');
    const nowDayKey = now.format('YYYY-MM-DD');
    if (dayKey !== nowDayKey) {
      selectPlanDay(nowDayKey);
      getPlan(nowDayKey);
      getPlanTrack(nowDayKey);
    }
    setRunwayBounds(now.toDate(), now.add(TIME_OFFSET * 2, 'ms').toDate());
  }

  checkNow() {
    if (this.state.isNowOn) {
      this.goToNow();
    }
    this.nowTimer = setTimeout(this.checkNow, MIN_NOW_JUMP);
  }

  renderHorizontalTimeGrid(lowerDate, upperDate) {
    const lowerMoment = moment.utc(lowerDate);
    const upperMoment = moment.utc(upperDate);
    const minute = lowerMoment.minute();
    let month = lowerMoment.clone();
    if (minute >= 30) {
      month = month.add(1, 'hour').startOf('hour');
    } else {
      month = month.minute(30).startOf('minute');
    }
    const marks = [];
    while (month.isBefore(upperMoment)) {
      const name = month.format('HH:mm');
      const time = month.valueOf();
      marks.push({ name, time });
      month = month
        .clone()
        .add(30, 'minute')
        .startOf('minute');
    }
    return marks.map(mark => {
      const lowerDateTime = new Date(lowerDate).getTime();
      const upperDateTime = new Date(upperDate).getTime();
      const top = interpolate(mark.time, lowerDateTime, upperDateTime, 100, 4);
      return (
        <Tether
          key={mark.time}
          attachment="middle right"
          targetAttachment="middle left"
          constraints={[
            {
              to: 'scrollParent',
            },
          ]}
        >
          <div className={styles.gridLineTether} style={{ top: `${top}%` }} />
          <div className={styles.gridLine} onWheel={this.onMouseWheel}>
            <span>{mark.name}</span>
          </div>
        </Tether>
      );
    });
  }

  renderNowMark(lowerDate, upperDate) {
    const lowerDateTime = new Date(lowerDate).getTime();
    const upperDateTime = new Date(upperDate).getTime();
    const nowDateTime = new Date().getTime();
    if (lowerDateTime > nowDateTime || upperDateTime < nowDateTime) return null;
    const top = interpolate(nowDateTime, lowerDateTime, upperDateTime, 100, 4);
    return (
      <Tether
        attachment="middle right"
        targetAttachment="middle left"
        constraints={[
          {
            to: 'scrollParent',
          },
        ]}
      >
        <div
          className={cx(styles.gridLineTether, styles.nowGridLineTether)}
          style={{ top: `${top}%` }}
        />
        <div className={styles.gridLine} onWheel={this.onMouseWheel}>
          {moment.utc(nowDateTime).format('HH:mm')}
        </div>
      </Tether>
    );
  }

  mapToBins(plans, lower, upper) {
    const { dayKey } = this.props;
    const {
      activeLanes: [minLane, maxLane],
    } = this.state;
    return plans.map((person, index) => {
      if (index < minLane || index > maxLane) return null;

      return (
        <section className={styles.perspectiveTiles} key={person.name}>
          {this.mapSigns(person.tasks, lower, upper)}
        </section>
      );
    });
  }
  /* eslint-enable */

  renderLaneSwitchArrows() {
    const { plan } = this.props;
    const {
      activeLanes: [minLane, maxLane],
    } = this.state;
    if (plan.length <= maxLane - minLane + 1) return null;

    const moveUp = () => this.setState({ activeLanes: [minLane + 1, maxLane + 1] });
    const moveDown = () => this.setState({ activeLanes: [minLane - 1, maxLane - 1] });

    const downEnabled = minLane !== 0;
    const upEnabled = plan.length > maxLane + 1;

    return (
      <Fragment>
        <div
          onClick={downEnabled ? moveDown : null}
          className={cx(styles.laneSwitchArrow, styles.left)}
        >
          <img src={laneBackArrow} />
        </div>
        <div
          onClick={upEnabled ? moveUp : null}
          className={cx(styles.laneSwitchArrow, styles.right)}
        >
          <img src={laneForwardArrow} />
        </div>
      </Fragment>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderLaneInfo(person, ref, style, arrowProps) {
    // const hideBox = () => this.setState({ selectedLaneInfo: null });
    const alerts = person.tasks.filter(task => moment.utc(task.endTime).isBefore(moment.utc()));
    const tasks = person.tasks.filter(task => moment.utc(task.endTime).isAfter(moment.utc()));

    const utilisation = Math.round((
      person.tasks.reduce((total, task) => total + task.duration, 0)
      / (7 * 60)) * 100);

    const available = person.tasks.length !== 0;

    return (
      <div ref={ref} style={style} className={styles.laneInfoParent}>
        <div className={styles.laneInfoContainer}>
          <div className={styles.laneInfoHeader}>
            <div className={styles.personInfoBox}>
              <img className={styles.avatar} src="https://picsum.photos/100" />
              <div className={styles.nameInfo}>
                <h3>{person.name}</h3>
                <span>{available ? 'Available' : 'Unavailable'}</span>
              </div>
            </div>
            <div className={styles.charts}>
              <div className={styles.chartContainer}>
                <InlineDonut className={styles.chart} value={utilisation} total={100} showPercent />
                <span className={styles.title}>Utilisation</span>
              </div>
              <div className={styles.chartContainer}>
                <InlineDonut className={styles.chart} value={250} total={1000} />
                <span className={styles.title}>Points</span>
              </div>
            </div>
          </div>
          <div className={styles.laneInfoContent}>
            <div className={styles.tasksColumn}>
              <div className={styles.title}>
                <span className={styles.number}>{tasks.length}</span>
                <span className={styles.text}>Tasks</span>
              </div>
              <div className={styles.tasks}>
                {tasks.map(task => (
                  <span>{task.name}</span>
                ))}
              </div>
            </div>
            <div className={styles.tasksColumn}>
              <div className={styles.title}>
                <span className={styles.number}>{alerts.length}</span>
                <span className={styles.text}>Alerts</span>
              </div>
              <div className={styles.tasks}>
                {alerts.map(task => (
                  <span>{task.name}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.closeButton}
          onClick={() => this.setState({ selectedLaneInfo: null })}
        >
          <img src={closeIcon} alt="Close Modal" />
        </div>
      </div>
    );
  }

  render() {
    const {
      plan, bounds, dayKey, selectPlanDay, getPlan,
    } = this.props;
    if (!plan) return null;
    const {
      dragging,
      activeLanes: [minLane, maxLane],
    } = this.state;
    const { lower, upper } = bounds;
    const [lowerTime, upperTime] = getMorningAndNextDay(dayKey);
    const sliderValue = this.calcSliderValue(lower, upper, lowerTime, upperTime);
    const numLanes = Math.min(maxLane - minLane + 1, plan.length);

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {this.renderDays(dayKey, (key) => {
            this.setState({ setWithMonth: true }, () => {
              selectPlanDay(key);
              getPlan(key);
            });
          })}
          <div
            className={styles.perspectiveContainer}
            onWheel={this.onMouseWheel}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
          >
            <div
              className={cx(
                styles.perspective,
                styles[`per-${numLanes}`],
                dragging && 'runway-dragging',
              )}
              role="presentation"
              onMouseDown={this.onMouseDown}
              onMouseMove={dragging ? this.onMouseMove : undefined}
              onMouseUp={dragging ? this.onMouseUp : undefined}
              onMouseLeave={dragging ? this.onMouseUp : undefined}
              onTouchStart={this.onTouchStart}
              onTouchMove={dragging ? this.onTouchMove : undefined}
              onTouchEnd={dragging ? this.onTouchEnd : undefined}
            >
              {this.mapToBins(plan, lower, upper)}
              {this.renderNowMark(lower, upper)}
              {this.renderHorizontalTimeGrid(lower, upper)}
              <MeasureScale onScaleChange={perspectiveScale => this.setState({ perspectiveScale })} />
            </div>
          </div>
          <div className={styles.titlesContainer}>
            {plan.map(this.renderTitles)}
            {this.renderLaneSwitchArrows()}
          </div>
          <div className={styles.sliderContainer}>
            <Slider
              defaultValue={!isNaN(sliderValue) ? sliderValue : 0}
              value={!isNaN(sliderValue) ? sliderValue : 0}
              onChange={this.onSliderChange}
              min={SLIDER_MIN}
              max={SLIDER_MAX}
            />
          </div>
        </div>
      </div>
    );
  }
}

Runway.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  bounds: PropTypes.shape({
    lower: PropTypes.instanceOf(Date),
    upper: PropTypes.instanceOf(Date),
  }).isRequired,
  getEvents: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  setRunwayBounds: PropTypes.func.isRequired,
  dayKey: PropTypes.string.isRequired,
};

Runway.defaultProps = {
  // events: [],
};
