import { push } from 'react-router-redux';
import TYPES from './types';
import API from '../../../api';

import { getAuthToken } from '../../user/selectors';
import { getTasks } from './selectors';

export function clearForm() {
  return (dispatch) => {
    dispatch({
      type: TYPES.clearForm,
    });
    dispatch(push('/dashboard'));
  };
}

export function postingEvent() {
  return {
    type: TYPES.postingEvent,
  };
}

export function removeTask(clientId) {
  return {
    type: TYPES.removeTask,
    payload: { clientId },
  };
}

export function postEventSuccess(choices) {
  return {
    type: TYPES.postEventSuccess,
    payload: { choices },
  };
}

export function postEventFail(error) {
  return {
    type: TYPES.postEventFail,
    payload: {
      error,
    },
  };
}

export function addTask(task) {
  return {
    type: TYPES.addTask,
    payload: {
      task,
    },
  };
}

export function reorderTasks(oldIndex, newIndex) {
  return {
    type: TYPES.reorderTasks,
    payload: {
      oldIndex,
      newIndex,
    },
  };
}

export function postEvent(event) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const tasks = getTasks(state);
    dispatch(postingEvent());
    let resEvent;
    let resTasks;
    try {
      resEvent = await API.Events.postEvent(event, authToken);
      const postTasks = tasks.map(task =>
        API.Tasks.postTask({ ...task, guidanceNotes: resEvent.guidanceNotes, parent: resEvent._id }, authToken));
      resTasks = await Promise.all(postTasks);
    } catch (err) {
      if (err) return dispatch(postEventFail(err));
    }
    if (resEvent.error) return dispatch(postEventFail(resEvent.error));
    return dispatch(postEventSuccess(resEvent));
  };
}
