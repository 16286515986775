import React from 'react';
import cx from 'classnames';

import styles from '../styles/trendTicker.module.scss';

import upIcon from '../assets/images/hq-report-trend-up@2x.png';
import downIcon from '../assets/images/hq-report-trend-down@2x.png';

export default function TrendTicker({
  up, down, same, text, className,
}) {
  return (
    <div
      className={cx(
        styles.container,
        up && styles.up,
        down && styles.down,
        same && styles.same,
        className,
      )}
    >
      <div className={styles.innerContainer}>
        {same ? (
          <div />
        ) : (
          <>
            <img src={up ? upIcon : downIcon} />
            {text}
          </>
        )}
      </div>
    </div>
  );
}
