import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import thunk from 'redux-thunk';

import reducers from '../modules';
import { basename } from '../config/hosts';

// eslint-disable-next-line no-underscore-dangle, no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = { key: 'root', storage };

const persistedReducers = persistCombineReducers(persistConfig, reducers);

export const history = createHistory({ basename });
const routerMiddlewareInstance = routerMiddleware(history);

const store = createStore(
  persistedReducers,
  undefined,
  composeEnhancers(applyMiddleware(routerMiddlewareInstance, thunk)),
);

export const persistor = persistStore(store);

export default store;
