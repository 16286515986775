import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Input, Alert } from 'reactstrap';
import { Formik } from 'formik';

import styles from '../styles/login.module.scss';

export default function Login({ isLoggingIn, submitLogin, error }) {
  return (
    <>
      {error && <Alert className={styles.alert} color="danger">{error}</Alert>}
      <Formik
        initialValues={{ employeeId: '', password: '' }}
        onSubmit={(values) => {
          submitLogin(values.employeeId, values.password);
        }}
        render={({
         values, handleChange, handleBlur, handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className={styles.form}>
            <FormGroup>
              <Input
                name="employeeId"
                value={values.employeeId}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Employee ID"
                className={styles.employeeId}
              />
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Password"
                className={styles.password}
              />
              <button className={styles.button} type="submit" disabled={isLoggingIn}>
                        Login
              </button>
            </FormGroup>
          </Form>
        )}
      />
    </>
  );
}

Login.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  submitLogin: PropTypes.func.isRequired,
  error: PropTypes.string,
};

Login.defaultProps = {
  error: '',
};
