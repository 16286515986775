import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tether from 'react-tether';
import Modal from 'react-modal';
import moment from 'moment';
import cx from 'classnames';

import { HOST, BASE_PATH } from '../api';

import styles from '../styles/taskPanel.module.scss';
import planStyles from '../styles/plan.module.scss';
import timelineStyles from '../styles/timeline.module.scss';

import closeIcon from '../assets/images/modal-close-icon@3x.png';
import taskComplete from '../assets/images/task-modal-success@2x.png';
import taskCompleteButton from '../assets/images/task-modal-yes@2x.png';
import taskUncompleteButton from '../assets/images/task-modal-no@2x.png';
import taskCompletedIcon from '../assets/images/task-complete-icon.png';
import taskUncompleteIcon from '../assets/images/task-uncomplete-icon.png';
import taskJustStartedIcon from '../assets/images/task-modal-started@2x.png';

import { getType, getTypeImage, getTypeColor } from '../util/mockTaskType';

import TaskModal from '../containers/TaskModal';

const pad = n => (`${n}`.length === 1 ? `0${n}` : n);

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(87, 87, 87, 0.59)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
    borderRadius: '5px',
  },
};

const incompleteReasons = [
  'No Time',
  'Takes Too Much Time',
  'Missing Items',
  'Not Clear',
  'Broken',
  'Task Done Before',
];

export default class TaskPanel extends Component {
  constructor(props) {
    super(props);
    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.startTask = this.startTask.bind(this);
    this.showCompletePrompt = this.showCompletePrompt.bind(this);
    this.showIncompletePrompt = this.showIncompletePrompt.bind(this);
    this.state = {
      isPopoverOpen: false,
      completePrompt: false,
      incompletePrompt: false,
      incompleteReason: null,
      justStarted: false,
    };
  }

  openPopover() {
    const { openPopoverCallback, track = {} } = this.props;
    openPopoverCallback();
    if (track.start) this.startTask();
    this.setState({ isPopoverOpen: true });
  }

  closePopover() {
    const { selectedTask, deselectTask } = this.props;
    this.setState({
      isPopoverOpen: false,
      completePrompt: false,
      incompleteReason: null,
      justStarted: false,
    });
    if (selectedTask) deselectTask();
  }

  startTask() {
    const { item, selectTask } = this.props;
    selectTask(item._id);
  }

  showCompletePrompt() {
    this.setState({ completePrompt: true });
  }

  showIncompletePrompt() {
    this.setState({ completePrompt: false, incompletePrompt: true });
  }

  renderPopover() {
    const { item, selectedTask, track = {} } = this.props;
    const { justStarted } = this.state;

    if (selectedTask) {
      return (
        <TaskModal>
          {({ complete, elapsed, finish }) => {
            let content;
            if (justStarted) {
              const left = item.duration - Math.ceil(elapsed / 60);
              const leftHour = pad(left > 0 ? Math.floor(left / 60) : -Math.floor(-left / 60));
              const leftMin = pad(left > 0 ? left % 60 : -left % 60);
              const ahead = left > 0;
              content = (
                <Fragment>
                  <div className={styles.finished}>
                    <div className={styles.successImage}>
                      <img src={taskJustStartedIcon} alt="Complete" />
                    </div>
                    <div className={styles.message}>Task Started</div>
                    <div className={styles.duration}>
                      <span className={cx(styles.finishedIn, ahead && styles.ahead)}>
                        {`${leftHour}h:${leftMin}m`}
                      </span>
                      {ahead ? ' remaining' : ' past'}
                    </div>
                    <button
                      className={styles.button}
                      onClick={this.closePopover}
                    >
                      Close & Continue
                    </button>
                  </div>
                </Fragment>
              );
            } else if (complete && track.end) {
              if (!track.completed) {
                content = (
                  <Fragment>
                    <div className={styles.finished}>
                      <div className={styles.failImage}>
                        <img src={taskUncompleteButton} alt="Not Complete" />
                      </div>
                      <div className={styles.message}>Task Was Not Completed</div>
                    </div>
                  </Fragment>
                );
              } else {
                const finishedIn =
                  item.duration * 60 - moment(track.end).diff(track.start, 'seconds');
                const ahead = finishedIn > 0;
                const finishedInHour = ahead
                  ? Math.floor(finishedIn / (60 * 60))
                  : Math.floor(-finishedIn / (60 * 60));
                const finishedInMin = ahead
                  ? Math.floor((finishedIn / 60) % 60)
                  : Math.floor((-finishedIn / 60) % 60);
                content = (
                  <Fragment>
                    <div className={styles.finished}>
                      <div className={styles.successImage}>
                        <img src={taskComplete} alt="Complete" />
                      </div>
                      <div className={styles.message}>Task Successfully Completed</div>
                      <div className={styles.duration}>
                        <span className={cx(styles.finishedIn, ahead && styles.ahead)}>
                          {`${finishedInHour}h:${finishedInMin}m`}
                        </span>
                        {ahead ? ' ahead of time' : ' late'}
                      </div>
                    </div>
                  </Fragment>
                );
              }
            } else if (this.state.incompletePrompt) {
              const { incompleteReason } = this.state;
              content = (
                <Fragment>
                  <div className={styles.finished}>
                    <div className={styles.message}>Why was the task not completed?</div>
                  </div>
                  <div className={styles.notFinisihedReasons}>
                    {incompleteReasons.map(reason => (
                      <div
                        className={cx(
                          styles.notFinisihedReason,
                          incompleteReason === reason && styles.selected,
                        )}
                        onClick={() => this.setState({ incompleteReason: reason })}
                      >
                        {reason}
                      </div>
                    ))}
                  </div>
                  <button
                    disabled={!incompleteReason}
                    className={cx(styles.button, !incompleteReason && styles.disabled)}
                    onClick={() => {
                      this.closePopover();
                      finish({ completed: false, incompleteReason });
                    }}
                  >
                    Submit Reason
                  </button>
                </Fragment>
              );
            } else {
              const elapsedHour = pad(Math.floor(elapsed / (60 * 60)));
              const elapsedMin = pad(Math.floor((elapsed / 60) % 60));
              const left = item.duration - Math.ceil(elapsed / 60);
              const leftHour = pad(left > 0 ? Math.floor(left / 60) : -Math.floor(-left / 60));
              const leftMin = pad(left > 0 ? left % 60 : -left % 60);
              const overdue = item.duration < elapsed / 60;
              const percent = left > 0 ? (elapsed / 60 / item.duration) * 100 : 100;
              let body;
              if (this.state.completePrompt) {
                body = (
                  <Fragment>
                    <div className={styles.completePrompt}>
                      <div className={styles.message}>Are you sure this task is complete?</div>
                      <div className={styles.options}>
                        <div className={styles.image} onClick={() => finish({ completed: true })}>
                          <img src={taskCompleteButton} alt="Complete" />
                          <span>Yes</span>
                        </div>
                        <div className={styles.image} onClick={this.showIncompletePrompt}>
                          <img src={taskUncompleteButton} alt="Didn't Complete" />
                          <span>No</span>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              } else {
                body = (
                  <Fragment>
                    {item.description ? (
                      <div className={styles.item}>
                        <div className={styles.title}>Description:</div>
                        <div className={styles.content}>{item.description}</div>
                      </div>
                    ) : (
                      <div className={styles.item}>
                        <div className={cx(styles.content, styles.noDesc)}>No description</div>
                      </div>
                    )}
                    {item.guidanceNotes ? (
                      <div className={styles.item}>
                        <div className={styles.title}>Description:</div>
                        <div className={styles.content}>
                          <a
                            href={`${HOST}${BASE_PATH}${item.guidanceNotes}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here to download the instructions PDF
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </Fragment>
                );
              }
              content = (
                <Fragment>
                  <div className={styles.header}>
                    <div className={styles.timeBoxContainer}>
                      <div className={styles.timeBox}>
                        <h4>{moment.utc(item.startTime).format('HH:mm')}</h4>
                        <span>Start Time</span>
                      </div>
                      <span className={styles.inProgress}>In Progress</span>
                    </div>
                    <div className={styles.infoContainer}>
                      <h4>{item.name}</h4>
                      <div className={styles.infoProgress}>
                        <div className={styles.progress}>
                          <div
                            className={cx(
                              styles.progressBar,
                              styles.good,
                              percent < 15,
                              styles.withPadding,
                            )}
                            style={{ width: `${percent}%` }}
                          >
                            <span>{`${elapsedHour}:${elapsedMin}m`}</span>
                          </div>
                        </div>
                        <div className={styles.remaining}>
                          {overdue
                            ? `Overdue - ${leftHour}:${leftMin}m`
                            : `Remaining time - ${leftHour}:${leftMin}m`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.body}>{body}</div>
                  {!this.state.completePrompt && (
                    <button className={styles.button} onClick={this.showCompletePrompt}>
                      Finish Task
                    </button>
                  )}
                  <img
                    className={styles.categoryIcon}
                    src={getTypeImage(item.name)}
                    alt={getType(item.name)}
                  />
                </Fragment>
              );
            }

            return (
              <Fragment>
                {content}
                <div className={styles.closeButton} onClick={this.closePopover}>
                  <img src={closeIcon} alt="Close Modal" />
                </div>
              </Fragment>
            );
          }}
        </TaskModal>
      );
    }

    const durHour = Math.floor(item.duration / 60);
    const durMin = item.duration % 60;

    return (
      <Fragment>
        <div className={styles.header}>
          <div className={styles.timeBoxContainer}>
            <div className={styles.timeBox}>
              <h4>{moment.utc(item.startTime).format('HH:mm')}</h4>
              <span>Start Time</span>
            </div>
            {moment.utc(item.startTime).isBefore(moment()) && <span>Urgent</span>}
          </div>
          <div className={styles.infoContainer}>
            <h4>{item.name}</h4>
            <div className={styles.infoProgress}>
              <div className={styles.progress}>
                <div className={styles.progressBar}>Not started</div>
              </div>
              <div className={styles.remaining}>{`Remaining time - ${durHour}:${durMin}m`}</div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          {item.description ? (
            <div className={styles.item}>
              <div className={styles.title}>Description:</div>
              <div className={styles.content}>{item.description}</div>
            </div>
          ) : (
            <div className={styles.item}>
              <div className={cx(styles.content, styles.noDesc)}>No description</div>
            </div>
          )}
          {item.guidanceNotes ? (
            <div className={styles.item}>
              <div className={styles.title}>Description:</div>
              <div className={styles.content}>
                <a
                  href={`${HOST}${BASE_PATH}${item.guidanceNotes}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to download the instructions PDF
                </a>
              </div>
            </div>
          ) : null}
        </div>
        <button
          className={styles.button}
          onClick={() => {
            this.setState({ justStarted: true });
            this.startTask();
          }}
        >
          Start Task
        </button>
        <img
          className={styles.categoryIcon}
          src={getTypeImage(item.name)}
          alt={getType(item.name)}
        />
        <div className={styles.closeButton} onClick={this.closePopover}>
          <img src={closeIcon} alt="Close Modal" />
        </div>
      </Fragment>
    );
  }

  render() {
    const {
      key, top, item, transform, onMouseWheel, track = {}, numLanes,
    } = this.props;
    const { isPopoverOpen } = this.state;
    const time = moment.utc(item.startTime).format('hh:mm');
    let typeImage = getTypeImage(item.name);

    if (track.end) {
      typeImage = track.completed ? taskCompletedIcon : taskUncompleteIcon;
    }

    const timeMoment = moment.utc(item.startTime);
    const zIndex = 24 * 60 - timeMoment.diff(timeMoment.clone().startOf('day'), 'minutes');

    return (
      <Tether
        key={key}
        attachment="bottom center"
        constraints={[
          {
            to: 'scrollParent',
          },
        ]}
        style={{ zIndex }}
      >
        <div ref={this.setTarget} className="runway-item-tether" style={{ top: `${top}%` }} />
        {!isPopoverOpen && (
          <div onWheel={onMouseWheel}>
            <div
              className={cx(
                timelineStyles.taskPanel,
                timelineStyles[getType(item.name)],
                timelineStyles[`per-${numLanes}`],
                track.end && timelineStyles.completed,
                track.start && !track.end && timelineStyles.started,
              )}
              style={{ transform }}
              onClick={this.openPopover}
              onWheel={onMouseWheel}
            >
              {moment.utc(item.startTime).isBefore(moment()) &&
                !track.end && (
                  <div className={cx(planStyles.urgentHeader, planStyles[getType(item.name)])}>
                    URGENT
                  </div>
                )}
              <div className={planStyles.taskHeader} style={{ color: getTypeColor(item.name) }}>
                <span className={planStyles.taskHeaderTime}>{time}</span>
                <img src={typeImage} alt={getType(item.name)} />
              </div>
              <span className={cx(planStyles.taskName, styles.taskName)}>{item.name}</span>
            </div>
          </div>
        )}
        <Modal
          className={cx(styles.modal, styles[getType(item.name)])}
          isOpen={isPopoverOpen}
          style={customStyles}
          onRequestClose={this.closePopover}
        >
          {this.renderPopover()}
        </Modal>
      </Tether>
    );
  }
}

TaskPanel.propTypes = {
  key: PropTypes.string.isRequired,
  transform: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  onMouseWheel: PropTypes.func.isRequired,
  selectTask: PropTypes.func.isRequired,
  deselectTask: PropTypes.func.isRequired,
  openPopoverCallback: PropTypes.func,
  numLanes: PropTypes.number.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }).isRequired,
};

TaskPanel.defaultProps = {
  openPopoverCallback: () => null,
};
