const data = [
  {
    title: 'Day',
    data: [
      { name: 'Management', value: 17 },
      { name: 'Core Operations', value: 15 },
      { name: 'Legal to Trade', value: 10 },
      { name: 'Facilities', value: 21 },
      { name: 'Health & Safety', value: 20 },
    ],
  },
  {
    title: 'Week',
    data: [
      { name: 'Management', value: 15 },
      { name: 'Core Operations', value: 17 },
      { name: 'Legal to Trade', value: 12 },
      { name: 'Facilities', value: 21 },
      { name: 'Health & Safety', value: 24 },
    ],
  },
  {
    title: 'Month',
    data: [
      { name: 'Management', value: 10 },
      { name: 'Core Operations', value: 10 },
      { name: 'Legal to Trade', value: 12 },
      { name: 'Facilities', value: 25 },
      { name: 'Health & Safety', value: 23 },
    ],
  },
];

export default data;
