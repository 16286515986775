import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';
import { arrayMove } from 'react-sortable-hoc';

import TYPES from './types';

export const initialState = {
  postingEvent: false,
  postingEventError: null,
  tasks: [],
};

export default function formsEvents(state = initialState, action) {
  switch (action.type) {
    case TYPES.postingEvent: {
      return { ...state, postingEvent: true, postingEventError: null };
    }

    case TYPES.postEventSuccess: {
      const { event } = action.payload;
      return {
        ...state,
        postingEvent: false,
        postingEventError: null,
        // ...choices,
      };
    }

    case TYPES.postEventFail: {
      const { error } = action.payload;
      return {
        ...state,
        postingEvent: false,
        postingEventError: error,
      };
    }

    case TYPES.addTask: {
      const { task } = action.payload;
      const oldTask = state.tasks.find(t => t.clientId === task.clientId);
      return {
        ...state,
        tasks: oldTask
          ? state.tasks.map(t => (t.clientId === task.clientId ? task : t))
          : [...state.tasks, task],
      };
    }

    case TYPES.removeTask: {
      const { clientId } = action.payload;
      return {
        ...state,
        tasks: state.tasks.filter(task => task.clientId !== clientId),
      };
    }

    case TYPES.reorderTasks: {
      const { oldIndex, newIndex } = action.payload;
      return {
        ...state,
        tasks: arrayMove(state.tasks, oldIndex, newIndex),
      };
    }

    case TYPES.clearForm: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
