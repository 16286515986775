import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Label, Input, Button } from 'reactstrap';
import { Formik } from 'formik';
import Dropzone from 'react-dropzone';
import { DayPickerRangeController } from 'react-dates';
import Tether from 'react-tether';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import 'react-dates/lib/css/_datepicker.css';

import TaskForm from '../containers/TaskForm';
import ApprovalList from './ApprovalList';
import SelectStores from './SelectStores';
import TaskOrder from './TaskOrder';

import eventFormSchema, { isRequired } from '../validation/eventForm';

import dragDropUploadSign from '../assets/images/drag-drop-upload-sign@2x.png';

import '../styles/EventForm.css';
import '../styles/reactDates.scss';
import styles from '../styles/eventForm.module.scss';

const STAGES = 4;

const STAGE_TITLES = {
  1: 'Event Setup',
  2: 'Add Stores',
  3: 'Add Tasks',
  4: 'Add Approvers',
};

const priorityChoices = [
  { id: 5, name: 'P5 - Optional' },
  { id: 4, name: 'P4 - Low' },
  { id: 3, name: 'P3 - Medium' },
  { id: 2, name: 'P2 - High' },
  { id: 1, name: 'P1 - Urgent' },
];

function renderOptions(options) {
  return options.map(option => <option value={option.id}>{option.name}</option>);
}

function renderError(error, touched) {
  return touched && error ? <div className={styles.formError}>{error}</div> : null;
}

export default class EventForm extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.addTask = this.addTask.bind(this);
    this.editTask = this.editTask.bind(this);
    this.removeTask = this.removeTask.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      pdfFile: undefined,
      stage: 1,
      focusedEventRangeField: null,
      eventFormValues: {},
      approvalList: [],
      displayTaskEdit: true,
    };
  }

  componentDidMount() {
    this.props.getChoices();
  }

  onDrop(acceptedFiles) {
    console.log(acceptedFiles);
    if (acceptedFiles.length) {
      this.setState({ pdfFile: acceptedFiles[acceptedFiles.length - 1] });
    }
  }

  addTask() {
    this.setState({
      displayTaskEdit: true,
      taskEditMode: false,
      taskValuesToEdit: null,
    });
  }

  editTask(clientId) {
    this.setState({
      displayTaskEdit: true,
      taskEditMode: true,
      taskValuesToEdit: this.props.tasks.find(task => task.clientId === clientId),
    });
  }

  removeTask(clientId) {
    const { tasks = [] } = this.props;
    let { displayTaskEdit } = this.state;
    const { taskValuesToEdit } = this.state;
    if (taskValuesToEdit && taskValuesToEdit.clientId === clientId) {
      displayTaskEdit = false;
    }
    if (tasks.length === 1) {
      displayTaskEdit = true;
    }
    this.setState({ taskEditMode: false, taskValuesToEdit: null, displayTaskEdit });
    this.props.removeTask(clientId);
  }

  submitForm() {
    const {
      submitEventForm, clearForm, getEvents, getTasks,
    } = this.props;
    const { eventFormValues, selectedStores, pdfFile } = this.state;

    submitEventForm({
      ...eventFormValues,
      pdfFile,
      storesSelected: selectedStores.map(store => store.value),
    });
    clearForm();
    getEvents();
    getTasks();
    this.setState({
      pdfFile: undefined,
      stage: 1,
      eventFormValues: {},
      approvalList: [],
      selectedStores: [],
    });
  }

  renderProgress() {
    const { stage } = this.state;
    const borderDist = `${100 / STAGES / 2}%`;
    return (
      <div className={styles.progressContainer}>
        <div className={styles.border} style={{ left: borderDist, right: borderDist }} />
        {[...Array(STAGES)].map((v, i) => (
          <div
            className={cx(styles.item, stage > i + 1 && styles.done)}
            onClick={() => stage > i + 1 && this.setState({ stage: i + 1 })}
          >
            <div
              className={cx(
                styles.number,
                stage > i && styles.active,
                stage > i + 1 && styles.done,
              )}
            >
              {i + 1}
            </div>
            <div className={styles.text}>{STAGE_TITLES[i + 1]}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const {
      stage,
      eventFormValues,
      pdfFile,
      focusedEventRangeField,
      displayTaskEdit,
      selectedStores = [],
    } = this.state;
    const {
      isSubmittingEventForm,
      error,
      stores,
      eventTypes,
      tasks = [],
      eventPurposes,
      reorderTasks,
    } = this.props;

    if (stores === undefined) return null;

    let content;
    switch (stage) {
      case 1: {
        content = (
          <Fragment>
            <Formik
              validationSchema={eventFormSchema}
              initialValues={{
                name: eventFormValues.name || '',
                priority:
                  eventFormValues.priority || (priorityChoices[2] ? priorityChoices[2].id : ''),
                start: eventFormValues.start || new Date(),
                end: eventFormValues.end || new Date(),
                type: eventFormValues.type || (eventTypes[0] ? eventTypes[0].id : ''),
                description: eventFormValues.description || '',
                purpose: eventFormValues.purpose || (eventPurposes[0] ? eventPurposes[0].id : ''),
              }}
              onSubmit={(values) => {
                this.setState({ eventFormValues: values, stage: 2 });
              }}
              render={({
                dirty,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form onSubmit={handleSubmit} className={styles.form}>
                  <div className={styles.formRow}>
                    <div className={styles.formCol}>
                      <Label for="name">
                        Event Name
                        {isRequired.name && '*'}
                      </Label>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={touched.name ? !errors.name : undefined}
                      />
                      {renderError(errors.name, touched.name)}
                      <Label for="priority">
                        Priority
                        {isRequired.priority && '*'}
                      </Label>
                      <Input
                        type="select"
                        name="priority"
                        value={values.priority}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={touched.priority ? !errors.priority : undefined}
                      >
                        {renderOptions(priorityChoices)}
                      </Input>
                      {renderError(errors.priority, touched.priority)}
                      <Label for="start">
                        Start Date
                        {isRequired.start && '*'}
                      </Label>
                      <Tether
                        attachment="top left"
                        targetAttachment="bottom left"
                        constraints={[
                          {
                            to: 'scrollParent',
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          value={
                            values.start && moment.utc(values.start).format('dddd, MMMM Do YYYY')
                          }
                          onFocus={() => this.setState({ focusedEventRangeField: START_DATE })}
                        />
                        {Boolean(focusedEventRangeField) && (
                          <div className={styles.rangePicker}>
                            <DayPickerRangeController
                              startDate={moment.utc(values.start)}
                              endDate={moment.utc(values.end)}
                              onDatesChange={({ startDate, endDate }) => {
                                setFieldValue('end', endDate && endDate.toDate());
                                setFieldValue('start', startDate && startDate.toDate());
                              }}
                              focusedInput={focusedEventRangeField}
                              onFocusChange={focusedInput =>
                                this.setState({ focusedEventRangeField: focusedInput })
                              }
                              onOutsideClick={() => this.setState({ focusedEventRangeField: null })}
                              isOutsideRange={day => (day ? day.isSameOrBefore() : true)}
                              minimumNights={0}
                              numberOfMonths={2}
                            />
                          </div>
                        )}
                      </Tether>
                      {renderError(errors.start, touched.start)}
                      <Label for="description">
                        Description
                        {isRequired.description && '*'}
                      </Label>
                      <Input
                        name="description"
                        type="textarea"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={touched.description ? !errors.description : undefined}
                      />
                      {renderError(errors.description, touched.description)}
                    </div>
                    <div className="event-form-col">
                      <Label for="type">
                        Event Type
                        {isRequired.type && '*'}
                      </Label>
                      <Input
                        type="select"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={touched.type ? !errors.type : undefined}
                      >
                        {renderOptions(eventTypes)}
                      </Input>
                      {renderError(errors.type, touched.type)}
                      <Label for="purpose">
                        Purpose
                        {isRequired.purpose && '*'}
                      </Label>
                      <Input
                        type="select"
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        valid={touched.purpose ? !errors.purpose : undefined}
                      >
                        {renderOptions(eventPurposes)}
                      </Input>
                      {renderError(errors.purpose, touched.purpose)}
                      <Label for="end">
                        End Date
                        {isRequired.end && '*'}
                      </Label>
                      <Input
                        type="text"
                        value={
                          values.end ? moment.utc(values.end).format('dddd, MMMM Do YYYY') : ''
                        }
                        onFocus={() => this.setState({ focusedEventRangeField: END_DATE })}
                      />
                      {renderError(errors.end, touched.end)}
                      <Label for="guidance">
                        Upload Guidance Notes
                        {isRequired.guidance && '*'}
                      </Label>
                      <Dropzone
                        accept="application/pdf"
                        className={styles.dropZone}
                        activeClassName={styles.accept}
                        rejectClassName={styles.reject}
                        onDrop={this.onDrop}
                      >
                        {pdfFile ? (
                          <span>
                            {pdfFile.name} ({pdfFile.size / 10e5}
                            KB)
                          </span>
                        ) : (
                          <div className={styles.uploadSign}>
                            <img src={dragDropUploadSign} />
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <Button
                      className={styles.button}
                      color="primary"
                      type="submit"
                      disabled={!dirty || !isEmpty(errors)}
                    >
                      Save & Continue
                    </Button>
                  </div>
                </Form>
              )}
            />
          </Fragment>
        );
        break;
      }
      case 2: {
        content = (
          <Fragment>
            <div className={styles.form}>
              <SelectStores
                stores={stores}
                selectedStores={selectedStores}
                onListChange={selectedStores => this.setState({ selectedStores })}
              />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                color="primary"
                disabled={!selectedStores.length}
                onClick={() => this.setState({ stage: 3 })}
              >
                Save & Continue
              </Button>
            </div>
          </Fragment>
        );
        break;
      }
      case 3: {
        content = (
          <Fragment>
            {Boolean(tasks.length) && (
              <Fragment>
                <label>Task Order</label>
                <TaskOrder
                  axis="xy"
                  distance={30}
                  tasks={tasks}
                  editTask={this.editTask}
                  removeTask={this.removeTask}
                  onSortEnd={({ oldIndex, newIndex }) => reorderTasks(oldIndex, newIndex)}
                  onSortStart={() => console.log('sortstart')}
                />
              </Fragment>
            )}
            {displayTaskEdit ? (
              <TaskForm
                edit={this.state.taskEditMode}
                valuesToEdit={this.state.taskValuesToEdit}
                removeTask={this.removeTask}
                constraints={[eventFormValues.start, eventFormValues.end]}
                onSubmit={() => this.setState({ displayTaskEdit: false })}
              />
            ) : (
              <div className={cx(styles.buttonContainer, styles.left)}>
                <Button
                  color="primary"
                  className={cx(styles.button, styles.green)}
                  onClick={this.addTask}
                >
                  Add Another Task
                </Button>
                <Button
                  className={styles.button}
                  color="primary"
                  onClick={() => this.setState({ stage: 4 })}
                  disabled={tasks.length === 0}
                >
                  Save & Continue
                </Button>
              </div>
            )}
          </Fragment>
        );
        break;
      }
      case 4: {
        content = (
          <Fragment>
            <div className={styles.form}>
              <ApprovalList onListChange={approvalList => this.setState({ approvalList })} />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                color="primary"
                onClick={this.submitForm}
                disabled={
                  !tasks.length ||
                  !this.state.approvalList.length ||
                  !eventFormValues ||
                  isSubmittingEventForm
                }
              >
                Submit for Approval
              </Button>
            </div>
          </Fragment>
        );
        break;
      }
      default:
        content = null;
    }
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>Add New Event</h2>
        <div className={styles.box}>
          <div className={styles.content}>
            {this.renderProgress()}
            {content}
          </div>
        </div>
      </div>
    );
  }
}

EventForm.propTypes = {
  isSubmittingEventForm: PropTypes.bool.isRequired,
  submitEventForm: PropTypes.func.isRequired,
  getChoices: PropTypes.func.isRequired,
  error: PropTypes.string,
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  eventPurposes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

EventForm.defaultProps = {
  error: '',
  eventTypes: [],
  eventPurposes: [],
};
