import { connect } from 'react-redux';

import EventForm from '../components/EventForm';

import { getEvents, getTasks } from '../modules/user/actions';
import { postEvent, clearForm, removeTask, reorderTasks } from '../modules/forms/events/actions';
import { getChoices } from '../modules/forms/choices/actions';
import * as eventSelectors from '../modules/forms/events/selectors';
import * as choicesSelectors from '../modules/forms/choices/selectors';

function mapStateToProps(state) {
  return {
    isSubmittingEventForm: eventSelectors.getIsPostingEvent(state),
    error: eventSelectors.getPostingEventError(state) ? 'Something went wrong' : '',
    stores: choicesSelectors.getStores(state),
    eventTypes: choicesSelectors.getEventTypes(state),
    eventPurposes: choicesSelectors.getEventPurposes(state),
    tasks: eventSelectors.getTasks(state),
  };
}

const mapDispatchToProps = {
  submitEventForm: postEvent,
  getChoices,
  clearForm,
  removeTask,
  getEvents,
  getTasks,
  reorderTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
