const data = [
  {
    title: 'Day',
    data: [
      { label: 'Draft', value: 12 },
      { label: 'Approved', value: 20 },
      { label: 'Live', value: 26 },
      { label: 'In Progress', value: 18 },
      { label: 'Overdue', value: 30 },
      { label: 'Completed', value: 15 },
    ],
  },
  {
    title: 'Week',
    data: [
      { label: 'Draft', value: 18 },
      { label: 'Approved', value: 21 },
      { label: 'Live', value: 20 },
      { label: 'In Progress', value: 22 },
      { label: 'Overdue', value: 20 },
      { label: 'Completed', value: 17 },
    ],
  },
  {
    title: 'Month',
    data: [
      { label: 'Draft', value: 40 },
      { label: 'Approved', value: 52 },
      { label: 'Live', value: 33 },
      { label: 'In Progress', value: 55 },
      { label: 'Overdue', value: 27 },
      { label: 'Completed', value: 30 },
    ],
  },
];

export default data;
