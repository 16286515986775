import { connect } from 'react-redux';

import EventsGrid from '../components/EventsGrid';

import { getEvents, getTasks, selectEvent, deselectEvent } from '../modules/user/actions';
import * as userSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    events: userSelectors.getMyEvents(state),
    tasks: userSelectors.getTasks(state),
    title: 'My Events',
  };
}

const mapDispatchToProps = {
  getEvents,
  getTasks,
  selectEvent,
  deselectEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsGrid);
