import moment from 'moment';
import TYPES from './types';

const currentMonth = moment();
const lowerBound = currentMonth.clone().startOf('month').toDate();
const upperBound = currentMonth.clone().endOf('month').toDate();

export const initialState = {
  employeeId: undefined,
  authToken: undefined,
  loggingIn: false,
  loginError: null,
  events: [],
  tasks: [],
  plan: [],
  planTrack: {},
  filter: {},
  bounds: { lower: lowerBound, upper: upperBound },
  selectedEventId: undefined,
  selectedTaskId: undefined,
  selectedPlanDayKey: moment().format('YYYY-MM-DD'),
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case TYPES.postingLogin: {
      return { ...state, postingLogin: true, loginError: null };
    }

    case TYPES.postLoginSuccess: {
      const { authToken, user: userData } = action.payload;
      return {
        ...state,
        ...userData,
        postingLogin: false,
        authToken,
      };
    }

    case TYPES.postLoginFail: {
      const { error } = action.payload;
      return {
        ...state,
        postingLogin: false,
        loginError: error,
      };
    }

    case TYPES.getEventsSuccess: {
      const { events } = action.payload;
      return {
        ...state,
        events,
      };
    }

    case TYPES.getTasksSuccess: {
      const { tasks } = action.payload;
      return {
        ...state,
        tasks,
      };
    }

    case TYPES.getPlanSuccess: {
      const { plan, filter } = action.payload;
      return {
        ...state,
        plan,
        filter,
      };
    }

    case TYPES.getPlanTrackSuccess: {
      const { planTrack } = action.payload;
      return {
        ...state,
        planTrack,
      };
    }

    case TYPES.setFilter: {
      const { filter } = action.payload;
      return {
        ...state,
        filter,
      };
    }

    case TYPES.resetFilter: {
      return {
        ...state,
        filter: {},
      };
    }

    case TYPES.setRunwayBounds: {
      const { lower, upper } = action.payload;
      return {
        ...state,
        bounds: { lower, upper },
      };
    }

    case TYPES.selectEvent: {
      const { id } = action.payload;
      return {
        ...state,
        selectedEventId: id,
      };
    }

    case TYPES.deselectEvent: {
      return {
        ...state,
        selectedEventId: undefined,
      };
    }

    case TYPES.selectTask: {
      const { id } = action.payload;
      return {
        ...state,
        selectedTaskId: id,
      };
    }

    case TYPES.deselectTask: {
      return {
        ...state,
        selectedTaskId: undefined,
      };
    }

    case TYPES.selectPlanDay: {
      const { dayKey } = action.payload;
      return {
        ...state,
        selectedPlanDayKey: dayKey,
      };
    }

    case TYPES.logout: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
