import React, { useRef, useEffect } from 'react';

const styles = {
  topLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topRight: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  bottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  bottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};

function getLeft(ref) {
  return ref.current.getBoundingClientRect().left;
}

export default function MeasureScale({ onScaleChange }) {
  const topLeftRef = useRef();
  const topRightRef = useRef();
  const bottomLeftRef = useRef();
  const bottomRightRef = useRef();

  useEffect(() => {
    function calculateScale() {
      return (getLeft(topRightRef) - getLeft(topLeftRef))
        / (getLeft(bottomRightRef) - getLeft(bottomLeftRef));
    }

    onScaleChange(calculateScale());

    window.addEventListener('resize', calculateScale);
    return () => window.removeEventListener('resize', calculateScale);
  }, []);

  return (
    <>
      <div style={styles.topLeft} ref={topLeftRef} />
      <div style={styles.topRight} ref={topRightRef} />
      <div style={styles.bottomLeft} ref={bottomLeftRef} />
      <div style={styles.bottomRight} ref={bottomRightRef} />
    </>
  );
}
