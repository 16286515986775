import React, { useState, useRef } from 'react';
import cx from 'classnames';
import { config, Spring, animated } from 'react-spring';

import useOnClickOutside from '../hooks/useOnClickOutside';
import useIsMobile from '../hooks/useIsMobile';

import styles from '../styles/report.module.scss';

import mobileCaretDown from '../assets/images/settings-mobile-caret-down@3x.png';

export default function ReportBox({
  data,
  title,
  subtitle,
  children,
  className,
  collapsible,
  keyMetric,
}) {
  let firstActiveTab;
  if (data && data.length) firstActiveTab = data[0].title;
  const [activeTab, setActiveTab] = useState(firstActiveTab);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setDropdownOpen(false));

  const isMobile = useIsMobile();
  const [isCollapsed, setCollapsed] = useState(collapsible && isMobile);
  const content =
    typeof children === 'function'
      ? children(data.find(datum => datum.title === activeTab).data)
      : children;

  const openTab = tabTitle => () => {
    setActiveTab(tabTitle);
    setDropdownOpen(false);
  };

  const headerClick =
    collapsible && isMobile
      ? (event) => {
        if (!event.nativeEvent.path.some(el => el.className === styles.reportBoxDropdown)) {
          setCollapsed(!isCollapsed);
        }
      }
      : () => null;

  const marginTop = isMobile ? 20 : 0;

  return (
    <div className={cx(styles.reportBox, className)}>
      <div className={styles.reportBoxHeader} onClick={headerClick}>
        {Boolean(keyMetric) &&
          isMobile && <div className={styles.reportBoxKeyMetric}>{keyMetric}</div>}
        <div className={styles.reportBoxTitle}>
          <h3>{title}</h3>
          {Boolean(subtitle) && <h4>{subtitle}</h4>}
        </div>
        {isCollapsed && (
          <div className={styles.reportBoxMobileDown}>
            <img src={mobileCaretDown} />
          </div>
        )}
        {data &&
          !isCollapsed && (
            <div className={styles.reportBoxDropdown} ref={dropdownRef}>
              <button
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className={cx(!dropdownOpen && styles.closed)}
              >
                {activeTab}
              </button>
              {dropdownOpen &&
                data.filter(datum => datum.title !== activeTab).map(datum => (
                  <span className={styles.reportBoxTab} onClick={openTab(datum.title)}>
                    {datum.title}
                  </span>
                ))}
            </div>
          )}
      </div>
      <Spring
        to={{
          height: isCollapsed ? 0 : 'auto',
          opacity: isCollapsed ? 0 : 1,
          marginTop: isCollapsed ? 0 : marginTop,
        }}
        config={{ ...config.stiff, clamp: true }}
      >
        {props => (
          <animated.div className={styles.reportBoxContent} style={props}>
            {content}
          </animated.div>
        )}
      </Spring>
    </div>
  );
}

ReportBox.defaultProps = {
  collapsible: true,
};
