import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cxx from 'classnames';
import 'react-select/dist/react-select.css';

import InlineProgress from './charts/InlineProgress';
import InlineIndicator from './charts/InlineIndicator';
import InlineDonut from './charts/InlineDonut';
import Donut from './charts/Donut';
import ConcentricDonuts from './charts/ConcentricDonuts';
import BarChart from './charts/BarChart';

import ReportBox from './ReportBox';
import topColleaguesData from '../data/topColleagues';
import overdueTasksData from '../data/overdueTasks';
import taskPaceData from '../data/taskPaceData';
import areaChartData from '../data/areaChartData';
import pieChartData from '../data/pieChartData';

import pointsInProgressIcon from '../assets/images/points-in-progress-icon@3x.png';
import behindScheduleIcon from '../assets/images/behind-schedule-icon@3x.png';
import alertsIcon from '../assets/images/alerts-icon@3x.png';

import styles from '../styles/report.module.scss';

/* eslint-disable class-methods-use-this */

function keyToTitle(key) {
  return key
    .replace(
      /([A-Z][a-z]*)/g,
      ([first, ...letters]) => ` ${first.toLowerCase()}${letters.join('')}`,
    )
    .replace(/(^[a-z])/, ([first]) => first.toUpperCase());
}

export default class Report extends Component {
  constructor(props) {
    super(props);
    this.selectGraph = this.selectGraph.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.handleMediaQuery = this.handleMediaQuery.bind(this);
    this.mql = window.matchMedia('(max-width: 480px)');
    this.state = { selectedGraph: 'progress', selectedEvent: null, isMobile: this.mql.matches };
  }

  componentDidMount() {
    this.props.getEvents();
    if (this.state.selectedEvent || !this.props.events.length) return;
    this.selectEvent({ data: { ...this.props.events[0] } });
    this.mql.addListener(this.handleMediaQuery);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.selectedEvent || !nextProps.events.length) return;
    this.selectEvent({ data: { ...nextProps.events[0] } });
  }

  componentWillUnmount() {
    this.mql.removeListener(this.handleMediaQuery);
  }

  handleMediaQuery() {
    this.setState({ isMobile: this.mql.matches });
  }

  selectEvent(event) {
    this.setState({ selectedEvent: event.data });
  }

  selectGraph(graph) {
    return () => {
      this.setState({ selectedGraph: graph });
    };
  }

  renderChartArea() {
    const { selectedGraph } = this.state;
    return (
      <div className="report-chart-container">
        <div className="report-chart-area report-box">{this.renderLineChart()}</div>
        <div className="report-chart-sidebar">
          <div
            onClick={this.selectGraph('progress')}
            className={cxx(
              'report-chart-sidebar-button',
              selectedGraph === 'progress' && 'report-chart-sidebar-button-active',
            )}
          >
            Event Progress
          </div>
          <div
            onClick={this.selectGraph('complete')}
            className={cxx(
              'report-chart-sidebar-button',
              selectedGraph === 'complete' && 'report-chart-sidebar-button-active',
            )}
          >
            Tasks Complete
          </div>
          <div
            onClick={this.selectGraph('overdue')}
            className={cxx(
              'report-chart-sidebar-button',
              selectedGraph === 'overdue' && 'report-chart-sidebar-button-active',
            )}
          >
            Tasks Overdue
          </div>
          <div
            onClick={this.selectGraph('average')}
            className={cxx(
              'report-chart-sidebar-button',
              selectedGraph === 'average' && 'report-chart-sidebar-button-active',
            )}
          >
            Av. Time to Complete
          </div>
        </div>
      </div>
    );
  }

  renderUtilisationChart() {
    return (
      <ReportBox
        title="Utilisation (%)"
        subtitle="Percentage"
        data={areaChartData}
        className={cxx(styles.box, styles.utilisationBox)}
        keyMetric={`${areaChartData[0].data.day[0]}%`}
      >
        {(datum) => {
          const name = Object.keys(datum)[0];
          const current = datum[name][0];
          const last = datum[name][1];
          return (
            <div className={styles.utilisationContainer}>
              <InlineDonut
                showPercent
                className={styles.utilisationDonut}
                value={current}
                total={100}
                subtitle={`This ${name}`}
                strokeWidth="2"
                animated
              />
              <InlineDonut
                showPercent
                className={styles.utilisationDonut}
                value={last}
                total={100}
                subtitle={`Last ${name}`}
                color="#10d592"
                strokeWidth="2"
                animated
              />
            </div>
          );
        }}
      </ReportBox>
    );
  }

  renderTaskCompliance() {
    const COLORS = {
      Management: '#4f80f7',
      'Core Operations': '#ffda83',
      'Legal to Trade': '#b087e8',
      Facilities: '#ff7576',
      'Health & Safety': '#10d592',
      Uncompleted: 'rgb(228, 228, 228)',
    };

    const addUncompleted = (datum) => {
      const total = datum.reduce((acc, point) => acc + point.value, 0);
      return datum.concat({
        name: 'Uncompleted',
        value: 100 - total,
      });
    };

    const todaysCompliance = pieChartData[0].data.reduce((acc, val) => acc + val.value, 0);

    return (
      <ReportBox
        title="Task Compliance"
        subtitle="Percantage per task"
        data={pieChartData}
        className={styles.box}
        keyMetric={`${todaysCompliance}%`}
      >
        {datum => (
          <div className={styles.complianceContainer}>
            <Donut
              className={styles.complianceDonut}
              data={datum}
              subtitle="Complete"
              colors={COLORS}
              strokeWidth="1.5"
            />
            <div className={styles.complianceLegend}>
              {addUncompleted(datum).map(point => (
                <div className={styles.legendItem}>
                  <InlineIndicator color={COLORS[point.name]} />
                  <h5>{point.name}</h5>
                  <span>{`${point.value}%`}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </ReportBox>
    );
  }

  renderStoreRank() {
    const data = [
      { title: 'City', data: { rank: 3, of: 20 } },
      { title: 'Region', data: { rank: 12, of: 50 } },
      { title: 'Company', data: { rank: 25, of: 125 } },
    ];
    return (
      <ReportBox
        data={data}
        title="Store Rank"
        className={cxx(styles.box, styles.storeRankBox)}
        keyMetric={`${data[0].data.rank}/${data[0].data.of}`}
      >
        {datum => (
          <div className={styles.storeRank}>
            <span className={styles.rank}>
              {datum.rank}/{datum.of}
            </span>
            <span className={styles.rankClass}>Gold</span>
          </div>
        )}
      </ReportBox>
    );
  }

  renderTopColleagues() {
    return (
      <ReportBox
        data={topColleaguesData}
        title="Top Colleagues"
        subtitle="Highest scoring"
        className={styles.box}
      >
        {datum => (
          <table className={styles.reportTable}>
            <thead>
              <tr>
                <th>Colleague</th>
                <th>Task Progress</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {datum.map(person => (
                <tr>
                  <td>{person.name}</td>
                  <td>
                    <InlineProgress value={person.complete} total={person.tasks} />
                  </td>
                  <td>
                    <b>{person.points}</b>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ReportBox>
    );
  }

  renderOverdueTasks() {
    return (
      <ReportBox
        data={overdueTasksData}
        title="Overdue Tasks"
        subtitle="Points/hour"
        className={styles.box}
      >
        {datum => (
          <>
            <div className={styles.overdueTableLegend}>
              <span>
                <InlineIndicator className={styles.indicator} color="#ff7576" /> Critical
              </span>
              <span>
                <InlineIndicator className={styles.indicator} color="#ffda83" /> Warning
              </span>
            </div>
            <table className={cxx(styles.reportTable, styles.overdueTable)}>
              <thead>
                <tr>
                  <th>Task</th>
                  <th>Name</th>
                  <th>Time Over</th>
                </tr>
              </thead>
              <tbody>
                {datum.map(task => (
                  <tr>
                    <td>
                      <InlineIndicator
                        className={styles.overdueTableIndicator}
                        color={task.priority === 'CRITICAL' ? '#ff7576' : '#ffda83'}
                      />
                      {task.task}
                    </td>
                    <td>{task.name}</td>
                    <td className={styles[task.priority === 'CRITICAL' ? 'critical' : 'warning']}>
                      {task.timeover}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </ReportBox>
    );
  }

  renderTodaysReport() {
    const { isMobile } = this.state;

    const colors = {
      total: '#bdc1c6',
      inProgress: '#4f80f7',
      overdue: '#10d592',
    };

    const dummyData = {
      total: 120,
      inProgress: 60,
      overdue: 30,
    };

    const bigRow = (
      <tr className={styles.headerBig}>
        <td>250</td>
        {isMobile && <td className={styles.filler} />}
        <td>00:55m</td>
        {isMobile && <td className={styles.filler} />}
        <td>101</td>
      </tr>
    );

    const smallRow = (
      <tr className={styles.headerSmall}>
        <td>Points in Progress</td>
        {isMobile && <td className={styles.filler} />}
        <td>Behind Schedule</td>
        {isMobile && <td className={styles.filler} />}
        <td>Alerts</td>
      </tr>
    );

    return (
      <ReportBox
        title="Today's Report"
        collapsible={false}
        className={cxx(styles.box, styles.todaysReport)}
      >
        <div className={styles.todaysReportHeader}>
          <table className={cxx(styles.reportTable, styles.todaysReportTable)}>
            <tr className={styles.icons}>
              <td>
                <img src={pointsInProgressIcon} />
              </td>
              {isMobile && <td className={styles.filler} />}
              <td>
                <img src={behindScheduleIcon} />
              </td>
              {isMobile && <td className={styles.filler} />}
              <td>
                <img src={alertsIcon} />
              </td>
            </tr>
            {isMobile ? smallRow : bigRow}
            {isMobile ? bigRow : smallRow}
          </table>
        </div>
        <div className={styles.todaysReportChart}>
          <div className={styles.legend}>
            <table className={styles.reportTable}>
              {Object.entries(dummyData).map(([key, value]) => (
                <tr className={styles.icons}>
                  <td>
                    <InlineIndicator color={colors[key]} />
                  </td>
                  <td className={styles.title}>{keyToTitle(key)}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className={styles.chartContainer}>
            <ConcentricDonuts
              animated
              className={styles.chart}
              total={dummyData.total}
              innerValue={dummyData.overdue}
              outerValue={dummyData.inProgress}
            />
          </div>
        </div>
      </ReportBox>
    );
  }

  renderTaskPace() {
    return (
      <ReportBox
        title="Task Pace"
        subtitle="(Points/Hour)"
        data={taskPaceData}
        className={cxx(styles.box, styles.taskPaceBox)}
        keyMetric={`${areaChartData[0].data.day[0]}%`}
      >
        {(datum) => {
          const name = Object.keys(datum)[0];
          const current = datum[name][0];
          const last = datum[name][1];
          const data = [{ color: '#4f80f7', value: current }, { color: '#10d592', value: last }];
          return (
            <div className={styles.taskPaceChart}>
              <div className={styles.legend}>
                <span className={styles.legendItem}>
                  <InlineIndicator color="#4f80f7" />
                  {`This ${name}`}
                </span>
                <span className={styles.legendItem}>
                  <InlineIndicator color="#10d592" />
                  {`Last ${name}`}
                </span>
              </div>
              <div className={styles.chart}>
                <BarChart data={data} />
              </div>
            </div>
          );
        }}
      </ReportBox>
    );
  }

  renderMobile() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {this.renderTodaysReport()}
          {this.renderTaskCompliance()}
          {this.renderUtilisationChart()}
          {this.renderOverdueTasks()}
          {this.renderTaskPace()}
          {this.renderStoreRank()}
        </div>
      </div>
    );
  }

  render() {
    const { selectedEvent, isMobile } = this.state;
    if (!selectedEvent) return null;

    if (isMobile) return this.renderMobile();

    return (
      <div className={styles.container}>
        <div className={styles.titleRow}>
          <h2>Paddington Station Store Performance</h2>
        </div>
        <div className={styles.innerContainer}>
          <div className={cxx(styles.reportCol, styles.firstCol)}>
            {this.renderTopColleagues()}
            {this.renderOverdueTasks()}
          </div>
          <div className={cxx(styles.reportCol, styles.secondCol)}>
            {this.renderTodaysReport()}
            {this.renderTaskCompliance()}
          </div>
          <div className={cxx(styles.reportCol, styles.thirdCol)}>
            {this.renderStoreRank()}
            {this.renderTaskPace()}
            {this.renderUtilisationChart()}
          </div>
        </div>
      </div>
    );
  }
}

Report.propTypes = {
  events: PropTypes.arrayOf({}),
  getEvents: PropTypes.func.isRequired,
};

Report.defaultProps = { events: [] };
