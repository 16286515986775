import { connect } from 'react-redux';

import Login from '../components/Login';

import { postLogin } from '../modules/user/actions';
import * as loginSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    isLoggingIn: loginSelectors.getIsLoggingIn(state),
    error: loginSelectors.getLoginError(state),
  };
}

const mapDispatchToProps = {
  submitLogin: postLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
