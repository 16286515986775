export default {
  postLoginSuccess: 'userTypes/postLoginSuccess',
  postingLogin: 'userTypes/postingLogin',
  postLoginFail: 'userTypes/postLoginFail',
  logout: 'userTypes/logout',
  getEventsSuccess: 'userTypes/getEventsSuccess',
  getTasksSuccess: 'userTypes/getTasksSuccess',
  getPlanSuccess: 'userTypes/getPlanSuccess',
  getPlanTrackSuccess: 'userTypes/getPlanTrackSuccess',
  setRunwayBounds: 'userTypes/setRunwayBounds',
  selectEvent: 'userTypes/selectEvent',
  deselectEvent: 'userTypes/deselectEvent',
  selectTask: 'userTypes/selectTask',
  deselectTask: 'userTypes/deselectTask',
  selectPlanDay: 'userTypes/selectPlanDay',
  setFilter: 'userTypes/setFilter',
  resetFilter: 'userTypes/resetFilter',
};
