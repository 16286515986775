import { connect } from 'react-redux';

import Report from '../components/Report';

import { getEvents, getSelectedPlanDayKey } from '../modules/user/selectors';
import {
  getPlan as getPlanAction,
  getEvents as getEventsAction,
  postPlan,
  selectPlanDay,
} from '../modules/user/actions';

function mapStateToProps(state) {
  return {
    events: getEvents(state),
    dayKey: getSelectedPlanDayKey(state),
  };
}

const mapDispatchToProps = {
  getPlan: getPlanAction,
  getEvents: getEventsAction,
  postPlan,
  selectPlanDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
