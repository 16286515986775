import { connect } from 'react-redux';

import TaskForm from '../components/TaskForm';

import { addTask } from '../modules/forms/events/actions';
// import * as taskSelectors from '../modules/forms/tasks/selectors';
import * as choicesSelectors from '../modules/forms/choices/selectors';

function mapStateToProps(state) {
  return {
    // isSubmittingTaskForm: taskSelectors.getIsSubmittingTaskForm(state),
    // error: taskSelectors.getTaskFormError(state) ? 'Something went wrong' : '',
    categories: choicesSelectors.getCategories(state),
    subCategories: choicesSelectors.getSubCategories(state),
    priorityOptions: choicesSelectors.getPriorityOptions(state),
    teams: choicesSelectors.getTeams(state),
    // slaOptions: taskSelectors.getTaskFormSLAOptions(state),
  };
}

const mapDispatchToProps = {
  submitTaskForm: addTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
