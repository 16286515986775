import { connect } from 'react-redux';

import Home from '../components/Home';

import { getIsUserLoggedIn } from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    isUserLoggedIn: getIsUserLoggedIn(state),
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
