const data = [
  {
    title: 'Day',
    data: [
      {
        task: 'Till Cash Run',
        name: 'Fred Barnes',
        timeover: '01:21',
        priority: 'WARNING',
      },
      {
        task: 'Clean Slicer',
        name: 'Tim Stafford',
        timeover: '02:22',
        priority: 'CRITICAL',
      },
      {
        task: 'Alcohol Training',
        name: 'James Bellamy',
        timeover: '03:21',
        priority: 'CRITICAL',
      },
      {
        task: 'Ticketing Review',
        name: 'Jane Mortimer',
        timeover: '00:56',
        priority: 'WARNING',
      },
      {
        task: 'Fire Route Check',
        name: 'Jenny Pound',
        timeover: '05:00',
        priority: 'CRITICAL',
      },
    ],
  },
  {
    title: 'Week',
    data: [
      {
        task: 'Alcohol Training',
        name: 'James Bellamy',
        timeover: '03:21',
        priority: 'CRITICAL',
      },
      {
        task: 'Fire Route Check',
        name: 'Jenny Pound',
        timeover: '05:00',
        priority: 'CRITICAL',
      },
      {
        task: 'Till Cash Run',
        name: 'Fred Barnes',
        timeover: '01:21',
        priority: 'WARNING',
      },
      {
        task: 'Ticketing Review',
        name: 'Jane Mortimer',
        timeover: '00:56',
        priority: 'WARNING',
      },
      {
        task: 'Clean Slicer',
        name: 'Tim Stafford',
        timeover: '02:22',
        priority: 'CRITICAL',
      },
    ],
  },
  {
    title: 'Month',
    data: [
      {
        task: 'Fire Route Check',
        name: 'Jenny Pound',
        timeover: '05:00',
        priority: 'CRITICAL',
      },
      {
        task: 'Alcohol Training',
        name: 'James Bellamy',
        timeover: '03:21',
        priority: 'CRITICAL',
      },
      {
        task: 'Clean Slicer',
        name: 'Tim Stafford',
        timeover: '02:22',
        priority: 'CRITICAL',
      },
      {
        task: 'Till Cash Run',
        name: 'Fred Barnes',
        timeover: '01:21',
        priority: 'WARNING',
      },
      {
        task: 'Ticketing Review',
        name: 'Jane Mortimer',
        timeover: '00:56',
        priority: 'WARNING',
      },
    ],
  },
];

export default data;
