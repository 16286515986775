import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Label, Input, Button, Alert } from 'reactstrap';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import uuidv4 from 'uuid/v4';

import Select from './Select';
import DateTime from './DateTime';

import taskFormSchema, { isRequired } from '../validation/taskForm';

import styles from '../styles/eventForm.module.scss';

const priorityChoices = [
  { id: 5, name: 'P5 - Optional' },
  { id: 4, name: 'P4 - Low' },
  { id: 3, name: 'P3 - Medium' },
  { id: 2, name: 'P2 - High' },
  { id: 1, name: 'P1 - Critical' },
];

const availabilityOptions = [
  { id: 'after 7:00am and before 11:59pm', name: 'All day' },
  { id: 'after 7:00am and before 11:59am', name: 'Morning' },
  { id: 'after 12:00pm and before 4:59pm', name: 'Afternoon' },
  { id: 'after 5:00pm and before 11:59pm', name: 'Evening' },
];

function renderOptions(options) {
  return options.map(option => <option value={option.id}>{option.name}</option>);
}

function renderSubCategoryOptions(options, id) {
  return renderOptions(options.filter(option => option.taskCategory === id));
}

function renderError(error, touched) {
  return touched && error ? <div className={styles.formError}>{error}</div> : null;
}


export default function TaskForm({
  edit,
  valuesToEdit,
  isSubmittingTaskForm,
  submitTaskForm,
  error,
  categories,
  subCategories,
  // priorityOptions,
  teams,
  removeTask,
  onSubmit,
  constraints,
}) {
  return (
    <Fragment>
      {error && <Alert color="danger">{error}</Alert>}
      <Formik
        key={edit ? valuesToEdit.clientId : 'new'}
        validationSchema={taskFormSchema}
        initialValues={
          edit
            ? {
                ...valuesToEdit,
                start: new Date(valuesToEdit.start),
                end: new Date(valuesToEdit.end),
              }
            : {
                category: categories[0] ? categories[0].id : '',
                subCategory: subCategories[0] ? subCategories[0].id : '',
                name: '',
                description: '',
                guidanceNotes: '',
                priority: priorityChoices[2] ? priorityChoices[2].id : '',
                start: constraints[0],
                end: constraints[0],
                estimatedDuration: 0,
                teamAssigned: [],
                availability: '',
                scheduleType: 'auto',
              }
        }
        onSubmit={(values) => {
          submitTaskForm({
            ...values,
            clientId: valuesToEdit ? valuesToEdit.clientId : uuidv4(),
          });
          if (onSubmit) onSubmit();
        }}
        render={({
          dirty,
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleReset,
          submitForm,
        }) => (
          <Fragment>
            <Form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formRow}>
                <div className={styles.formCol}>
                  <Label for="name">
                    Name
                    {isRequired.name && '*'}
                  </Label>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.name ? !errors.name : undefined}
                  />
                  {renderError(errors.name, touched.name)}
                  <Label for="category">
                    Event Category
                    {isRequired.category && '*'}
                  </Label>
                  <Input
                    type="select"
                    name="category"
                    value={values.category}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.category ? !errors.category : undefined}
                  >
                    {renderOptions(categories)}
                  </Input>
                  {renderError(errors.category, touched.category)}
                </div>
                <div className={styles.formCol}>
                  <Label for="priority">
                    Priority
                    {isRequired.priority && '*'}
                  </Label>
                  <Input
                    type="select"
                    name="priority"
                    value={values.priority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.priority ? !errors.priority : undefined}
                  >
                    {renderOptions(priorityChoices)}
                  </Input>
                  {renderError(errors.priority, touched.priority)}
                  <Label for="subCategory">
                    Event Sub-Category
                    {isRequired.subCategory && '*'}
                  </Label>
                  <Input
                    type="select"
                    name="subCategory"
                    value={values.subCategory}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.subCategory ? !errors.subCategory : undefined}
                  >
                    {renderSubCategoryOptions(subCategories, values.category)}
                  </Input>
                  {renderError(errors.subCategory, touched.subCategory)}
                </div>
              </div>
              <div className={styles.formRow}>
                <Label for="scheduleType">
                  <input
                    type="radio"
                    name="scheduleType"
                    value="auto"
                    checked={values.scheduleType === 'auto'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Auto
                </Label>
                <Label for="scheduleType">
                  <input
                    type="radio"
                    name="scheduleType"
                    value="specific"
                    checked={values.scheduleType === 'specific'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Specific
                </Label>
              </div>
              {values.scheduleType === 'auto' ? (
                <div className={styles.formRow}>
                  <div className={styles.formCol}>
                    <Label for="availability">
                      Time band
                      {isRequired.availability && '*'}
                    </Label>
                    <Select
                      closeOnSelect={false}
                      placeholder="Select a time band..."
                      name="availability"
                      value={values.availability}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={availabilityOptions}
                      clearable={false}
                    />
                    {renderError(errors.availability, touched.availability)}
                  </div>
                  <div className={styles.formCol}>
                    <Label for="estimatedDuration">
                      Estimated Duration (Minutes)
                      {isRequired.estimatedDuration && '*'}
                    </Label>
                    <Input
                      name="estimatedDuration"
                      type="number"
                      value={values.estimatedDuration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      valid={touched.estimatedDuration ? !errors.estimatedDuration : undefined}
                    />
                    {renderError(errors.estimatedDuration, touched.estimatedDuration)}
                  </div>
                </div>
              ) : (
                <div className={styles.formRow}>
                  <div className={styles.formCol}>
                    <Label for="start">
                      Start Date & Time
                      {isRequired.start && '*'}
                    </Label>
                    <DateTime
                      name="start"
                      value={values.start}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      startDate={constraints[0]}
                      endDate={constraints[1]}
                      viewMode="time"
                    />
                    {renderError(errors.start, touched.start)}
                  </div>
                  <div className={styles.formCol}>
                    <Label for="end">
                      End Date & Time
                      {isRequired.end && '*'}
                    </Label>
                    <DateTime
                      name="end"
                      value={values.end}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      startDate={values.start || constraints[0]}
                      endDate={constraints[1]}
                      valid={touched.end ? !errors.end : undefined}
                      viewMode="time"
                    />
                    {renderError(errors.end, touched.end)}
                  </div>
                </div>
              )}
              <div className={styles.formRow}>
                <div className={styles.formCol}>
                  <Label for="teamAssigned">
                    Team Assigned to
                    {isRequired.teamAssigned && '*'}
                  </Label>
                  <Select
                    multi
                    closeOnSelect={false}
                    placeholder="Select teams..."
                    name="teamAssigned"
                    value={values.teamAssigned}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={teams}
                  />
                  {renderError(errors.teamAssigned, touched.teamAssigned)}
                  <Label for="description">
                    Description
                    {isRequired.description && '*'}
                  </Label>
                  <Input
                    name="description"
                    type="textarea"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.description ? !errors.description : undefined}
                  />
                  {renderError(errors.description, touched.description)}
                </div>
                <div className={styles.formCol}>
                  <Label for="guidanceNotes">
                    Guidance Notes
                    {isRequired.guidanceNotes && '*'}
                  </Label>
                  <Input
                    name="guidanceNotes"
                    type="textarea"
                    value={values.guidanceNotes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    valid={touched.guidanceNotes ? !errors.guidanceNotes : undefined}
                  />
                  {renderError(errors.guidanceNotes, touched.guidanceNotes)}
                </div>
              </div>
            </Form>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                color="primary"
                onClick={submitForm}
                disabled={(isSubmittingTaskForm || !dirty || !isEmpty(errors)) && !edit}
              >
                {edit ? 'Save Task' : 'Add Task'}
              </Button>
            </div>
          </Fragment>
        )}
      />
    </Fragment>
  );
}

TaskForm.propTypes = {
  edit: PropTypes.bool,
  isSubmittingTaskForm: PropTypes.bool.isRequired,
  submitTaskForm: PropTypes.func.isRequired,
  error: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  subCategories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  priorityOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

TaskForm.defaultProps = {
  error: '',
  edit: false,
};
