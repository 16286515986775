import { push } from 'react-router-redux';
import TYPES from './types';
import API from '../../api';
import { getAuthToken, getPlanFilter } from './selectors';

export function postingLogin() {
  return {
    type: TYPES.postingLogin,
  };
}

export function postLoginSuccess({ authToken, user }) {
  return {
    type: TYPES.postLoginSuccess,
    payload: {
      authToken,
      user,
    },
  };
}

export function postLoginFail(error) {
  return {
    type: TYPES.postLoginFail,
    payload: {
      error,
    },
  };
}

export function setRunwayBounds(lower, upper) {
  return {
    type: TYPES.setRunwayBounds,
    payload: {
      lower,
      upper,
    },
  };
}

export function selectEvent(id) {
  return {
    type: TYPES.selectEvent,
    payload: { id },
  };
}

export function deselectEvent() {
  return {
    type: TYPES.deselectEvent,
  };
}

export function selectTask(id) {
  return {
    type: TYPES.selectTask,
    payload: { id },
  };
}

export function deselectTask() {
  return {
    type: TYPES.deselectTask,
  };
}

export function selectPlanDay(dayKey) {
  return {
    type: TYPES.selectPlanDay,
    payload: { dayKey },
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: TYPES.logout });
    dispatch(push('/'));
  };
}

export function postLogin(employeeId, password) {
  return async (dispatch) => {
    dispatch(postingLogin());
    let res;
    try {
      res = await API.User.postLogin(employeeId, password);
    } catch (err) {
      if (err.error) return dispatch(postLoginFail(err.error));
    }
    if (res.error) return dispatch(postLoginFail(res.error));
    return dispatch(postLoginSuccess(res));
  };
}

export function getEventsSuccess(events) {
  return {
    type: TYPES.getEventsSuccess,
    payload: { events },
  };
}

export function getEvents() {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.Events.getEvents(authToken);
    } catch (err) {
      //
      console.log(err);
    }
    if (res.error) return null;
    return dispatch(getEventsSuccess(res));
  };
}

export function getTasksSuccess(tasks) {
  return {
    type: TYPES.getTasksSuccess,
    payload: { tasks },
  };
}

export function getTasks() {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.Tasks.getTasks(authToken);
    } catch (err) {
      return null;
    }
    if (res && res.error) return null;
    return dispatch(getTasksSuccess(res));
  };
}

export function getPlanSuccess({ plan, filter }) {
  return {
    type: TYPES.getPlanSuccess,
    payload: { plan, filter },
  };
}

export function setFilter(filter) {
  return {
    type: TYPES.setFilter,
    payload: { filter },
  };
}

export function resetFilter() {
  return {
    type: TYPES.resetFilter,
  };
}

export function getPlan(dayKey) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.Plan.getPlan(dayKey, authToken);
    } catch (err) {
      //
    }
    if (res.error) return null;
    return dispatch(getPlanSuccess(res));
  };
}

export function postPlan(dayKey, plan) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.Plan.postPlan(dayKey, plan, authToken);
    } catch (err) {
      //
    }
    if (res.error) return null;
    return dispatch(getPlanSuccess(res));
  };
}

export function postPlanAuto(dayKey) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    const filter = getPlanFilter(state);
    let res;
    try {
      res = await API.Plan.postPlanAuto(dayKey, filter, authToken);
    } catch (err) {
      //
    }
    if (res.error) return null;
    return dispatch(getPlanSuccess(res));
  };
}

export function getPlanTrackSuccess(planTrack) {
  return {
    type: TYPES.getPlanTrackSuccess,
    payload: { planTrack },
  };
}

export function getPlanTrack(dayKey) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.PlanTrack.getPlanTrack(dayKey, authToken);
    } catch (err) {
      //
    }
    if (res.error) return null;
    return dispatch(getPlanTrackSuccess(res));
  };
}

export function postPlanTrack(dayKey, planTrack) {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.PlanTrack.postPlanTrack(dayKey, planTrack, authToken);
    } catch (err) {
      //
    }
    if (res.error) return null;
    dispatch(getPlanTrackSuccess(res));
    return res;
  };
}
