import React from 'react';
import cxx from 'classnames';

import styles from '../../styles/charts/inlineDonut.module.scss';

function generateColor(seed) {
  let total = 0;
  for (let i = 0; i !== seed.length; i += 1) {
    if (total >= Number.MAX_SAFE_INTEGER) break;
    total += seed.charCodeAt(i);
  }
  let color = Math.floor(Math.abs(Math.sin(total) * 16777215) % 16777215);
  color = color.toString(16);
  while (color.length < 6) {
    color = `0${color}`;
  }
  return `#${color}`;
}

function getStrokeDasharray(data, index) {
  if (index === 0) return 25;
  return 125 - data.filter((_, i) => i < index).reduce((acc, cell) => acc + cell.value, 0);
}

export default function Donut({
  data, className, subtitle, strokeWidth, animated, colors,
}) {
  const total = data.reduce((acc, cell) => acc + cell.value, 0);
  return (
    <svg width="100%" height="100%" viewBox="0 0 34 34" className={className}>
      <circle cx="17" cy="17" r="15.91549430918954" fill="#fff" />
      <circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke="#e4e4e4"
        strokeWidth={strokeWidth || 1}
      />
      {data.map((cell, index) => (
        <circle
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke={(colors && colors[cell.name]) || generateColor(cell.name)}
          strokeWidth={strokeWidth || 1}
          strokeDasharray={`${cell.value} ${100 - cell.value}`}
          strokeDashoffset={getStrokeDasharray(data, index)}
        />
      ))}
      <text x="50%" y="50%" className={cxx(styles.text, Boolean(subtitle) && styles.withSubtitle)}>
        {`${total}%`}
      </text>
      {Boolean(subtitle) && (
        <text x="50%" y="50%" className={styles.subtitle}>
          {subtitle}
        </text>
      )}
    </svg>
  );
}
