export const basename = '/';

export const basePaths = {
  production: '/api/v1',
  development: '',
};

export default {
  production: 'https://demo.nimblr.co.uk',
  development: 'http://localhost:8080',
};
