import { connect } from 'react-redux';

import Plan from '../components/Plan';

import { getPlan, getSelectedPlanDayKey, getPlanTrack, getPlanFilter } from '../modules/user/selectors';
import {
  getPlan as getPlanAction,
  getPlanTrack as getPlanTrackAction,
  postPlan,
  postPlanAuto,
  selectPlanDay,
  setFilter,
  resetFilter,
} from '../modules/user/actions';

function mapStateToProps(state) {
  return {
    plan: getPlan(state),
    planTrack: getPlanTrack(state),
    dayKey: getSelectedPlanDayKey(state),
    filter: getPlanFilter(state),
  };
}

const mapDispatchToProps = {
  getPlan: getPlanAction,
  getPlanTrack: getPlanTrackAction,
  postPlan,
  postPlanAuto,
  selectPlanDay,
  setFilter,
  resetFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
