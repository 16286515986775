import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cloneDeep from 'lodash/cloneDeep';
import pull from 'lodash/pull';
import isEqual from 'lodash/isEqual';
import flatMap from 'lodash/flatMap';
import keyBy from 'lodash/keyBy';
import cx from 'classnames';
import moment from 'moment';
import later from 'later';

import DayNav from './DayNav';
import styles from '../styles/plan.module.scss';

import { getType, getTypeImage, getTypeColor } from '../util/mockTaskType';

import laneBackArrow from '../assets/images/lane_back_arrow.svg';
import laneForwardArrow from '../assets/images/lane_forward_arrow.svg';
import closeIcon from '../assets/images/modal-close-icon@3x.png';
import illegalMoveIcon from '../assets/images/plan-illegal-move-icon@3x.png';

const MIN_LANE_WIDTH = 190;

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
    padding: 0,
    overflow: 'hidden',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: 'transparent',
  },
};

function transformHours(plan, track, tempData) {
  const newPlan = cloneDeep(plan);
  return newPlan.map(col => ({
    ...col,
    tasks: col.tasks
      .map(task => ({
        ...task,
        track: track[task._id] || {},
        time: moment
          .utc((tempData[task._id] && tempData[task._id].startTime) || task.startTime)
          .format('HH:mm'),
        badSchedule: tempData[task._id] ? tempData[task._id].badSchedule : false,
      }))
      .reverse(),
  }));
}

function reduceTotalTime(total, col) {
  return total + col.duration;
}

function renderPercentage(time) {
  const totalTimePerDay = 60 * 7;
  const percentage = Math.round(time / totalTimePerDay * 100);
  return `${percentage}%`;
}

function getTimeType(time) {
  const totalTimePerDay = 60 * 7;
  const percentage = Math.round(time / totalTimePerDay * 100);
  if (percentage === 0) {
    return 'inactive';
  } else if (percentage < 30 || percentage > 95) {
    return 'danger';
  } else if (percentage >= 30 && percentage <= 60) {
    return 'mid';
  }
  return 'regular';
}

function renderTask(task, index) {
  return (
    <Draggable
      key={task._id}
      draggableId={task._id}
      index={index}
      isDragDisabled={!!task.track.start}
    >
      {provided => (
        <div
          className={cx(
            styles.task,
            styles[getType(task.name)],
            // task.track.start && !task.track.end && 'plan-task-started',
            task.track.end && styles.done,
            task.badSchedule && styles.bad,
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!task.track.end && moment.utc(task.startTime).isBefore(moment()) &&
            <div className={cx(styles.urgentHeader, styles[getType(task.name)])}>URGENT</div>
          }
          <div className={styles.taskHeader} style={{ color: getTypeColor(task.name) }}>
            <span className={styles.taskHeaderTime}>{task.time}</span>
            {/* !task.track.end
              ? <img src={getTypeImage(task.name)} alt={getType(task.name)} />
              : <img
                src={task.track.completed ? taskCompletedIcon : taskUncompletedIcon}
                style={{ border: '1px solid #909090' }}
                alt={getType(task.name)}
              />
            */}
            <img src={getTypeImage(task.name)} alt={getType(task.name)} />
            {/* task.track.end &&
              <span className="completed">
                {task.track.completed ? 'COMPLETED' : 'ENDED'}
              </span>
            */}
          </div>
          <span className={styles.taskName}>{task.name}</span>
        </div>
      )}
    </Draggable>
  );
}

function calcTimes(listFromState, dayKey, planTrack) {
  if (!listFromState) return null;

  return listFromState.map(person => ({
    ...person,
    tasks: person.tasks.reduce((acc, task, index) => {
      let startMoment;
      let defaultStartMoment;
      let startedButChanging = false;
      if (index === 0) {
        defaultStartMoment = moment
          .utc(dayKey)
          .hours(7)
          .minutes(30);
      } else {
        defaultStartMoment = moment.utc(acc[index - 1].endTime);
      }

      if (planTrack[task._id] && planTrack[task._id].start) {
        const originalStartMoment = moment.utc(task.startTime);
        if (!originalStartMoment.isBefore(defaultStartMoment)) {
          startMoment = originalStartMoment;
        } else {
          startedButChanging = true;
          startMoment = defaultStartMoment;
        }
      } else if (!task.available) {
        startMoment = defaultStartMoment;
        // TODO: dragged task snaps to now if put on the top of the stack
        // startMoment = defaultStartMoment.isAfter(moment.utc())
        //   ? defaultStartMoment
        //   : moment.utc();
      } else {
        const schedule = later.schedule(later.parse.text(task.available));
        if (schedule.isValid(defaultStartMoment.toDate())) {
          startMoment = defaultStartMoment;
        } else {
          const scheduleStartMoment = moment.utc(dayKey).isSame(moment.utc(), 'day')
            ? moment.utc()
            : defaultStartMoment;
          const nextAvailable = schedule.next(
            1,
            scheduleStartMoment.toDate(),
            scheduleStartMoment
              .clone()
              .endOf('day')
              .toDate(),
          );
          if (nextAvailable) {
            startMoment = moment.utc(nextAvailable);
          } else {
            startMoment = defaultStartMoment;
          }
        }
      }
      return acc.concat({
        ...task,
        startTime: startMoment.toDate(),
        endTime: startMoment.add(task.duration, 'minutes').toDate(),
        startedButChanging,
      });
    }, []),
  }));
}

function getTempData(list, draggableId) {
  return keyBy(
    flatMap(list, person =>
      person.tasks.map(({
        startTime, endTime, available, startedButChanging, name, ...task
      }) => {
        let badSchedule = false;
        let badScheduleReason;
        if (available) {
          const schedule = later.schedule(later.parse.text(available));
          if (!schedule.isValid(moment.utc(startTime).toDate())) {
            badSchedule = true;
            badScheduleReason = `${name} must be ${available}.`;
          }
        }
        if (task._id === draggableId && moment.utc(startTime).isBefore()) {
          badSchedule = true;
          badScheduleReason = `${name} is overdue and can't be moved.`;
        }
        if (startedButChanging) {
          badScheduleReason = `${name} already started.`;
          badSchedule = true;
        }
        return {
          _id: task._id,
          startTime,
          endTime,
          badSchedule,
          badScheduleReason,
        };
      })),
    '_id',
  );
}

function calcDropResults(listFromState, result) {
  const { source, destination } = result;
  if (!destination) return null;
  const list = cloneDeep(listFromState);
  const sourceList = [...list.find(col => col._id === source.droppableId).tasks].reverse();
  let destinationList;
  if (source.droppableId === destination.droppableId) {
    destinationList = sourceList;
  } else {
    destinationList = [...list.find(col => col._id === destination.droppableId).tasks].reverse();
  }
  const [removed] = sourceList.splice(source.index, 1);
  destinationList.splice(destination.index, 0, removed);
  return list.map((col) => {
    if (col._id === source.droppableId) {
      return {
        ...list.find(coln => coln._id === source.droppableId),
        tasks: sourceList.reverse(),
      };
    }
    if (col._id === destination.droppableId) {
      return {
        ...list.find(coln => coln._id === destination.droppableId),
        tasks: destinationList.reverse(),
      };
    }
    return col;
  });
}

export default class Plan extends Component {
  constructor(props) {
    super(props);
    this.reorder = this.reorder.bind(this);
    this.handleDragUpdate = this.handleDragUpdate.bind(this);
    this.renderColumn = this.renderColumn.bind(this);
    this.closeIllegalModal = this.closeIllegalModal.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.renderLaneSwitchArrows = this.renderLaneSwitchArrows.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.renderDays = this.renderDays.bind(this);
    this.mql = window.matchMedia('(max-width: 480px)');
    this.droppableRefs = {};
    this.planBoardRef = React.createRef();
    this.state = {
      columns: [],
      tempData: {},
      activeLanes: [0, 6],
      illegalModalData: null,
      isMobile: this.mql.matches,
    };
  }

  componentDidMount() {
    const { dayKey, getPlan, getPlanTrack } = this.props;
    getPlan(dayKey);
    getPlanTrack(dayKey);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.mql.addListener(this.mediaQueryChanged);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.plan, nextProps.plan) || this.state.columns.length === 0) {
      this.setState({ columns: nextProps.plan, tempData: {} });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.mql.removeListener(this.mediaQueryChanged);
  }

  closeIllegalModal() {
    this.setState({ illegalModalData: null });
  }

  mediaQueryChanged() {
    this.setState({ isMobile: this.mql.matches });
  }

  handleResize() {
    const maxLanes = Math.floor(window.innerWidth / MIN_LANE_WIDTH);

    this.setState({
      activeLanes: [
        0,
        maxLanes - 1,
      ],
    });
  }

  reorder(result) {
    const { dayKey, planTrack } = this.props;
    const { tempData } = this.state;
    const list = calcTimes(calcDropResults(this.state.columns, result), dayKey, planTrack);
    if (!list) this.setState({ tempData: {} });
    else if (!Object.values(tempData).find(task => task.badSchedule)) {
      this.setState({ columns: list, tempData: {} }, () =>
        this.props.postPlan(this.props.dayKey, list));
    } else {
      const illegalModalData = Object.values(tempData)
        .filter(task => task.badSchedule)
        .map(task => task.badScheduleReason);
      this.setState({ tempData: {}, illegalModalData });
    }
  }

  handleDragUpdate(result) {
    const { dayKey, planTrack } = this.props;
    const listCandidate = calcTimes(calcDropResults(this.state.columns, result), dayKey, planTrack);
    if (!listCandidate) {
      this.setState({ tempData: {} });
    } else {
      this.setState({ tempData: getTempData(listCandidate, result.draggableId) });
    }
  }

  renderDays(selectedDay, goToDay) {
    const dayOffsets = [0, 1, 2, 3, 4, 5, 6];
    const days = dayOffsets.map((offset) => {
      const dayMoment = moment().add(offset, 'day');
      const dayKey = dayMoment.format('YYYY-MM-DD');
      const active = selectedDay === dayKey;
      return {
        name: dayMoment.format(this.state.isMobile ? 'ddd' : 'dddd'),
        onClick: () => goToDay(dayKey),
        active,
      };
    });
    return <DayNav items={days} />;
  }

  renderModal() {
    const { illegalModalData } = this.state;
    return (
      <Modal
        isOpen={Boolean(illegalModalData)}
        onRequestClose={this.closeIllegalModal}
        style={customStyles}
      >
        <div className={styles.illegalModal}>
          <button className={styles.closeButton} onClick={this.closeIllegalModal}>
            <img src={closeIcon} alt="Close Modal" />
          </button>
          <img src={illegalMoveIcon} className={styles.illegalMoveIcon} />
          <h3>{'You can\'t do this move!'}</h3>
          {(illegalModalData || []).map(reason => (
            <span className={styles.illegalReason}>{reason}</span>
          ))}
        </div>
      </Modal>
    );
  }

  renderLaneSwitchArrows() {
    const { columns, activeLanes: [minLane, maxLane] } = this.state;
    if (columns.length <= ((maxLane - minLane) + 1)) return null;

    const moveUp = () => this.setState({ activeLanes: [minLane + 1, maxLane + 1] });
    const moveDown = () => this.setState({ activeLanes: [minLane - 1, maxLane - 1] });

    const downEnabled = minLane !== 0;
    const upEnabled = columns.length > maxLane + 1;

    return (
      <Fragment>
        <div onClick={downEnabled ? moveDown : null} className="runway-lane-switch-arrow arrow-left">
          <img src={laneBackArrow} alt="Back" />
        </div>
        <div onClick={upEnabled ? moveUp : null} className="runway-lane-switch-arrow arrow-right">
          <img src={laneForwardArrow} alt="Forward" />
        </div>
      </Fragment>
    );
  }

  renderColumn(column) {
    const {
      filter, setFilter, postPlanAuto, dayKey,
    } = this.props;
    const { omit = [] } = filter;
    const disabled = omit.indexOf(column._id) > -1;

    if (!this.droppableRefs[column._id]) {
      this.droppableRefs[column._id] = React.createRef();
    }

    return (
      <div className={styles.columnContainer} key={column._id} ref={this.droppableRefs[column._id]}>
        <div className={styles.columnTitle}>
          <span>{column.name}</span>
          <input
            type="checkbox"
            id={`${column._id}-checkbox`}
            className={styles.checkbox}
            checked={!disabled}
            onChange={() => {
              if (disabled) setFilter({ omit: pull(omit, column._id) });
              else setFilter({ omit: [...omit, column._id] });
              postPlanAuto(dayKey);
            }}
          />
          <label htmlFor={`${column._id}-checkbox`} />
          <div className={cx(styles.percentage, styles[getTimeType(column.tasks.reduce(reduceTotalTime, 0))])}>
            {renderPercentage(column.tasks.reduce(reduceTotalTime, 0))}
          </div>
        </div>
        <Droppable droppableId={column._id} isDropDisabled={disabled}>
          {provided => (
            <div ref={provided.innerRef} className={styles.column} {...provided.droppableProps}>
              <div className="plan-column-wrapper">
                {column.tasks.map(renderTask)}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </div>
    );
  }

  render() {
    const { columns, tempData } = this.state;
    const {
      getPlan,
      getPlanTrack,
      selectPlanDay,
      resetFilter,
      dayKey,
      planTrack = {},
    } = this.props;
    return (
      <div className="plan-container">
        {this.renderDays(dayKey, (key) => {
          selectPlanDay(key);
          resetFilter();
          getPlan(key);
          getPlanTrack(key);
        })}
        <DragDropContext onDragEnd={this.reorder} onDragUpdate={this.handleDragUpdate}>
          <div className={styles.planBoard}>
            <div className={styles.planBoardScroll} ref={this.planBoardRef}>
              <div className={styles.planBoardFlex} ref={this.planBoardRef}>
                {transformHours(columns, planTrack, tempData).map(this.renderColumn)}
              </div>
            </div>
          </div>
        </DragDropContext>
        {this.renderModal()}
      </div>
    );
  }
}

Plan.propTypes = {
  plan: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.number,
  })),
  filter: PropTypes.shape({}),
  planTrack: PropTypes.shape({}),
  getPlan: PropTypes.func.isRequired,
  getPlanTrack: PropTypes.func.isRequired,
  postPlan: PropTypes.func.isRequired,
  postPlanAuto: PropTypes.func.isRequired,
  selectPlanDay: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  dayKey: PropTypes.string.isRequired,
};

Plan.defaultProps = {
  plan: [],
  planTrack: {},
  filter: {},
};
