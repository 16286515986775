import { createSelector } from 'reselect';

export const getUser = state => state.user;

export const getIsUserLoggedIn = createSelector(
  getUser,
  user => !!(user.employeeId && user.authToken),
);

export const getUserId = createSelector(getUser, user => user._id);

export const getAuthToken = createSelector(getUser, user => user.authToken);

export const getJobGrade = createSelector(getUser, user => user.jobGrade);

export const getAccountType = createSelector(getUser, user => user.type);

export const getIsLoggingIn = createSelector(getUser, user => user.loggingIn);

export const getLoginError = createSelector(getUser, user => user.loginError);

export const getEvents = createSelector(getUser, user => user.events);

export const getTasks = createSelector(getUser, user => user.tasks);

export const getPlan = createSelector(getUser, user => user.plan);

export const getPlanTrack = createSelector(getUser, user => user.planTrack);

export const getPlanFilter = createSelector(getUser, user => user.filter);

export const getRunwayBounds = createSelector(getUser, user => user.bounds);

export const getSelectedEventId = createSelector(getUser, user => user.selectedEventId);

export const getSelectedTaskId = createSelector(getUser, user => user.selectedTaskId);

export const getSelectedPlanDayKey = createSelector(getUser, user => user.selectedPlanDayKey);

export const getSelectedEvent = createSelector(
  getEvents,
  getTasks,
  getSelectedEventId,
  (events, tasks, id) => {
    if (!id) return undefined;
    const event = events.find(e => e._id === id);
    if (!event) return undefined;
    const tasksOfEvent = tasks.filter(task => task.parent === id);
    return { ...event, tasks: tasksOfEvent };
  },
);

export const getSelectedTask = createSelector(
  getPlan,
  getSelectedTaskId,
  (plan, id) => {
    if (!id) return undefined;
    if (!plan) return undefined;
    let targetTask;
    plan.forEach(person => person.tasks.forEach((task) => {
      if (task._id === id) targetTask = task;
    }));
    return targetTask;
  },
);

export const getMyEvents = createSelector(
  getUserId,
  getEvents,
  (id, events) =>
    events.filter(event => event.createdBy && event.createdBy._id && event.createdBy._id === id),
);
