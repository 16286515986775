import qs from 'qs';
import objectToFormData from 'object-to-formdata';
import hosts, { basePaths } from '../config/hosts';

export const HOST = hosts[process.env.NODE_ENV];
export const BASE_PATH = basePaths[process.env.NODE_ENV];

const headers = {
  json: {
    'Content-Type': 'application/json',
  },
  auth: authToken => ({ Authorization: `Bearer ${authToken}` }),
};

export function apiPath(resource, querystring) {
  const q = querystring ? qs.stringify(querystring) : '';
  return `${HOST}${BASE_PATH}${resource}?${q}`;
}

export function handleErrors(response) {
  if (!response.ok) {
    return response
      .json()
      .then((body) => {
        throw body;
      })
      .catch((error) => {
        throw error;
      });
  }
  return response;
}

const API = {
  User: {
    postLogin: (employeeId, password) =>
      fetch(apiPath('/login'), {
        method: 'POST',
        body: JSON.stringify({ employeeId, password }),
        headers: { ...headers.json },
      })
        .then(handleErrors)
        .then(response => response.json()),
  },
  Choices: {
    getChoices: authToken =>
      fetch(apiPath('/choices'), {
        method: 'GET',
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
  },
  Events: {
    getEvents: authToken =>
      fetch(apiPath('/events'), {
        method: 'GET',
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
    postEvent: (event, authToken) => {
      const hasFile = event.pdfFile;
      let body = JSON.stringify({ ...event });
      if (hasFile) {
        const { pdfFile, ...options } = event;
        body = objectToFormData(options);
        body.append('guidanceNotes', event.pdfFile);
      }
      return fetch(apiPath('/events'), {
        method: 'POST',
        body,
        headers: { ...(hasFile ? {} : headers.json), ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json());
    },
  },
  Tasks: {
    getTasks: authToken =>
      fetch(apiPath('/tasks'), {
        method: 'GET',
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
    postTask: (task, authToken) =>
      fetch(apiPath('/tasks'), {
        method: 'POST',
        body: JSON.stringify({ ...task }),
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
  },
  Plan: {
    getPlan: (dayKey, authToken) =>
      fetch(apiPath(`/plan/${dayKey}`), {
        method: 'GET',
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
    postPlan: (dayKey, plan, authToken) =>
      fetch(apiPath(`/plan/${dayKey}`), {
        method: 'POST',
        body: JSON.stringify({ plan }),
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
    postPlanAuto: (dayKey, filter, authToken) =>
      fetch(apiPath(`/plan/${dayKey}/auto`), {
        method: 'POST',
        body: JSON.stringify({ filter }),
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
  },
  PlanTrack: {
    getPlanTrack: (dayKey, authToken) =>
      fetch(apiPath(`/planTrack/${dayKey}`), {
        method: 'GET',
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
    postPlanTrack: (dayKey, planTrack, authToken) =>
      fetch(apiPath(`/planTrack/${dayKey}`), {
        method: 'POST',
        body: JSON.stringify({ planTrack }),
        headers: { ...headers.json, ...headers.auth(authToken) },
      })
        .then(handleErrors)
        .then(response => response.json()),
  },
};

export default API;
