import React from 'react';
import { useSpring, animated as animcomps } from 'react-spring/hooks';

const scale = 0.68;
const transform = `translate(17 17) scale(${scale}) translate(-17 -17)`;

export default function ConcentricDonuts({
  innerValue,
  outerValue,
  total,
  className,
  strokeWidth,
  innerColor,
  outerColor,
  animated,
}) {
  const innerPercent = (innerValue / total) * 100;
  const outerPercent = (outerValue / total) * 100;
  const { innerDash, outerDash } = useSpring({
    innerDash: `${innerPercent} ${100 - innerPercent}`,
    outerDash: `${outerPercent} ${100 - outerPercent}`,
    from: { innerDash: '0 100', outerDash: '0 100' },
  });
  return (
    <svg width="100%" height="100%" viewBox="0 0 34 34" className={className}>
      <circle cx="17" cy="17" r="15.91549430918954" fill="transparent" />
      <circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke="#e4e4e4"
        strokeWidth={strokeWidth || 1}
      />
      <animcomps.circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke={outerColor || '#2d96eb'}
        strokeWidth={strokeWidth || 1}
        strokeDasharray={animated ? outerDash : `${outerPercent} ${100 - outerPercent}`}
        strokeDashoffset="25"
      />
      <circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke="#e4e4e4"
        transform={transform}
        strokeWidth={(strokeWidth || 1) / scale}
      />
      <animcomps.circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        transform={transform}
        stroke={innerColor || '#10d592'}
        strokeWidth={(strokeWidth || 1) / scale}
        strokeDasharray={animated ? innerDash : `${innerPercent} ${100 - innerPercent}`}
        strokeDashoffset="25"
      />
    </svg>
  );
}
