import React from 'react';
import cx from 'classnames';

import InlineIndicator from './charts/InlineIndicator';
import ConcentricDonuts from './charts/ConcentricDonuts';
import LinearArea from './charts/LinearArea';
import TrendTicker from './TrendTicker';

import completedOnTimeData from '../data/completedOnTimeData';

import styles from '../styles/eventsCompliance.module.scss';

import overdueIcon from '../assets/images/events-compliance-overdue-icon@2x.png';
import escalationsIcon from '../assets/images/events-compliance-escalations-icon@2x.png';

export default function EventsCompliance() {
  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <h2>Events Compliance</h2>
      </div>
      <div className={styles.firstRow}>
        <div className={styles.summary}>
          <div className={styles.title}>Total Events</div>
          <div className={styles.number}>42</div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Number of Event Tasks</div>
          <div className={styles.number}>231</div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Events Completed on Time</div>
          <div className={styles.number}>
            64%
            <TrendTicker className={styles.ticker} up text="5.9%" />
          </div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Duration Accuracy</div>
          <div className={styles.number}>
            27 mins
            <TrendTicker className={styles.ticker} down text="2m" />
          </div>
        </div>
      </div>
      <div className={styles.firstCol}>
        <div className={cx(styles.box, styles.merge)}>
          <h3 className={styles.boxHeading}>Event Compliance</h3>
          <div className={styles.eventsCompliance}>
            <div className={styles.legend}>
              <InlineIndicator color="#10d592" />
              <span>Events Completed</span>
              <span className={styles.number}>60</span>
              <InlineIndicator color="#e53c5c" />
              <span>Critical Critical  Completed</span>
              <span className={styles.number}>30</span>
            </div>
            <div className={styles.chart}>
              <ConcentricDonuts
                animated
                total={100}
                innerValue={30}
                outerValue={60}
                innerColor="#e53c5c"
                outerColor="#10d592"
              />
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <h3 className={styles.boxHeading}>Overdue Tasks</h3>
          <div className={styles.overdueTasks}>
            <div className={styles.legend}>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Warning</span>
              </div>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Critical</span>
              </div>
            </div>
            <div className={styles.table}>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Fire Route Check</span>
              </div>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Alcohol Training</span>
              </div>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Clean Slicer</span>
              </div>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Till Cash Run</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <h3 className={styles.boxHeading}>Top Areas</h3>
          <h4 className={styles.boxSubHeading}>Overview</h4>
          <div className={styles.topAreas}>
            <div className={styles.row}>
              <div className={styles.name}>London</div>
              <TrendTicker up text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Manchester</div>
              <TrendTicker down text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>South West</div>
              <TrendTicker same />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Anglesey</div>
              <TrendTicker down text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Soffolk</div>
              <TrendTicker up text="5.9%" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.secondCol}>
        <div className={styles.keyMetric}>
          <img src={overdueIcon} />
          7 Overdue Tasks
        </div>
        <div className={styles.keyMetric}>
          <img src={escalationsIcon} />
          7 Escalations
        </div>
        <div className={cx(styles.box, styles.merge, styles.completedOnTime)}>
          <div className={styles.sidebar}>
            <h3 className={styles.boxHeading}>Events Completed on Time</h3>
            <h4 className={styles.boxSubHeading}>Percentage of total Events</h4>
            <div className={styles.numbers}>
              <span>64%</span>
              <TrendTicker up text="5.9%" />
            </div>
          </div>
          <div className={styles.chart}>
            <LinearArea data={completedOnTimeData} width={327} height={190} />
          </div>
        </div>
      </div>
    </div>
  );
}
