/* eslint-disable global-require */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { Spring, animated } from 'react-spring';

import styles from '../styles/hqSidebar.module.scss';

const config = [
  {
    title: 'Dashboard',
    link: '/dashboard',
    icon: require('../assets/images/hq-dashboard-icon@2x.png'),
    activeIcon: require('../assets/images/hq-dashboard-icon-active@2x.png'),
  },
  {
    title: 'Timeline',
    link: '/dashboard/timeline',
    icon: require('../assets/images/hq-timeline-icon@2x.png'),
    activeIcon: require('../assets/images/hq-timeline-icon-active@2x.png'),
  },
  {
    title: 'Events',
    icon: require('../assets/images/hq-events-icon@2x.png'),
    activeIcon: require('../assets/images/hq-events-icon-active@2x.png'),
    subMenu: [
      {
        title: 'Events Calendar',
        link: '/dashboard/events/calendar',
      },
      {
        title: 'My Events',
        link: '/dashboard/events/my',
      },
      {
        title: 'Add a New Event',
        link: '/dashboard/events/new',
      },
      {
        title: 'View All Events',
        link: '/dashboard/events/all',
      },
    ],
  },
  {
    title: 'Reports',
    icon: require('../assets/images/hq-reports-icon@2x.png'),
    activeIcon: require('../assets/images/hq-reports-icon-active@2x.png'),
    subMenu: [
      {
        title: 'Events Compliance',
        link: '/dashboard/reports/compliance',
      },
      {
        title: 'Core Operations Compliance',
        link: '/dashboard/reports/coreopscompliance',
      },
      {
        title: 'Event Performance',
        link: '/dashboard/reports/performance',
      },
    ],
  },
  // {
  //   title: 'Approvals',
  //   link: '/dashboard/approvals',
  //   icon: require('../assets/images/hq-approvals-icon@2x.png'),
  //   activeIcon: require('../assets/images/hq-approvals-icon-active@2x.png'),
  // },
  // {
  //   title: 'Settings',
  //   link: '/dashboard/settings',
  //   icon: require('../assets/images/hq-settings-icon@2x.png'),
  //   activeIcon: require('../assets/images/hq-settings-icon-active@2x.png'),
  // },
];

const Link = ({ children, ...props }) => <a {...props}>{children}</a>;

const Item = ({
  title, link, icon, activeIcon, subMenu,
}) => {
  const [open, setOpen] = useState(false);

  const LinkComponent = subMenu ? Link : NavLink;

  const linkProps = subMenu
    ? {
      onClick: () => setOpen(!open),
      className: cx(styles.option, open && styles.activeOption, styles.optionWithSubMenu),
      href: '#',
    }
    : {
      exact: true,
      key: title,
      className: styles.option,
      activeClassName: styles.activeOption,
      to: link,
    };

  return (
    <>
      <LinkComponent {...linkProps}>
        <img src={icon} className={styles.icon} />
        <img src={activeIcon} className={cx(styles.icon, styles.activeIcon)} />
        {title}
      </LinkComponent>
      {Boolean(subMenu) && (
        <Spring from={{ height: 0 }} to={{ height: open ? 'auto' : 0 }}>
          {styleProps => (
            <animated.div
              className={cx(styles.subMenu, open && styles.subMenuBorder)}
              style={styleProps}
            >
              {subMenu.map(item => (
                <NavLink
                  exact
                  key={item.title}
                  className={styles.subMenuItem}
                  activeClassName={styles.activeSubMenuItem}
                  to={item.link}
                >
                  {item.title}
                </NavLink>
              ))}
            </animated.div>
          )}
        </Spring>
      )}
    </>
  );
};

export default function HQSidebar() {
  return (
    <div className={styles.container}>
      {config.map(option => (
        <Item {...option} />
      ))}
    </div>
  );
}
