import promotions from './hq-promotions-icon@2x.png';
import training from './hq-training-icon@2x.png';
import seasonal from './hq-seasonal-icon@2x.png';
import coreOps from './hq-core-ops-icon@2x.png';
import corporate from './hq-corporate-icon@2x.png';
import constructionWork from './hq-construction-work-icon@2x.png';
import storeVisit from './hq-store-visit-icon@2x.png';
import stockTake from './hq-stock-take-icon@2x.png';
import charity from './hq-charity-icon@2x.png';
import localEvent from './hq-local-event-icon@2x.png';
import productRecall from './hq-product-recall-icon@2x.png';
import it from './hq-it-icon@2x.png';
import plannedOutage from './hq-planned-outage-icon@2x.png';
import facilities from './hq-facilities-icon@2x.png';

const iconMap = {
  Promotional: promotions,
  'Core Operations': coreOps,
  'Product Recall': productRecall,
  'Local Event': localEvent,
  Charity: charity,
  Seaonal: seasonal,
  'Stock Take': stockTake,
  'IT hardware/software change': it,
  Facilities: facilities,
  Training: training,
  Corporate: corporate,
  'Planned System Outage': plannedOutage,
  'Construction Work': constructionWork,
  'Store Visit': storeVisit,
};

export default function getIconForEventName(eventName) {
  return iconMap[eventName] || promotions;
}
