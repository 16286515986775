import TYPES from './types';
import API from '../../../api';

import { getAuthToken } from '../../user/selectors';

export function gettingChoices() {
  return {
    type: TYPES.gettingChoices,
  };
}

export function getChoicesSuccess(choices) {
  return {
    type: TYPES.getChoicesSuccess,
    payload: { choices },
  };
}

export function getChoicesFail(error) {
  return {
    type: TYPES.getChoicesFail,
    payload: {
      error,
    },
  };
}

export function getChoices() {
  return async (dispatch, getState) => {
    const state = getState();
    const authToken = getAuthToken(state);
    let res;
    try {
      res = await API.Choices.getChoices(authToken);
    } catch (err) {
      if (err) return dispatch(getChoicesFail(err));
    }
    if (res.error) return dispatch(getChoicesFail(res.error));
    return dispatch(getChoicesSuccess(res));
  };
}
