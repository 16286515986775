import { useEffect, useState } from 'react';

export default function useIsMobile() {
  const mql = window.matchMedia('(max-width: 480px)');
  const [state, setState] = useState(mql.matches);

  function handleChange() {
    setState(mql.matches);
  }

  useEffect(() => {
    mql.addListener(handleChange);
    return () => mql.removeListener(handleChange);
  }, []);

  return state;
}
