import { connect } from 'react-redux';

import Dashboard from '../components/Dashboard';

import { getAccountType, getSelectedPlanDayKey } from '../modules/user/selectors';
import { logout, postPlanAuto } from '../modules/user/actions';

function mapStateToProps(state) {
  return {
    accountType: getAccountType(state),
    dayKey: getSelectedPlanDayKey(state),
  };
}

const mapDispatchToProps = {
  logout,
  postPlanAuto,
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  return {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    autoAssign: () => {
      dispatchProps.postPlanAuto(stateProps.dayKey);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Dashboard);
