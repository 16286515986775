const data = [
  {
    label: 'Abigail Henderson (abigailhenderson@nimblr.co.uk)',
    value: 'abigailhenderson@nimblr.co.uk',
  },
  {
    label: 'Janna Rich (jannarich@nimblr.co.uk)',
    value: 'jannarich@nimblr.co.uk',
  },
  {
    label: 'Elva Bowman (elvabowman@nimblr.co.uk)',
    value: 'elvabowman@nimblr.co.uk',
  },
  {
    label: 'Golden Higgins (goldenhiggins@nimblr.co.uk)',
    value: 'goldenhiggins@nimblr.co.uk',
  },
  {
    label: 'Rosalyn Harris (rosalynharris@nimblr.co.uk)',
    value: 'rosalynharris@nimblr.co.uk',
  },
  {
    label: 'Lou Davis (loudavis@nimblr.co.uk)',
    value: 'loudavis@nimblr.co.uk',
  },
  {
    label: 'Hilda Bauer (hildabauer@nimblr.co.uk)',
    value: 'hildabauer@nimblr.co.uk',
  },
  {
    label: 'Kristy Wilder (kristywilder@nimblr.co.uk)',
    value: 'kristywilder@nimblr.co.uk',
  },
  {
    label: 'Robert Flowers (robertflowers@nimblr.co.uk)',
    value: 'robertflowers@nimblr.co.uk',
  },
  {
    label: 'Veronica Gomez (veronicagomez@nimblr.co.uk)',
    value: 'veronicagomez@nimblr.co.uk',
  },
  {
    label: 'Herminia Hull (herminiahull@nimblr.co.uk)',
    value: 'herminiahull@nimblr.co.uk',
  },
  {
    label: 'Desiree Alvarez (desireealvarez@nimblr.co.uk)',
    value: 'desireealvarez@nimblr.co.uk',
  },
  {
    label: 'Ora Kirkland (orakirkland@nimblr.co.uk)',
    value: 'orakirkland@nimblr.co.uk',
  },
  {
    label: 'Cherry Castro (cherrycastro@nimblr.co.uk)',
    value: 'cherrycastro@nimblr.co.uk',
  },
  {
    label: 'Yesenia Blankenship (yeseniablankenship@nimblr.co.uk)',
    value: 'yeseniablankenship@nimblr.co.uk',
  },
  {
    label: 'Dollie Morgan (dolliemorgan@nimblr.co.uk)',
    value: 'dolliemorgan@nimblr.co.uk',
  },
  {
    label: 'Gwendolyn Morse (gwendolynmorse@nimblr.co.uk)',
    value: 'gwendolynmorse@nimblr.co.uk',
  },
  {
    label: 'Hubbard Beach (hubbardbeach@nimblr.co.uk)',
    value: 'hubbardbeach@nimblr.co.uk',
  },
  {
    label: 'Mcleod Galloway (mcleodgalloway@nimblr.co.uk)',
    value: 'mcleodgalloway@nimblr.co.uk',
  },
  {
    label: 'Parks Medina (parksmedina@nimblr.co.uk)',
    value: 'parksmedina@nimblr.co.uk',
  },
];

export default data;
