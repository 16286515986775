import React from 'react';
import styles from '../../styles/charts/inlineIndicator.module.scss';

export default function InlineIndicator({ className, color }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 10 10"
      preserveAspectRatio="xMidYMid meet"
      className={className || styles.indicator}
    >
      <circle cx="5" cy="5" r="3.25" fill="transparent" stroke={color} strokeWidth="2.5" />
    </svg>
  );
}
