import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';

import Login from '../containers/Login';

import loginStyles from '../styles/login.module.scss';
import logo from '../assets/images/login-nimblr-logo.png';

export default function Home({ isUserLoggedIn }) {
  return (
    <div
      style={{ textAlign: 'center', height: '100vh' }}
      className={classNames(!isUserLoggedIn && loginStyles.background)}
    >
      <img src={logo} className={loginStyles.logo} />
      <div className={loginStyles.loginBox}>
        {isUserLoggedIn && <Redirect to="/dashboard" />}
        <h2 className={loginStyles.title}>Login</h2>
        <Login />
      </div>
    </div>
  );
}

Home.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
};
