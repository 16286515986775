/* eslint-disable no-bitwise */

export default function xmur3(str) {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i += 1) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = h << 13 | h >>> 19;
  }
  return (min, max) => {
    h = Math.imul(h ^ h >>> 16, 2246822507);
    h = Math.imul(h ^ h >>> 13, 3266489909);
    const value = ((h ^= h >>> 16) >>> 0) / (2147483647 * 2);
    return Math.round(value * (max - min) + min);
  };
}
