const data = [
  {
    title: 'Day',
    data: {
      day: [76, 73],
    },
  },
  {
    title: 'Week',
    data: {
      week: [60, 88],
    },
  },
  {
    title: 'Month',
    data: {
      month: [70, 65],
    },
  },
];

export default data;
