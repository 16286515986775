import React from 'react';
import cxx from 'classnames';
import { useSpring, animated as animcomps } from 'react-spring/hooks';

import styles from '../../styles/charts/inlineDonut.module.scss';

export default function InlineDonut({
  value,
  total,
  showPercent,
  className,
  subtitle,
  strokeWidth,
  color,
  animated,
}) {
  const percent = (value / total) * 100;
  const { strokeDasharray } = useSpring({
    strokeDasharray: `${percent} ${100 - percent}`,
    from: { strokeDasharray: '0 100' },
  });
  return (
    <svg width="100%" height="100%" viewBox="0 0 34 34" className={className}>
      <circle cx="17" cy="17" r="15.91549430918954" fill="#fff" />
      <circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke="#e4e4e4"
        strokeWidth={strokeWidth || 1}
      />
      <animcomps.circle
        cx="17"
        cy="17"
        r="15.91549430918954"
        fill="transparent"
        stroke={color || '#2d96eb'}
        strokeWidth={strokeWidth || 1}
        strokeDasharray={animated ? strokeDasharray : `${percent} ${100 - percent}`}
        strokeDashoffset="25"
      />
      <text x="50%" y="50%" className={cxx(styles.text, Boolean(subtitle) && styles.withSubtitle)}>
        {showPercent ? `${percent}%` : value}
      </text>
      {Boolean(subtitle) && (
        <text x="50%" y="50%" className={styles.subtitle}>
          {subtitle}
        </text>
      )}
    </svg>
  );
}
