import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import InlineIndicator from './charts/InlineIndicator';
import InlineDonut from './charts/InlineDonut';
import LinearArea from './charts/LinearArea';
import TrendTicker from './TrendTicker';
import Select from './Select';

import styles from '../styles/eventPerformance.module.scss';

import todaysReportData from '../data/todaysReportData';

import randomWithSeed from '../util/randomWithSeed';

export default function EventPerformance({ events = [], getEvents }) {
  useEffect(() => getEvents(), []);

  if (events.length === 0) {
    return null;
  }

  const orderedEvents = [...events].reverse().map(event => ({ ...event, id: event._id }));

  const [chart, setChart] = useState(0);
  const [event, setEvent] = useState(orderedEvents[0]);

  const RNG = randomWithSeed(event._id);

  const avgTimeToComplete = `${RNG(0, 3)}h ${RNG(2, 59)}mins`;
  const overdueStores = RNG(0, 20);
  const actualVsEstimate = `${RNG(60, 100)}%`;
  const eventsCompleted = RNG(10, 100);
  const criticalCompleted = RNG(10, 100);

  return (
    <>
      <div className={styles.titleRow}>
        <h2>Event Performance</h2>
      </div>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <h2>{event.name}</h2>
          <p className={styles.infoItem}>
            <b>Description</b> - {event.description || 'None'}
          </p>
          <p className={styles.infoItem}>
            <b>Type</b> - {(event.type && event.type.name) || 'Unknown'}
          </p>
        </div>
        <div className={styles.headerSelect}>
          <h3>Choose Event</h3>
          <Select
            clearable={false}
            options={orderedEvents}
            value={event}
            onChange={(_, id) => setEvent(orderedEvents.find(e => e.id === id))}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.keyMetrics}>
            <div className={styles.summary}>
              <div className={styles.title}>Average Time to Complete</div>
              <div className={styles.number}>{avgTimeToComplete}</div>
            </div>
            <div className={styles.summary}>
              <div className={styles.title}>Overdue Stores</div>
              <div className={styles.number}>{overdueStores}</div>
            </div>
            <div className={styles.summary}>
              <div className={styles.title}>Actual Completion Time vs Estimate</div>
              <div className={styles.number}>{actualVsEstimate}</div>
            </div>
          </div>
          <div className={cx(styles.box, styles.reportGraphContainer)}>
            <h3 className={styles.boxHeading}>Today's Report</h3>
            <div className={styles.todaysReport}>
              <div className={styles.buttons}>
                <button className={cx(chart === 0 && styles.active)} onClick={() => setChart(0)}>
                  Event Progess
                </button>
                <button className={cx(chart === 1 && styles.active)} onClick={() => setChart(1)}>
                  Tasks Complete
                </button>
                <button className={cx(chart === 2 && styles.active)} onClick={() => setChart(2)}>
                  Task Overdue
                </button>
                <button className={cx(chart === 3 && styles.active)} onClick={() => setChart(3)}>
                  Avg Time to Complete
                </button>
              </div>
              <div className={styles.chart}>
                <LinearArea
                  data={todaysReportData(RNG)[chart]}
                  gradientOpacity={0.25}
                  lineWidth={4}
                  width={327}
                  height={190}
                />
              </div>
            </div>
          </div>
          <div className={cx(styles.box, styles.centered)}>
            <div className={styles.eventsCompliance}>
              <div className={styles.legend}>
                <InlineIndicator color="#10d592" />
                <span>Events Completed</span>
                <span className={styles.number}>{`${eventsCompleted}%`}</span>
                <InlineIndicator color="#e53c5c" />
                <span>Critical Completed</span>
                <span className={styles.number}>{`${criticalCompleted}%`}</span>
              </div>
              <div className={styles.chart}>
                <InlineDonut
                  showPercent
                  className={styles.donut}
                  color="#10d592"
                  value={eventsCompleted}
                  total={100}
                  strokeWidth="2"
                  animated
                />
              </div>
              <div className={styles.chart}>
                <InlineDonut
                  showPercent
                  className={styles.donut}
                  color="#e53c5c"
                  value={criticalCompleted}
                  total={100}
                  strokeWidth="2"
                  animated
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={cx(styles.box, styles.noPadding)}>
            <h3 className={styles.boxHeading}>Most Overdue Tasks</h3>
            <h4 className={styles.boxSubHeading}>Overview</h4>
            <div className={styles.topAreas}>
              <div className={cx(styles.row, styles.border)}>
                <div className={styles.name}>1. Place Signane at end of aisle</div>
              </div>
              <div className={cx(styles.row, styles.border)}>
                <div className={styles.name}>2. Front of Store Display Set</div>
              </div>
              <div className={cx(styles.row, styles.border)}>
                <div className={styles.name}>3. Checking Shelf edge labels</div>
              </div>
              <div className={cx(styles.row, styles.border)}>
                <div className={styles.name}>4. POS marketing materials</div>
              </div>
            </div>
          </div>
          <div className={styles.box}>
            <h3 className={styles.boxHeading}>Top Stores</h3>
            <h4 className={styles.boxSubHeading}>Overview</h4>
            <div className={styles.topAreas}>
              <div className={styles.row}>
                <div className={styles.name}>Bow</div>
                <TrendTicker up text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Whitechapel</div>
                <TrendTicker down text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Harringay</div>
                <TrendTicker same />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Anglesey</div>
                <TrendTicker down text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Soffolk</div>
                <TrendTicker up text="5.9%" />
              </div>
            </div>
          </div>
          <div className={styles.box}>
            <h3 className={styles.boxHeading}>Top Areas</h3>
            <h4 className={styles.boxSubHeading}>Overview</h4>
            <div className={styles.topAreas}>
              <div className={styles.row}>
                <div className={styles.name}>London</div>
                <TrendTicker up text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Manchester</div>
                <TrendTicker down text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>South West</div>
                <TrendTicker same />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Anglesey</div>
                <TrendTicker down text="5.9%" />
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Soffolk</div>
                <TrendTicker up text="5.9%" />
              </div>
            </div>
          </div>
          <div className={styles.box}>
            <h3 className={styles.boxHeading}>Overdue Stores</h3>
            <h4 className={styles.boxSubHeading}>Overview</h4>
            <div className={styles.topAreas}>
              <div className={styles.row}>
                <div className={styles.name}>Didsbury</div>
                <span className={styles.time}>2h 33m</span>
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Bethseda</div>
                <span className={styles.time}>2h 20m</span>
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Frome</div>
                <span className={styles.time}>2h 09m</span>
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Brighton</div>
                <span className={styles.time}>1h 35m</span>
              </div>
              <div className={styles.row}>
                <div className={styles.name}>Norwich</div>
                <span className={styles.time}>0h 58m</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
