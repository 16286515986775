import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import 'react-select/dist/react-select.css';

export default class Select extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(value) {
    const { onChange, name, multi } = this.props;
    if (multi) {
      onChange(name, value.map(v => v.id));
    } else {
      onChange(name, value && value.id);
    }
  }

  handleBlur() {
    const { onBlur, name } = this.props;
    onBlur(name, true);
  }

  render() {
    const {
      name, options, multi, value, onChange, onBlur, ...props
    } = this.props;
    const transformedValue = multi
      ? value.map(val => options.find(option => option.id === val))
      : value;
    return (
      <ReactSelect
        name={name}
        multi={multi}
        options={options}
        value={transformedValue}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        labelKey="name"
        valueKey="id"
        {...props}
      />
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.obj),
};

Select.defaultProps = {
  multi: false,
  options: [],
  onChange: () => null,
  onBlur: () => null,
};
