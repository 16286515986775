import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDateTime from 'react-datetime';
import moment from 'moment';

import 'react-datetime/css/react-datetime.css';

export default class DateTime extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
  }

  handleChange(value) {
    const { onChange, name } = this.props;
    if (typeof value === 'string') {
      onChange(name, value);
    } else {
      onChange(name, value.toDate());
    }
  }

  handleBlur() {
    const { onBlur, name } = this.props;
    onBlur(name, true);
  }

  isValidDate(currentDate) {
    const { startDate, endDate } = this.props;
    const fromStart = startDate ? currentDate.isSameOrAfter(startDate, 'day') : true;
    const fromEnd = endDate ? currentDate.isSameOrBefore(endDate, 'day') : true;
    return fromStart && fromEnd;
  }

  render() {
    const {
      name, value, onChange, onBlur, valid, ...props
    } = this.props;
    return (
      <ReactDateTime
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        isValidDate={this.isValidDate}
        dateFormat="DD/MM/YYYY"
        {...props}
      />
    );
  }
}

DateTime.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  multi: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

DateTime.defaultProps = {
  multi: false,
  options: [],
};
