const data = [
  {
    id: 'datum',
    data: [
      {
        x: 'January',
        y: 40,
      },
      {
        x: 'February',
        y: 180,
      },
      {
        x: 'April',
        y: 140,
      },
      {
        x: 'June',
        y: 216,
      },
      {
        x: 'July',
        y: 100,
      },
      {
        x: 'August',
        y: 240,
      },
      {
        x: 'December',
        y: 100,
      },
    ],
  },
];

export default data;
