function preventDefault(e) {
  e.preventDefault();
}

export function disableTouchScroll() {
  document.body.addEventListener('touchmove', preventDefault, { passive: false });
}

export function enableTouchScroll() {
  document.body.removeEventListener('touchmove', preventDefault, { passive: false });
}
