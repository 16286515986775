import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Button } from 'reactstrap';

import ReportBox from './ReportBox';
import InlineIndicator from './charts/InlineIndicator';
import Donut from './charts/Donut';
import BarChartThick from './charts/BarChartThick';

import styles from '../styles/hqDashboard.module.scss';
import reportStyles from '../styles/report.module.scss';

import pieChartData from '../data/pieChartData';
import eventPerformanceEvents from '../data/eventPerformanceEvents';

import eventsIcon from '../assets/images/hq-report-events-icon@2x.png';
import alertsIcon from '../assets/images/hq-report-alerts-icon@2x.png';
import approvalsIcon from '../assets/images/hq-report-approvals-icon@2x.png';

function TaskCompliance() {
  const COLORS = {
    Management: '#4f80f7',
    'Core Operations': '#ffda83',
    'Legal to Trade': '#b087e8',
    Facilities: '#ff7576',
    'Health & Safety': '#10d592',
    Uncompleted: 'rgb(228, 228, 228)',
  };

  const addUncompleted = (datum) => {
    const total = datum.reduce((acc, point) => acc + point.value, 0);
    return datum.concat({
      name: 'Uncompleted',
      value: 100 - total,
    });
  };

  const todaysCompliance = pieChartData[0].data.reduce((acc, val) => acc + val.value, 0);

  return (
    <ReportBox
      title="Task Compliance"
      subtitle="Percantage per task"
      data={pieChartData}
      className={styles.complianceReportBox}
      keyMetric={`${todaysCompliance}%`}
    >
      {datum => (
        <div className={reportStyles.complianceContainer}>
          <Donut
            className={reportStyles.complianceDonut}
            data={datum}
            subtitle="Complete"
            colors={COLORS}
            strokeWidth="1.5"
          />
          <div className={reportStyles.complianceLegend}>
            {addUncompleted(datum).map(point => (
              <div className={reportStyles.legendItem}>
                <InlineIndicator color={COLORS[point.name]} />
                <h5>{point.name}</h5>
                <span>{`${point.value}%`}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </ReportBox>
  );
}

function EventsChart() {
  const colors = {
    Draft: '#4f80f7',
    Approved: '#ffda83',
    Live: '#b087e8',
    'In Progress': '#5fe3a1',
    Overdue: '#ff7576',
    Completed: '#e3e3e3',
  };

  return (
    <ReportBox
      title="Events"
      subtitle="Events By Stage"
      data={eventPerformanceEvents}
      className={styles.complianceReportBox}
    >
      {datum => <BarChartThick data={datum} colors={colors} />}
    </ReportBox>
  );
}

export default function HQDashboard({ approvals = [], events = [] }) {
  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        <div className={styles.header}>
          <img src={eventsIcon} />
          25 Events
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBar}>
            <div className={cx(styles.progress, styles.blue)} style={{ width: '75%' }} />
          </div>
          75%
        </div>
        <div className={styles.footer}>In progress Events</div>
      </div>
      <div className={styles.firstRow}>
        <div className={styles.header}>
          <img src={alertsIcon} />7 Events
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBar}>
            <div className={cx(styles.progress, styles.red)} style={{ width: '75%' }} />
          </div>
          75%
        </div>
        <div className={styles.footer}>Overdue Event Tasks</div>
      </div>
      <div className={styles.firstRow}>
        <div className={styles.header}>
          <img src={approvalsIcon} />
          25 Approvals
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBar}>
            <div className={cx(styles.progress, styles.green)} style={{ width: '75%' }} />
          </div>
          75%
        </div>
        <div className={styles.footer}>Approval Progress</div>
      </div>
      <div className={styles.secondRow}>
        <TaskCompliance />
      </div>
      <div className={styles.secondRow}>
        <EventsChart />
      </div>
      <div className={styles.thirdRow}>
        <h3 className={styles.title}>Quick Approvals</h3>
        <div className={cx(styles.body, styles.approvals)}>
          {approvals.length === 0 && (
            <div className={styles.noApprovals}>No awaiting approvals</div>
          )}
          {approvals.map(event => (
            <div className={styles.row}>
              <div className={styles.eventCol}>
                <div className={styles.eventTitle}>
                  <b>{event.code}</b> {event.name}
                </div>
                <div className={styles.creator}>
                  <div className={styles.initials}>{event.createdBy}</div>
                  {event.createdBy}
                </div>
              </div>
              <Button className={styles.button}>Approve</Button>
              <Button className={cx(styles.button, styles.secondary)}>Reject</Button>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.thirdRow}>
        <h3 className={styles.title}>Top 5 Alerts</h3>
        <div className={styles.topAlerts}>
          <div className={styles.store}>Brixton Road</div>
          <div className={styles.name}>Fire Route Check</div>
          <div className={cx(styles.status, styles.warning)}>Warning</div>
          <div className={styles.store}>Camden</div>
          <div className={styles.name}>25% off Clothing</div>
          <div className={cx(styles.status, styles.critical)}>Critical</div>
          <div className={styles.store}>Bangor</div>
          <div className={styles.name}>25% off Clothing</div>
          <div className={cx(styles.status, styles.critical)}>Critical</div>
          <div className={styles.store}>Vauxhall</div>
          <div className={styles.name}>25% off Clothing</div>
          <div className={cx(styles.status, styles.critical)}>Critical</div>
          <div className={styles.store}>Chester</div>
          <div className={styles.name}>Event Signage</div>
          <div className={cx(styles.status, styles.warning)}>Warning</div>
        </div>
      </div>
      <div className={styles.thirdRow}>
        <h3 className={styles.title}>Recent Activities</h3>
        <div className={cx(styles.body, styles.recentActivities)}>
          {[...events].reverse().slice(0, 5).map(event => (
            <div className={styles.row}>
              <div className={styles.activity}>
                {`Abigail Henderson approved your event ${event.eventId} ${event.name}`}
              </div>
              <div className={styles.time}>
                {moment.utc(event.createdAt).toNow(true)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
