import { createSelector } from 'reselect';

export const getFormsChoices = state => state.formsChoices;

export const getCategories = createSelector(getFormsChoices, choices => choices.categories);

export const getSubCategories = createSelector(getFormsChoices, choices => choices.subCategories);

export const getTeams = createSelector(getFormsChoices, choices => choices.teams);

export const getStores = createSelector(
  getFormsChoices,
  choices =>
    (choices.stores
      ? choices.stores.map(store => ({
        label: store.name,
        value: store.id,
        group: store.group,
        format: store.format,
      }))
      : []),
);

export const getEventTypes = createSelector(getFormsChoices, choices => choices.eventTypes);

export const getEventPurposes = createSelector(getFormsChoices, choices => choices.eventPurposes);

export const getPriorityOptions = createSelector(
  getFormsChoices,
  choices => choices.priorityOptions,
);
