import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import pull from 'lodash/pull';
import cx from 'classnames';

import 'react-select/dist/react-select.css';

import styles from '../styles/eventForm.module.scss';

import approvalListData from '../data/approvalList';

const MAX_ITEMS = 5;

export default class ApprovalList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { selectedList: [] };
  }

  handleChange(item) {
    this.setState((prevState) => {
      const selectedList = prevState.selectedList.concat(item);
      this.bubbleEvent(selectedList);
      return {
        selectedList,
      };
    });
  }

  removeItem(item) {
    this.setState((prevState) => {
      const selectedList = pull(prevState.selectedList, item);
      this.bubbleEvent(selectedList);
      return {
        selectedList,
      };
    });
  }

  bubbleEvent(selectedList) {
    const { onListChange } = this.props;
    if (onListChange) onListChange(selectedList);
  }

  render() {
    const { selectedList } = this.state;
    const selectData = pull(approvalListData, ...selectedList);
    return (
      <div className={styles.formRow}>
        <div className={cx(styles.formCol, styles.gray)}>
          <label>Select Approvers</label>
          <ReactSelect
            disabled={selectedList.length === MAX_ITEMS}
            options={selectData}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.formCol}>
          <label>Approvers Order</label>
          <div className={cx(styles.pillBox, styles.approvers)}>
            {selectedList.map((item, index) => (
              <div className={styles.pill} key={item.label}>
                {`${index + 1} - ${item.label}`}
                <span className={styles.delete} onClick={() => this.removeItem(item)}>
                  x
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

ApprovalList.propTypes = {
  onListChange: PropTypes.func,
};

ApprovalList.defaultProps = {
  onListChange: () => null,
};
