import scrollbarsCSS from '../styles/Scrollbars';

export default function addScrollbars() {
  const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
  if (!isMacLike) {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(scrollbarsCSS));
    document.head.appendChild(style);
  }
}
