import React from 'react';
import cx from 'classnames';

import styles from '../../styles/charts/barChartThick.module.scss';

export default function BarChartThick({ data, className, colors }) {
  const gridNum = Math.ceil(Math.max(...data.map(d => d.value)) / 5);

  let gridTemplateAreas = '';
  for (let i = 1; i <= gridNum; i += 1) {
    let row = `tick-${i} `;
    for (let k = 0; k <= data.length; k += 1) {
      row += `bar-${k + 1} `;
    }
    gridTemplateAreas += `"${row}" `;
  }

  let legendTemplate = '"empty ';
  for (let k = 0; k <= data.length; k += 1) {
    legendTemplate += `label-${k + 1} `;
  }
  legendTemplate += '"';
  gridTemplateAreas += legendTemplate;

  const style = {
    gridTemplateAreas,
    gridTemplateColumns: `min-content repeat(${data.length}, 1fr)`,
    gridTemplateRows: `repeat(${gridNum}, 1fr) min-content`,
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      {[...Array(gridNum)].map((v, i) => (
        <div className={styles.tick} style={{ gridArea: `tick-${i + 1}` }}>
          {(gridNum - i) * 5}
        </div>
      ))}
      {data.map(({ value, label }, i) => (
        <>
          <div className={styles.barContainer} style={{ gridArea: `bar-${i + 1}` }}>
            <div
              className={styles.bar}
              style={{
                height: `${(value / (gridNum * 5)) * 100}%`,
                backgroundColor: colors[label],
              }}
            />
          </div>
          <div className={styles.label} style={{ gridArea: `label-${i + 1}` }}>
            {label}
          </div>
        </>
      ))}
      <div style={{ gridArea: 'empty' }} />
    </div>
  );
}
