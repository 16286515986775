import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import without from 'lodash/without';
import uniqBy from 'lodash/uniqBy';
import cx from 'classnames';

import 'react-select/dist/react-select.css';

import styles from '../styles/eventForm.module.scss';

export default class SelectStores extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(item) {
    const { selectedStores } = this.props;
    this.bubbleEvent(selectedStores.concat(item));
  }

  handleBulkAdd(type) {
    return (item) => {
      const { stores, selectedStores } = this.props;
      const eligibleStores = stores.filter(store => store[type] === item.value);
      this.bubbleEvent(uniqBy(selectedStores.concat(eligibleStores), 'value'));
    };
  }

  removeItem(item) {
    const { selectedStores } = this.props;
    this.bubbleEvent(without(selectedStores, item));
  }

  bubbleEvent(selectedList) {
    const { onListChange } = this.props;
    if (onListChange) onListChange(selectedList);
  }

  toggleAll(add) {
    const { selectedStores, stores } = this.props;
    if (add) {
      this.bubbleEvent(uniqBy(selectedStores.concat(stores), 'value'));
    } else {
      this.bubbleEvent([]);
    }
  }

  render() {
    const { stores, selectedStores } = this.props;
    const individualSelectData = without(stores, ...selectedStores);
    const formatSelectData = uniqBy(
      individualSelectData.map(store => ({ label: store.format, value: store.format })),
      'value',
    ).filter(store => store.value);
    const groupSelectData = uniqBy(
      individualSelectData.map(store => ({ label: store.group, value: store.group })),
      'value',
    ).filter(store => store.value);

    const isAllStoresChecked = stores.length === selectedStores.length;

    return (
      <div className={styles.formRow}>
        <div className={styles.formCol}>
          <label>Add Individual Stores</label>
          <ReactSelect options={individualSelectData} onChange={this.handleChange} />
          <label>Add Format</label>
          <ReactSelect options={formatSelectData} onChange={this.handleBulkAdd('format')} />
          <label>Smart Store Groups</label>
          <ReactSelect options={groupSelectData} onChange={this.handleBulkAdd('group')} />
          <label>
            <input
              type="checkbox"
              checked={isAllStoresChecked}
              onChange={() => this.toggleAll(!isAllStoresChecked)}
            />{' '}
            All Stores
          </label>
        </div>
        <div className={styles.formCol}>
          <label>Selected Stores</label>
          <div className={cx(styles.pillBox, styles.selectedStores)}>
            {selectedStores.map(item => (
              <div key={item.value} className={styles.pill}>
                {item.label}
                <span className={styles.delete} onClick={() => this.removeItem(item)}>
                  x
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

SelectStores.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.object),
  onListChange: PropTypes.func,
};

SelectStores.defaultProps = {
  stores: [],
  onListChange: () => null,
};
