const data = [
  {
    id: '2',
    data: [
      {
        x: 'January',
        y: 40,
      },
      {
        x: 'February',
        y: 180,
      },
      {
        x: 'April',
        y: 140,
      },
      {
        x: 'June',
        y: 216,
      },
      {
        x: 'July',
        y: 100,
      },
      {
        x: 'August',
        y: 240,
      },
      {
        x: 'December',
        y: 100,
      },
    ],
  },
  {
    id: '1',
    data: [
      {
        x: 'January',
        y: 100,
      },
      {
        x: 'February',
        y: 250,
      },
      {
        x: 'April',
        y: 111,
      },
      {
        x: 'June',
        y: 120,
      },
      {
        x: 'July',
        y: 150,
      },
      {
        x: 'August',
        y: 110,
      },
      {
        x: 'December',
        y: 60,
      },
    ],
  },
];

export default data;
