import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import cx from 'classnames';

import styles from '../styles/eventForm.module.scss';

const Pill = SortableElement(({ task, editTask, removeTask }) => (
  <div key={task.name} className={styles.pill}>
    <span onClick={() => editTask(task.clientId)} title={task.name}>{task.name}</span>
    <span className={styles.delete} onClick={() => removeTask(task.clientId)}>
      x
    </span>
  </div>
));

export default SortableContainer(({ tasks, editTask, removeTask }) => (
  <div className={cx(styles.pillBox, styles.tasks)}>
    {tasks.map((task, index) => (
      <Pill
        key={`task-${task.name}`}
        index={index}
        task={task}
        editTask={editTask}
        removeTask={removeTask}
      />
    ))}
  </div>
));
