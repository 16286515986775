import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from '../styles/dayNav.module.scss';

export default function DayNav({ items }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        {items.map((item, index) => (
          <a
            tabIndex={index}
            className={cx(
              styles.day,
              item.active && (item.activeClassName || styles.activeDay),
              item.className,
            )}
            onClick={item.onClick}
          >
            {item.name}
          </a>
        ))}
      </div>
    </div>
  );
}

DayNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
  })),
};

DayNav.defaultProps = {
  items: [],
};
