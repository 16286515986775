import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tether from 'react-tether';
import Slider from 'rc-slider';
import moment from 'moment';
import cx from 'classnames';

import styles from '../styles/timeline.module.scss';

import '../styles/slider.scss';
import Panel from './Panel';
import DayNav from './DayNav';
import EventModal from '../containers/EventModal';
import MeasureScale from './MeasureScale';

import laneBackArrow from '../assets/images/lane-back-arrow@3x.png';
import laneForwardArrow from '../assets/images/lane-forward-arrow@3x.png';

const runwayTitles = [
  'Seasonal Events',
  'Marketing Events',
  'Local Events',
  'Initiatives',
  'Trading Events',
  'Project Events',
];

const TIME_OFFSET = 1000 * 60 * 60 * 24 * 15;
const SLIDER_MIN = 0;
const SLIDER_MAX = 1000;
const MIN_LANE_WIDTH = 190;

const interpolate = (value, originalLower, originalUpper, lower, upper) => {
  const ratio = (value - originalLower) / (originalUpper - originalLower);
  return lower + (ratio * (upper - lower));
};

const getNowAndNextYear = () => {
  const lowerTime = moment.utc().startOf('month').toDate();
  const upperTime = moment.utc().startOf('month').add(12, 'months').toDate();
  return [lowerTime, upperTime];
};

export default class Runway extends Component {
  constructor(props) {
    super(props);
    this.mapSigns = this.mapSigns.bind(this);
    this.mapToBins = this.mapToBins.bind(this);
    this.renderMonths = this.renderMonths.bind(this);
    this.renderMonthMarks = this.renderMonthMarks.bind(this);
    this.onSliderChange = this.onSliderChange.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.renderLaneSwitchArrows = this.renderLaneSwitchArrows.bind(this);
    this.state = {
      dragging: false,
      activeLanes: [0, 6],
      setWithMonth: true,
      perspectiveScale: 0.5,
    };
    this.elementRef = React.createRef();
  }

  componentWillMount() {
    this.props.getEvents();
    this.props.getTasks();
  }

  componentDidMount() {
    const now = moment().subtract((TIME_OFFSET * 2) / 10, 'ms');
    this.props.setRunwayBounds(now.toDate(), now.add(TIME_OFFSET * 2, 'ms').toDate());
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onSliderChange(value) {
    const [lowerTime, upperTime] = getNowAndNextYear();
    const interpolatedValue = interpolate(
      value,
      SLIDER_MIN,
      SLIDER_MAX,
      lowerTime.getTime(),
      upperTime.getTime(),
    );
    const newLowerBound = new Date(parseInt(interpolatedValue - TIME_OFFSET, 10));
    const newUpperBound = new Date(parseInt(interpolatedValue + TIME_OFFSET, 10));
    if (!this.state.setWithMonth) {
      this.props.setRunwayBounds(newLowerBound, newUpperBound);
    } else {
      this.setState({ setWithMonth: false });
    }
  }

  onMouseDown(event) {
    const { bounds: { lower } } = this.props;
    this.startY = event.nativeEvent.pageY;
    this.startLower = lower;
    const { height } = event.nativeEvent.target.getBoundingClientRect();
    this.runwayHeight = height;
    this.setState({ dragging: true });
  }

  onMouseMove(event) {
    event.preventDefault();
    const { setRunwayBounds } = this.props;
    const diff = event.nativeEvent.pageY - this.startY;
    const lowerTime = new Date(this.startLower).getTime()
      + ((diff / this.runwayHeight) * (TIME_OFFSET * 2));
    const upperTime = lowerTime + (TIME_OFFSET * 2);
    setRunwayBounds(new Date(lowerTime), new Date(upperTime));
  }

  onMouseUp() {
    this.setState({ dragging: false });
  }

  onTouchStart(e) {
    this.onMouseDown({ nativeEvent: e.nativeEvent.changedTouches[0] });
  }

  onTouchMove(e) {
    this.onMouseMove({
      nativeEvent: e.nativeEvent.changedTouches[0],
      preventDefault: e.nativeEvent.preventDefault.bind(e.nativeEvent),
    });
  }

  onTouchEnd(e) {
    this.onMouseUp({ nativeEvent: e.nativeEvent.changedTouches[0] });
  }

  /* eslint-disable */
  mapSigns(items, lowerDate, upperDate) {
    const { selectEvent, deselectEvent } = this.props;
    const {
      activeLanes: [minLane, maxLane],
      perspectiveScale,
    } = this.state;
    const numLanes = Math.min(maxLane - minLane + 1, 6);
    return items.reverse().map(item => {
      const dateTime = new Date(item.end).getTime();
      const lowerDateTime = new Date(lowerDate).getTime();
      const upperDateTime = new Date(upperDate).getTime();
      if (dateTime < lowerDateTime || dateTime > upperDateTime) return null;
      const top = interpolate(dateTime, lowerDateTime, upperDateTime, 100, 4);
      const scale = interpolate(dateTime, lowerDateTime, upperDateTime, 1, perspectiveScale);
      const transform = `scale(${scale}) translateY(calc(100% * (1 - ${scale}) / 2))`;
      return (
        <Panel
          key={item._id}
          top={top}
          item={item}
          transform={transform}
          onMouseWheel={this.onMouseWheel}
          selectEvent={selectEvent}
          deselectEvent={deselectEvent}
          numLanes={numLanes}
        />
      );
    });
  }

  handleResize() {
    if (!this.elementRef.current) return;
    const { width } = this.elementRef.current.getBoundingClientRect();
    const maxLanes = Math.floor(width / MIN_LANE_WIDTH);

    this.setState(prevState => ({
      activeLanes: [
        0,
        maxLanes - 1,
      ],
    }));
  }

  onMouseWheel(event) {
    const { bounds: { lower }, setRunwayBounds } = this.props;
    event.preventDefault();
    event.stopPropagation();
    const lowerTime = new Date(lower).getTime() + event.nativeEvent.deltaY * 1000 * 60 * 60 * 1;
    const upperTime = lowerTime + TIME_OFFSET * 2;
    setRunwayBounds(new Date(lowerTime), new Date(upperTime));
  }

  calcSliderValue(lower, upper, lowerMax, upperMax) {
    const lowerTime = new Date(lower).getTime();
    const upperTime = new Date(upper).getTime();
    const sliderTime = (upperTime - lowerTime) / 2 + lowerTime;
    return interpolate(sliderTime, lowerMax, upperMax, SLIDER_MIN, SLIDER_MAX);
  }

  renderMonthMarks(lowerDate, upperDate) {
    const lowerMoment = moment(lowerDate);
    const upperMoment = moment(upperDate);
    let month = lowerMoment
      .clone()
      .add(1, 'month')
      .startOf('month');
    const marks = [];
    while (month.isBefore(upperMoment)) {
      let name = month.format('MMMM, YYYY');
      const time = month.valueOf();
      if (Math.abs(moment.duration(month.diff()).asDays()) <= 3) {
        name = '';
      }
      marks.push({ name, time });
      month = month
        .clone()
        .add(1, 'month')
        .startOf('month');
    }
    return marks.map(mark => {
      const lowerDateTime = new Date(lowerDate).getTime();
      const upperDateTime = new Date(upperDate).getTime();
      const top = interpolate(mark.time, lowerDateTime, upperDateTime, 100, 4);
      return (
        <Tether
          key={mark.time}
          attachment="middle right"
          targetAttachment="middle left"
          constraints={[
            {
              to: 'scrollParent',
            },
          ]}
        >
          <div className={styles.gridLineTether} style={{ top: `${top}%` }} />
          <div className={styles.gridLine} onWheel={this.onMouseWheel}>
            <span>{mark.name}</span>
          </div>
        </Tether>
      );
    });
  }

  renderNowMark(lowerDate, upperDate) {
    const lowerDateTime = new Date(lowerDate).getTime();
    const upperDateTime = new Date(upperDate).getTime();
    const nowDateTime = new Date().getTime();
    if (lowerDateTime > nowDateTime || upperDateTime < nowDateTime) return null;
    const top = interpolate(nowDateTime, lowerDateTime, upperDateTime, 100, 4);
    return (
      <Tether
        attachment="middle right"
        targetAttachment="middle left"
        constraints={[
          {
            to: 'scrollParent',
          },
        ]}
      >
        <div className={cx(styles.gridLineTether, styles.nowGridLineTether)} style={{ top: `${top}%` }} />
        <div className={styles.gridLine} onWheel={this.onMouseWheel}>
          <span>
            Today
            <br />
            {moment(nowDateTime).format('Do MMMM, YYYY')}
          </span>
        </div>
      </Tether>
    );
  }

  mapToBins(events, lower, upper) {
    const bins = events.map(e => ({ ...e, bin: parseInt(new Date(e.end).getTime(), 10) % 6 }));
    return [...Array(6)].map((v, i) => {
      const { activeLanes: [minLane, maxLane] } = this.state;
      if (i < minLane || i > maxLane) return null;
      return (
        <section className={styles.perspectiveTiles} key={i}>
          {this.mapSigns(bins.filter(e => e.bin === i), lower, upper)}
        </section>
      )
    });
  }
  /* eslint-enable */

  renderLaneSwitchArrows() {
    const { activeLanes: [minLane, maxLane] } = this.state;
    if (((maxLane - minLane) + 1) > 6) return null;

    const moveUp = () => this.setState({ activeLanes: [minLane + 1, maxLane + 1] });
    const moveDown = () => this.setState({ activeLanes: [minLane - 1, maxLane - 1] });

    const downEnabled = minLane !== 0;
    const upEnabled = maxLane + 1 < 6;

    return (
      <Fragment>
        <div onClick={downEnabled ? moveDown : null} className={cx(styles.laneSwitchArrow, styles.left)}>
          <img src={laneBackArrow} />
        </div>
        <div onClick={upEnabled ? moveUp : null} className={cx(styles.laneSwitchArrow, styles.right)}>
          <img src={laneForwardArrow} />
        </div>
      </Fragment>
    );
  }

  renderMonths() {
    const monthItems = [...Array(12).keys()].map((offset) => {
      const dayOffset = 1;
      const monthMoment = moment.utc().startOf('month').add(offset, 'month');
      const lowerBoundary = monthMoment.clone().subtract(dayOffset, 'days');
      const endOfMonth = lowerBoundary.clone().add(TIME_OFFSET * 2, 'ms').toDate();
      const active = monthMoment.isSame(moment.utc(this.props.bounds.lower).add(dayOffset, 'day'), 'month');

      return {
        name: monthMoment.format('MMMM'),
        onClick: () => {
          this.setState({ setWithMonth: true });
          this.props.setRunwayBounds(lowerBoundary.toDate(), endOfMonth);
        },
        active,
      };
    });

    return <DayNav items={monthItems} />;
  }

  render() {
    const { events, bounds } = this.props;
    if (!events) return null;
    const {
      dragging,
      activeLanes: [minLane, maxLane],
    } = this.state;
    const { lower, upper } = bounds;
    const [lowerTime, upperTime] = getNowAndNextYear();
    const sliderValue = this.calcSliderValue(lower, upper, lowerTime, upperTime);
    const numLanes = Math.min(maxLane - minLane + 1, 6);

    return (
      <div className="runway-container" ref={this.elementRef}>
        <EventModal />
        {this.renderMonths()}
        <div
          className={styles.perspectiveContainer}
          onWheel={this.onMouseWheel}
        >
          <div
            className={cx(
              styles.perspective,
              styles[`per-${numLanes}`],
              dragging && 'runway-dragging',
            )}
            role="presentation"
            onMouseDown={this.onMouseDown}
            onMouseMove={dragging ? this.onMouseMove : undefined}
            onMouseUp={dragging ? this.onMouseUp : undefined}
            onMouseLeave={dragging ? this.onMouseUp : undefined}
            onTouchStart={this.onTouchStart}
            onTouchMove={dragging ? this.onTouchMove : undefined}
            onTouchEnd={dragging ? this.onTouchEnd : undefined}
          >
            {this.mapToBins(events, lower, upper)}
            {this.renderMonthMarks(lower, upper)}
            {this.renderNowMark(lower, upper)}
            <MeasureScale
              onScaleChange={perspectiveScale => this.setState({ perspectiveScale })}
            />
          </div>
        </div>
        <div className={styles.titlesContainer}>
          {runwayTitles.map((title, index) => {
            if (index < minLane || index > maxLane) return null;
            return <div className={styles.title}><span>{title}</span></div>;
          })}
          {this.renderLaneSwitchArrows()}
        </div>
        <div className={styles.sliderContainer}>
          <Slider
            defaultValue={!isNaN(sliderValue) ? sliderValue : 0}
            value={!isNaN(sliderValue) ? sliderValue : 0}
            onChange={this.onSliderChange}
            min={SLIDER_MIN}
            max={SLIDER_MAX}
          />
        </div>
      </div>
    );
  }
}

Runway.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
  bounds: PropTypes.shape({
    lower: PropTypes.instanceOf(Date),
    upper: PropTypes.instanceOf(Date),
  }).isRequired,
  getEvents: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  setRunwayBounds: PropTypes.func.isRequired,
};

Runway.defaultProps = {
  // events: [],
};
