import yup from 'yup';
import moment from 'moment';

export const isRequired = {
  name: true,
  description: false,
  guidanceNotes: false,
  priority: true,
  duration: false,
  estimatedDuration: true,
  availability: true,
  start: true,
  end: true,
  category: true,
  subCategory: true,
  teamAssigned: true,
};

export default yup.object().shape({
  name: yup
    .string()
    .label('Name')
    .required(),
  description: yup.string().label('Description'),
  guidanceNotes: yup.string().label('Guidance notes'),
  priority: yup
    .number()
    .label('Priority')
    .required(),
  duration: yup.number(),
  scheduleType: yup
    .string()
    .oneOf(['auto', 'specific'])
    .required(),
  start: yup
    .date()
    .label('Start date & time')
    .when(
      'scheduleType',
      (scheduleType, schema) => (scheduleType === 'specific' ? schema.required() : schema),
    ),
  end: yup.date().when(['start', 'scheduleType'], (start, scheduleType) => {
    const schema = start && moment(start).isValid() ? yup.date().min(start) : yup.date();
    return scheduleType === 'specific' ? schema.required() : schema;
  }),
  estimatedDuration: yup
    .number()
    .label('Estimated duration')
    .when(
      'scheduleType',
      (scheduleType, schema) => (scheduleType === 'auto' ? schema.required() : schema),
    ),
  availability: yup
    .string()
    .label('Time band')
    .when(
      'scheduleType',
      (scheduleType, schema) => (scheduleType === 'auto' ? schema.required() : schema),
    ),
  category: yup.string().required(),
  subCategory: yup.string().required(),
  teamAssigned: yup
    .array()
    .of(yup.string())
    .label('Team assigned')
    .required(),
});
