import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import Tether from 'react-tether';

import { EventCard } from './EventsGrid';

import useHover from '../hooks/useHover';

import styles from '../styles/eventsCalendar.module.scss';

import backIcon from '../assets/images/calendar-back@2x.png';
import forwardIcon from '../assets/images/calendar-forward@2x.png';

const uniqueFilter = (v, i, a) => a.indexOf(v) === i;

const bandColors = [
  '#fb7ea4',
  '#9ddcdc',
  '#cca8e9',
  '#ffaa64',
  '#3490de',
  '#574b90',
  '#7dace4',
];
const getBandColor = str => bandColors[str.length % bandColors.length];

function transformEventForCard(event) {
  let {
    name, createdBy = {}, type, tasks, priority, start, end,
  } = event;
  const { firstName, lastName } = createdBy;
  type = type || {};
  const { name: category } = type;
  const teams = flatMap(tasks, task => task.teamAssigned.map(team => get(team, 'name')))
    .filter(Boolean)
    .filter(uniqueFilter)
    .join(', ');
  return {
    ...event,
    name,
    firstName,
    lastName,
    priority,
    category,
    teams,
    start,
    end,
  };
}

function EventBand({ event }) {
  const [hoverRef, tooltipRef, isHovered] = useHover();
  return (
    <Tether
      attachment="top center"
      targetAttachment="top right"
      constraints={[
        {
          to: 'scrollParent',
          attachment: 'together',
        },
      ]}
      style={{ zIndex: 150 }}
    >
      <div className={styles.eventBand} style={{ width: event.width, backgroundColor: getBandColor(event.name) }} ref={hoverRef}>
        <div className={styles.iconContainer} />
        <p>{event.name}</p>
      </div>
      <div className={styles.card} ref={tooltipRef}>
        {isHovered && <EventCard {...event} />}
      </div>
    </Tether>
  );
}

export default function EventsCalendar({ events, getEvents }) {
  useEffect(() => {
    getEvents();
  }, []);
  const [type, setType] = useState('month');
  const [date, setDate] = useState(moment
    .utc()
    .startOf('month')
    .toDate());

  const start = moment.utc(date);
  const end = moment.utc(date).endOf(type);
  const startOfWeek = start.clone().startOf('isoWeek');
  const endOfWeek = end.clone().endOf('isoWeek');
  const diff = endOfWeek.diff(startOfWeek, 'day');

  const goForward = () =>
    setDate(start
      .clone()
      .add(1, type)
      .toDate());
  const goBack = () =>
    setDate(start
      .clone()
      .subtract(1, type)
      .toDate());

  let weekEvents = [];
  if (type === 'week') {
    const eventsInEachDay = [...Array(diff + 1)].map((v, i) => {
      const day = startOfWeek.clone().add(i, 'day');
      return events
        .filter(event =>
          moment.utc(event.start).isSameOrBefore(day, 'day') &&
            moment.utc(event.end).isSameOrAfter(day, 'day'))
        .sort((a, b) => new Date(b.start) - new Date(a.start));
    });
    eventsInEachDay.forEach((eventsInDay, index) => {
      const day = startOfWeek.clone().add(index, 'day');
      if (index === 0) {
        weekEvents = weekEvents.concat(eventsInDay.map((event, i) => {
          const spanToEnd = -1 * day.diff(moment.utc(event.end), 'day');
          const spanToEndOfWeek = -1 * day.diff(day.clone().endOf('isoWeek'), 'day');
          return {
            row: i,
            col: 0,
            span: Math.min(spanToEnd, spanToEndOfWeek) + 1,
            event,
          };
        }));
      } else {
        const topRow = Math.max(...eventsInDay
          .filter(event => weekEvents.some(e => e.event._id === event._id))
          .map((event) => {
            const position = weekEvents.find(e => e.event._id === event._id);
            return position.row;
          }));
        eventsInDay
          .filter(event => !weekEvents.some(e => e.event._id === event._id))
          .forEach((event, i) => {
            const spanToEnd = -1 * day.diff(moment.utc(event.end), 'day');
            const spanToEndOfWeek = -1 * day.diff(day.clone().endOf('isoWeek'), 'day');
            weekEvents = weekEvents.concat({
              row: topRow === -Infinity ? i : topRow + i + 1,
              col: index,
              span: Math.min(spanToEnd, spanToEndOfWeek) + 1,
              event,
            });
          });
      }
    });
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Events Calendar</h2>
      <div className={styles.calendarContainer}>
        <div className={styles.header}>
          <div className={styles.controls}>
            <div className={styles.button} onClick={goBack}>
              <img src={backIcon} />
            </div>
            <div className={styles.button} onClick={goForward}>
              <img src={forwardIcon} />
            </div>
          </div>
          <div className={styles.date}>{start.format('MMMM YYYY')}</div>
          <div className={styles.tabs}>
            <div
              className={cx(styles.tab, type === 'month' && styles.active)}
              onClick={() => {
                setType('month');
                setDate(start
                    .clone()
                    .startOf('month')
                    .toDate());
              }}
            >
              Month
            </div>
            <div
              className={cx(styles.tab, type === 'week' && styles.active)}
              onClick={() => setType('week')}
            >
              Week
            </div>
          </div>
        </div>
        <div className={styles.dayNames}>
          {[...Array(7)].map((v, i) => (
            <div className={styles.weekDayName}>
              {startOfWeek
                .clone()
                .add(i, 'day')
                .format('dddd')}
              {type === 'week' && (
                <span>
                  {startOfWeek
                    .clone()
                    .add(i, 'day')
                    .format('D.M.YYYY')}
                </span>
              )}
            </div>
          ))}
        </div>
        {type === 'month' ? (
          <div className={styles.calendar}>
            {[...Array(diff + 1)].map((v, i) => {
              const day = startOfWeek.clone().add(i, 'day');
              const eventsOnDay = events.filter(event =>
                moment.utc(event.start).isSame(day, 'day'));
              const eventsContinuingFromWeekBefore = day.isSame(
                day.clone().startOf('isoWeek'),
                'day',
              )
                ? events.filter(event =>
                      moment.utc(event.start).isBefore(day, 'day') &&
                      moment.utc(event.end).isSameOrAfter(day, 'day'))
                : [];

              const eventsToDisplay = eventsOnDay
                .concat(eventsContinuingFromWeekBefore)
                .map((event) => {
                  const spanToEnd = -1 * day.diff(moment.utc(event.end), 'day');
                  const spanToEndOfWeek = -1 * day.diff(day.clone().endOf('isoWeek'), 'day');
                  const width = `calc(${(Math.min(spanToEnd, spanToEndOfWeek) + 1) * 100}% - 20px)`;
                  return { ...event, width };
                });
              return (
                <div className={cx(styles.day, !day.isSame(start, type) && styles.notInWeek)}>
                  <span className={moment.utc().isSame(day, 'day') && styles.today}>
                    {day.format('D')}
                  </span>
                  {eventsToDisplay.map(transformEventForCard).map(event => (
                    <EventBand event={event} />
                  ))}
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.week}>
            {weekEvents.map(event => (
              <div
                className={styles.weekEventContainer}
                style={{
                  top: 106 * event.row,
                  left: `calc(100% / 7 * ${event.col})`,
                  width: `calc(100% / 7 * ${event.span})`,
                }}
              >
                <div className={styles.weekEvent}>
                  <div className={styles.iconContainer} />
                  {event.event.name}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
