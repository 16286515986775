import React from 'react';
import cx from 'classnames';

import { useSpring, animated } from 'react-spring/hooks';

import styles from '../../styles/charts/inlineProgress.module.scss';

export default function InlineProgress({ className, color, total, value }) {
  const valueProps = useSpring({ width: `${value / total * 100}%`, from: { width: '0%' } });
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.total}>
        <animated.div className={styles.progress} style={valueProps} />
      </div>
      <span>{value}/{total}</span>
    </div>
  );
}
