import yup from 'yup';

export const isRequired = {
  name: true,
  description: false,
  type: true,
  purpose: true,
  start: true,
  end: true,
  state: false,
  priority: true,
};

export default yup.object().shape({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description'),
  type: yup.string().label('Event type').required(),
  purpose: yup.string().label('Event purpose').required(),
  start: yup.date().label('Start date').required(),
  end: yup.date().label('End date').required(),
  priority: yup.number().label('Priority').required(),
});
