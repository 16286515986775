import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import useOnClickOutside from '../hooks/useOnClickOutside';
import styles from '../styles/navigation.module.scss';

import dashboardLogo from '../assets/images/dashboard-logo@3x.png';
import searchIcon from '../assets/images/dashboard-search-icon@3x.png';
import sampleAvatar from '../assets/images/sample-avatar@3x.jpg';
import switchUserIcon from '../assets/images/user-menu-switch-user-icon@3x.png';

const tabsConfig = [
  {
    title: 'Plan',
    link: '/dashboard/plan',
    icon: require('../assets/images/dashboard-plan-icon@3x.png'),
    activeIcon: require('../assets/images/dashboard-plan-icon-active@3x.png'),
  },
  {
    title: 'Timeline',
    link: '/dashboard',
    icon: require('../assets/images/dashboard-timeline-icon@3x.png'),
    activeIcon: require('../assets/images/dashboard-timeline-icon-active@3x.png'),
  },
  {
    title: 'Report',
    link: '/dashboard/report',
    icon: require('../assets/images/dashboard-report-icon@3x.png'),
    activeIcon: require('../assets/images/dashboard-report-icon-active@3x.png'),
  },
];

const dropdownConfig = [
  {
    icon: require('../assets/images/user-menu-store-profile-icon@3x.png'),
    title: 'Store Profile',
    link: '#',
  },
  {
    icon: require('../assets/images/user-menu-settings-icon@3x.png'),
    title: 'Settings',
    link: '#',
  },
];

export default function Navigation({ logout, noTabBar, isManager }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setDropdownOpen(false));

  const dropdownWithLogout = dropdownConfig.concat({
    icon: switchUserIcon,
    title: 'Switch User',
    onClick: logout,
  });

  const tabs = tabsConfig.filter(tab => isManager || tab.title !== 'Plan');

  return (
    <div className={cx(styles.container, noTabBar && styles.noTabBarContainer)}>
      <div className={styles.bar}>
        <div className={styles.logo}>
          <img src={dashboardLogo} />
        </div>
        <div className={styles.barItems}>
          <div className={styles.searchContainer}>
            <img src={searchIcon} />
            <input type="text" placeholder="Search" />
          </div>
          <div className={styles.userMenuContainer} ref={dropdownRef}>
            <img src={sampleAvatar} className={styles.avatar} />
            <button
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              John
            </button>
            <div className={cx(styles.dropdownContainer, dropdownOpen && styles.show)}>
              <div className={styles.dropdownMenu}>
                {dropdownWithLogout.map(({ link, title, icon, ...rest }) => (
                  <a href={link || '#'} {...rest}>
                    <img src={icon} />
                    {title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!noTabBar && (
        <div className={styles.tabs}>
          {tabs.map(tab => (
            <NavLink
              exact
              key={tab.name}
              className={styles.tab}
              activeClassName={styles.active}
              to={tab.link}
            >
              <img src={tab.icon} className={styles.tabIcon} />
              <img src={tab.activeIcon} className={styles.tabActiveIcon} />
              {tab.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  noTabBar: PropTypes.bool,
  isManager: PropTypes.bool,
};

Navigation.defaultProps = {
  noTabBar: false,
  isManager: false,
};
