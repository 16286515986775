const data = RNG => [
  [
    {
      id: 'datum0',
      data: [
        {
          x: 'Day 1',
          y: RNG(40, 200),
        },
        {
          x: 'Day 2',
          y: RNG(40, 200),
        },
        {
          x: 'Day 3',
          y: RNG(40, 200),
        },
        {
          x: 'Day 4',
          y: RNG(40, 200),
        },
        {
          x: 'Day 5',
          y: RNG(40, 200),
        },
      ],
    },
  ],
  [
    {
      id: 'datum1',
      data: [
        {
          x: 'Day 1',
          y: RNG(40, 200),
        },
        {
          x: 'Day 2',
          y: RNG(40, 200),
        },
        {
          x: 'Day 3',
          y: RNG(40, 200),
        },
        {
          x: 'Day 4',
          y: RNG(40, 200),
        },
        {
          x: 'Day 5',
          y: RNG(40, 200),
        },
      ],
    },
  ],
  [
    {
      id: 'datum2',
      data: [
        {
          x: 'Day 1',
          y: RNG(40, 200),
        },
        {
          x: 'Day 2',
          y: RNG(40, 200),
        },
        {
          x: 'Day 3',
          y: RNG(40, 200),
        },
        {
          x: 'Day 4',
          y: RNG(40, 200),
        },
        {
          x: 'Day 5',
          y: RNG(40, 200),
        },
      ],
    },
  ],
  [
    {
      id: 'datum3',
      data: [
        {
          x: 'Day 1',
          y: RNG(40, 200),
        },
        {
          x: 'Day 2',
          y: RNG(40, 200),
        },
        {
          x: 'Day 3',
          y: RNG(40, 200),
        },
        {
          x: 'Day 4',
          y: RNG(40, 200),
        },
        {
          x: 'Day 5',
          y: RNG(40, 200),
        },
      ],
    },
  ],
];

export default data;
