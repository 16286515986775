const data = [
  {
    title: 'Day',
    data: [
      {
        trend: 'down',
        name: 'James Bellamy',
        tasks: 248,
        complete: 215,
        points: 1405,
      },
      {
        trend: 'up',
        name: 'Jenny Pound',
        tasks: 250,
        complete: 220,
        points: 1552,
      },
      {
        trend: 'up',
        name: 'Jane Mortimer',
        tasks: 200,
        complete: 165,
        points: 1005,
      },
      {
        trend: 'same',
        name: 'Tim Stafford',
        tasks: 330,
        complete: 225,
        points: 1200,
      },
      {
        trend: 'up',
        name: 'Fred Barnes',
        tasks: 150,
        complete: 130,
        points: 1120,
      },
    ],
  },
  {
    title: 'Week',
    data: [
      {
        trend: 'down',
        name: 'James Bellamy',
        tasks: 248,
        complete: 215,
        points: 1405,
      },
      {
        trend: 'same',
        name: 'Tim Stafford',
        tasks: 330,
        complete: 225,
        points: 1200,
      },
      {
        trend: 'up',
        name: 'Jane Mortimer',
        tasks: 200,
        complete: 165,
        points: 1005,
      },
      {
        trend: 'up',
        name: 'Fred Barnes',
        tasks: 150,
        complete: 130,
        points: 1120,
      },
      {
        trend: 'up',
        name: 'Jenny Pound',
        tasks: 250,
        complete: 220,
        points: 1552,
      },
    ],
  },
  {
    title: 'Month',
    data: [
      {
        trend: 'up',
        name: 'Jenny Pound',
        tasks: 250,
        complete: 220,
        points: 1552,
      },
      {
        trend: 'down',
        name: 'James Bellamy',
        tasks: 248,
        complete: 215,
        points: 1405,
      },
      {
        trend: 'same',
        name: 'Tim Stafford',
        tasks: 330,
        complete: 225,
        points: 1200,
      },
      {
        trend: 'up',
        name: 'Fred Barnes',
        tasks: 150,
        complete: 130,
        points: 1120,
      },
      {
        trend: 'up',
        name: 'Jane Mortimer',
        tasks: 200,
        complete: 165,
        points: 1005,
      },
    ],
  },
];

export default data;
