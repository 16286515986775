import React from 'react';
import cxx from 'classnames';

import ConcentricDonuts from './charts/ConcentricDonuts';
import InlineIndicator from './charts/InlineIndicator';
import InlineDonut from './charts/InlineDonut';
import CurvedArea from './charts/CurvedArea';
import TrendTicker from './TrendTicker';

import taskPaceGraphData from '../data/taskPaceGraphData';

import pointsInProgressIcon from '../assets/images/points-in-progress-icon@3x.png';
import behindScheduleIcon from '../assets/images/behind-schedule-icon@3x.png';
import alertsIcon from '../assets/images/alerts-icon@3x.png';

import styles from '../styles/coreOperationsCompliance.module.scss';

function keyToTitle(key) {
  return key
    .replace(
      /([A-Z][a-z]*)/g,
      ([first, ...letters]) => ` ${first.toLowerCase()}${letters.join('')}`,
    )
    .replace(/(^[a-z])/, ([first]) => first.toUpperCase());
}

function TodaysReport() {
  const isMobile = false;

  const colors = {
    overdue: '#bdc1c6',
    completed: '#4f80f7',
    criticalTaskCompleted: '#e53c5c',
  };

  const dummyData = {
    overdue: 120,
    completed: 60,
    criticalTaskCompleted: 30,
  };

  const bigRow = (
    <tr className={styles.headerBig}>
      <td>250</td>
      {isMobile && <td className={styles.filler} />}
      <td>00:55m</td>
      {isMobile && <td className={styles.filler} />}
      <td>101</td>
    </tr>
  );

  const smallRow = (
    <tr className={styles.headerSmall}>
      <td>Overdue Tasks</td>
      <td>Behind Schedule</td>
      <td>Escalations</td>
    </tr>
  );

  return (
    <div className={styles.todaysReport}>
      <div className={styles.todaysReportHeader}>
        <table className={cxx(styles.reportTable, styles.todaysReportTable)}>
          <tr className={styles.icons}>
            <td>
              <img src={pointsInProgressIcon} />
            </td>
            <td>
              <img src={behindScheduleIcon} />
            </td>
            <td>
              <img src={alertsIcon} />
            </td>
          </tr>
          {bigRow}
          {smallRow}
        </table>
      </div>
      <div className={styles.todaysReportChart}>
        <div className={styles.legend}>
          <table className={styles.reportTable}>
            {Object.entries(dummyData).map(([key, value]) => (
              <tr className={styles.icons}>
                <td>
                  <InlineIndicator color={colors[key]} />
                </td>
                <td className={styles.title}>{keyToTitle(key)}</td>
                <td>{value}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className={styles.chartContainer}>
          <ConcentricDonuts
            animated
            className={styles.chart}
            total={250}
            innerValue={dummyData.completed}
            outerValue={dummyData.overdue}
            innerColor="#e53c5c"
            outerColor="#4f80f7"
          />
        </div>
      </div>
    </div>
  );
}

export default function CoreOperationsCompliance() {
  return (
    <div className={styles.container}>
      <div className={styles.titleRow}>
        <h2>Core Operations Compliance</h2>
      </div>
      <div className={styles.firstRow}>
        <div className={styles.summary}>
          <div className={styles.title}>Hours Saved</div>
          <div className={styles.number}>42</div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Sales Reclaimed</div>
          <div className={styles.number}>$5k</div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Productivity</div>
          <div className={styles.number}>
            4%
            <TrendTicker className={styles.ticker} up text="5.9%" />
          </div>
        </div>
        <div className={styles.summary}>
          <div className={styles.title}>Average time taken to Complete</div>
          <div className={styles.number}>
            27 mins
            <TrendTicker className={styles.ticker} down text="2m" />
          </div>
        </div>
      </div>
      <div className={styles.secondRow}>
        <TodaysReport />
        <div className={styles.box}>
          <h3 className={styles.boxHeading}>Top Areas</h3>
          <h4 className={styles.boxSubHeading}>Overview</h4>
          <div className={styles.topAreas}>
            <div className={styles.row}>
              <div className={styles.name}>London</div>
              <TrendTicker up text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Manchester</div>
              <TrendTicker down text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>South West</div>
              <TrendTicker up text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Anglesey</div>
              <TrendTicker down text="5.9%" />
            </div>
            <div className={styles.row}>
              <div className={styles.name}>Soffolk</div>
              <TrendTicker up text="5.9%" />
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <h4 className={styles.boxHeading}>Overdue Tasks</h4>
          <div className={styles.overdueTasks}>
            <div className={styles.legend}>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Warning</span>
              </div>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Critical</span>
              </div>
            </div>
            <div className={styles.table}>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Fire Route Check</span>
              </div>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Alcohol Training</span>
              </div>
              <div>
                <InlineIndicator color="#ffda83" />
                <span>Clean Slicer</span>
              </div>
              <div>
                <InlineIndicator color="#ff7576" />
                <span>Till Cash Run</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.thirdRow}>
        <div className={styles.taskPaceContainer}>
          <div className={styles.sidebar}>
            <h3 className={styles.boxHeading}>Task Pace</h3>
            <h4 className={styles.boxSubHeading}>Points/hour</h4>
            <TrendTicker up text="5.9%" className={styles.ticker} />
          </div>
          <div className={styles.graph}>
            <CurvedArea data={taskPaceGraphData} width={252} height={147} />
          </div>
        </div>
        <div className={styles.box}>
          <h3 className={styles.boxHeading}>Utilisation (%)</h3>
          <h4 className={styles.boxSubHeading}>Percentage</h4>
          <div className={styles.utilisationContainer}>
            <InlineDonut
              showPercent
              className={styles.utilisationDonut}
              value={71}
              total={100}
              subtitle="This month"
              strokeWidth="2"
              animated
            />
            <InlineDonut
              showPercent
              className={styles.utilisationDonut}
              value={67}
              total={100}
              subtitle="Last month"
              color="#10d592"
              strokeWidth="2"
              animated
            />
          </div>
        </div>
        <div className={styles.rankContainer}>
          <div className={styles.rank}>
            <div className={styles.heading}>Store Rank</div>
            <div className={styles.number}>25/176</div>
            <div className={styles.type}>Gold</div>
          </div>
          <div className={styles.rank}>
            <div className={styles.heading}>Area Rank</div>
            <div className={styles.number}>25/175</div>
            <div className={styles.type}>Gold</div>
          </div>
        </div>
      </div>
    </div>
  );
}
