import taskOperationalIcon from '../assets/images/task-operational.svg';
import taskPromotionalIcon from '../assets/images/task-promotional.svg';
import taskCommercailIcon from '../assets/images/task-commercial.svg';
import taskLocalIcon from '../assets/images/task-local.svg';
import taskSeasonalIcon from '../assets/images/task-seasonal.svg';
import taskSpecialIcon from '../assets/images/task-special.svg';

const types = [
  'Operational',
  'Promotional',
  'Commercial',
  'Local',
  'Seasonal',
  'Special',
];

const icons = {
  Operational: taskOperationalIcon,
  Promotional: taskPromotionalIcon,
  Commercial: taskCommercailIcon,
  Local: taskLocalIcon,
  Seasonal: taskSeasonalIcon,
  Special: taskSpecialIcon,
};

const colors = {
  Operational: '#636363',
  Promotional: '#2699fb',
  Commercial: '#10d592',
  Local: '#a3a1fb',
  Seasonal: '#56d9fe',
  Special: '#fec656',
};

export const getType = name => types[(name.length % types.length)];

export const getTypeImage = name => icons[getType(name)];

export const getTypeColor = name => colors[getType(name)];
