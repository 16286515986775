import { createSelector } from 'reselect';

export const getFormsEvents = state => state.formsEvents;

export const getIsPostingEvent = createSelector(getFormsEvents, events => events.postingEvent);

export const getPostingEventError = createSelector(
  getFormsEvents,
  events => events.postingEventError,
);

export const getTasks = createSelector(
  getFormsEvents,
  events => events.tasks,
);
