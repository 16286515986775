import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Route } from 'react-router-dom';
import 'react-dates/initialize';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './styles/fonts.scss';

import store, { history, persistor } from './config/store';
import { basename } from './config/hosts';

import Home from './containers/Home';
import Dashboard from './containers/Dashboard';

function App() {
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ConnectedRouter history={history} basename={basename}>
          <>
            <Route exact path="/" component={Home} />
            <Route path="/dashboard" render={({ ...props }) => <Dashboard {...props} />} />
          </>
        </ConnectedRouter>
      </Provider>
    </PersistGate>
  );
}

export default App;
