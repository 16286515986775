import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

export default class EventModal extends Component {
  constructor(props) {
    super(props);
    this.postPlanTrackStart = this.postPlanTrackStart.bind(this);
    this.postPlanTrackEnd = this.postPlanTrackEnd.bind(this);
    this.setElapsed = this.setElapsed.bind(this);
    this.reset = this.reset.bind(this);
    this.setup = this.setup.bind(this);
    this.state = { elapsed: undefined, complete: false };
  }

  componentDidMount() {
    this.setup(this.props, true);
  }

  componentWillReceiveProps(nextProps) {
    this.setup(nextProps);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setup(props, first = false) {
    const { planTrack, task } = props;
    if (!task) {
      this.reset();
      return;
    }
    if (!first && this.props.task && task._id === this.props.task._id) return;
    this.reset();
    const start = get(planTrack, [task._id, 'start']);
    const end = get(planTrack, [task._id, 'end']);
    if (start && end) {
      this.setState(() => ({ complete: true }));
    } else if (start) {
      this.setElapsed(props, new Date() - new Date(start));
    } else {
      this.postPlanTrackStart(props);
      this.setElapsed(props);
    }
  }

  setElapsed(props, diff) {
    let elapsed = 0;
    let remaining = 1000;
    if (diff) {
      elapsed = Math.floor(diff / (1000));
      remaining = (1000) - Math.round(diff % (1000));
    }
    if (this.state.elapsed !== undefined) {
      this.setState(state => this.setState({ elapsed: state.elapsed + 1 }));
    } else {
      this.setState({ elapsed });
    }
    this.timer = setTimeout(() => this.setElapsed(this.props), remaining);
  }

  reset() {
    this.setState({ elapsed: undefined, complete: false });
    if (this.timer) clearTimeout(this.timer);
  }

  postPlanTrackStart(props) {
    const {
      planTrack, task, dayKey,
    } = props;
    planTrack[task._id] = { start: new Date() };
    this.props.postPlanTrack(dayKey, planTrack);
  }

  postPlanTrackEnd({ completed, incompleteReason }) {
    const {
      planTrack, postPlanTrack, task, dayKey,
    } = this.props;
    clearTimeout(this.timer);
    planTrack[task._id] = {
      ...planTrack[task._id],
      end: new Date(),
      completed,
      incompleteReason,
    };
    postPlanTrack(dayKey, planTrack);
    this.setState({ complete: true });
  }

  render() {
    const { task, children } = this.props;
    const { elapsed, complete } = this.state;
    const args = {
      complete,
      elapsed,
      finish: options => this.postPlanTrackEnd(options),
    };

    return children(args);
  }
}

EventModal.propTypes = {
  task: PropTypes.object,
  planTrack: PropTypes.object,
  deselectTask: PropTypes.func.isRequired,
  postPlanTrack: PropTypes.func.isRequired,
  dayKey: PropTypes.string,
  isModal: PropTypes.bool,
};

EventModal.defaultProps = {
  planTrack: {},
  isModal: true,
};
