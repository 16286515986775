import { connect } from 'react-redux';

import EventModal from '../components/EventModal';

import { deselectEvent } from '../modules/user/actions';
import * as userSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    event: userSelectors.getSelectedEvent(state),
  };
}

const mapDispatchToProps = {
  deselectEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventModal);
