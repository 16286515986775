import { routerReducer } from 'react-router-redux';

import user from './user';
import formsChoices from './forms/choices';
import formsEvents from './forms/events';

export default {
  user,
  formsChoices,
  formsEvents,
  router: routerReducer,
};
