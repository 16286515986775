import { connect } from 'react-redux';

import EventPerformance from '../components/EventPerformance';

import { getEvents } from '../modules/user/actions';
import * as userSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    events: userSelectors.getEvents(state),
  };
}

const mapDispatchToProps = {
  getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPerformance);
