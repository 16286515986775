import React from 'react';
import { Line } from '@nivo/line';

export default function LinearArea({
  width,
  height,
  data,
  lineWidth = 0,
  gradient = ['#03b8cb', '#3e8df1'],
  gradientOpacity = 1,
}) {
  return (
    <>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor={gradient[0]} stopOpacity={gradientOpacity} />
            <stop offset="100%" stopColor={gradient[1]} stopOpacity={gradientOpacity} />
          </linearGradient>
        </defs>
      </svg>
      <Line
        width={width}
        height={height}
        data={data}
        colors={['url(#gradient)']}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          stacked: true,
          min: 0,
          max: 'auto',
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          legend: '',
          legendOffset: 1,
          legendPosition: 'middle',
        }}
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        areaOpacity={1}
        enableGridX={false}
        lineWidth={lineWidth}
        enableDots={false}
        enableArea
        motionStiffness={90}
        motionDamping={15}
        isInteractive={false}
        enableStackTooltip={false}
        legends={[]}
        animate
      />
    </>
  );
}
