import { useRef, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export default function useHover() {
  const [hovered, setHovered] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState(false);

  const ref = useRef(null);
  const tooltipRef = useRef(null);

  const setHoveredD = debounce(setHovered, 100);

  const handleMouseOver = () => setHoveredD(true);
  const handleMouseOut = () => setHoveredD(false);

  const handleMouseOverTooltip = () => setTooltipHovered(true);
  const handleMouseOutTooltip = () => setTooltipHovered(false);

  useEffect(
    () => {
      const node = ref.current;
      const tooltipNode = tooltipRef.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        if (tooltipNode) {
          tooltipNode.addEventListener('mouseover', handleMouseOverTooltip);
          tooltipNode.addEventListener('mouseout', handleMouseOutTooltip);
        }

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);

          if (tooltipNode) {
            tooltipNode.removeEventListener('mouseover', handleMouseOverTooltip);
            tooltipNode.removeEventListener('mouseout', handleMouseOutTooltip);
          }
        };
      }
    },
    [ref.current, tooltipRef.current],
  );

  return [ref, tooltipRef, hovered || tooltipHovered];
}
