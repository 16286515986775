import TYPES from './types';

export const initialState = {
  gettingChoices: false,
  gettingChoicesError: null,
};

export default function formsChoices(state = initialState, action) {
  switch (action.type) {
    case TYPES.gettingChoices: {
      return { ...state, gettingChoices: true, gettingChoicesError: null };
    }

    case TYPES.getChoicesSuccess: {
      const { choices } = action.payload;
      return {
        ...state,
        ...choices,
      };
    }

    case TYPES.getChoicesFail: {
      const { error } = action.payload;
      return {
        ...state,
        gettingChoices: false,
        gettingChoicesError: error,
      };
    }

    default: {
      return state;
    }
  }
}
