import { connect } from 'react-redux';

import TasksTimeline from '../components/TasksTimeline';

import { selectPlanDay, getPlanTrack, getPlan as getPlanAction, getEvents, getTasks, selectTask, deselectTask, setRunwayBounds } from '../modules/user/actions';
import * as userSelectors from '../modules/user/selectors';

function mapStateToProps(state) {
  return {
    events: userSelectors.getEvents(state),
    tasks: userSelectors.getTasks(state),
    bounds: userSelectors.getRunwayBounds(state),
    plan: userSelectors.getPlan(state),
    planTrack: userSelectors.getPlanTrack(state),
    dayKey: userSelectors.getSelectedPlanDayKey(state),
    selectedTask: userSelectors.getSelectedTask(state),
  };
}

const mapDispatchToProps = {
  getEvents,
  getTasks,
  setRunwayBounds,
  selectTask,
  deselectTask,
  selectPlanDay,
  getPlanTrack,
  getPlan: getPlanAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTimeline);
