import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';

import styles from '../styles/taskPanel.module.scss';

import closeIcon from '../assets/images/modal-close-icon@3x.png';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(87, 87, 87, 0.59)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 200,
    borderRadius: '5px',
  },
};

const mapWithComma = (item, index, items) => (index === items.length - 1 ? item.name : `${item.name}, `);

export default class EventModal extends Component {
  constructor(props) {
    super(props);
    this.bla = 0;
  }

  render() {
    const { event, deselectEvent } = this.props;

    if (!event) return null;

    const startDay = moment.utc(event.start).format('DD MMM');
    const endDay = moment.utc(event.end).format('DD MMM');

    return (
      <Modal
        className={styles.modal}
        isOpen={!!event}
        onRequestClose={deselectEvent}
        style={customStyles}
      >
        <div className={styles.header}>
          <div className={styles.timeBoxContainer}>
            <div className={styles.timeBox}>
              <h4>{event.eventId}</h4>
              <span>Code</span>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <h4>{event.name}</h4>
            <div className={styles.infoProgress}>
              <div className={styles.progress}>
                <div className={styles.progressBar}>Not started</div>
              </div>
              <div className={styles.remaining}>{`${startDay} - ${endDay}`}</div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          {event.type && (
            <div className={styles.item}>
              <div className={styles.title}>Event Type:</div>
              <div className={styles.content}>{event.type.name}</div>
            </div>
          )}
          {event.description && (
            <div className={styles.item}>
              <div className={styles.title}>Description:</div>
              <div className={styles.content}>{event.description}</div>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.title}>Stores Selected:</div>
            <div className={styles.content}>{event.storesSelected.map(mapWithComma)}</div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Tasks:</div>
            <div className={styles.content}>{event.tasks.map(mapWithComma)}</div>
          </div>
        </div>
        <div className={styles.closeButton} onClick={deselectEvent}>
          <img src={closeIcon} alt="Close Modal" />
        </div>
      </Modal>
    );
  }
}

EventModal.propTypes = {
  event: PropTypes.object,
  deselectEvent: PropTypes.func.isRequired,
};
