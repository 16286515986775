import React from 'react';
import cxx from 'classnames';

import styles from '../../styles/charts/barChart.module.scss';

export default function BarChart({
  data,
  className,
}) {
  const lines = [...Array(6).keys()].reverse().map(tick => tick * 20);
  return (
    <div className={cxx(styles.container, className)}>
      <div className={styles.grid}>
        {lines.map(line => (
          <div className={styles.line}>
            <div className={styles.number}>{line}</div>
            <div className={styles.border} />
          </div>
        ))}
      </div>
      <div className={styles.bars}>
        {data.map(({ color, value }) => (
          <div className={styles.bar} style={{ height: `${value * 5 / 6}%`, backgroundColor: color }} />
        ))}
      </div>
    </div>
  );
}
