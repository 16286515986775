import React from 'react';
import { Line } from '@nivo/line';

export default function CurvedArea({ width, height, data }) {
  return (
    <>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" stopColor="#03b8cb" stopOpacity="0.8" />
            <stop offset="100%" stopColor="#3e8df1" stopOpacity="0.8" />
          </linearGradient>
        </defs>
      </svg>
      <Line
        width={width}
        height={height}
        data={data}
        colors={['url(#gradient)', '#d5d5d5']}
        xScale={{
          type: 'point',
        }}
        yScale={{
          type: 'linear',
          stacked: false,
          min: 0,
          max: 'auto',
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          legend: '',
          legendOffset: 1,
          legendPosition: 'middle',
        }}
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        areaOpacity={1}
        enableGridX={false}
        lineWidth={0}
        enableDots={false}
        enableArea
        motionStiffness={90}
        motionDamping={15}
        isInteractive={false}
        enableStackTooltip={false}
        legends={[]}
        animate
      />
    </>
  );
}
