import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Tether from 'react-tether';
import Popover from 'react-popover';
import moment from 'moment';
import cx from 'classnames';

import styles from '../styles/taskPanel.module.scss';
import timelineStyles from '../styles/timeline.module.scss';
import planStyles from '../styles/plan.module.scss';

export default class Panel extends Component {
  constructor(props) {
    super(props);
    this.openPopover = this.openPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openModal = this.openModal.bind(this);
    this.state = {
      isPopoverOpen: false,
    };
  }

  openPopover() {
    this.setState({ isPopoverOpen: true });
  }

  closePopover() {
    this.setState({ isPopoverOpen: false });
  }

  openModal() {
    const { item, selectEvent } = this.props;
    selectEvent(item._id);
  }

  renderPopover() {
    const { item } = this.props;
    return [
      <div>
        <b>{item.name}</b>
      </div>,
      <div>
        {item.description
          ? <span>{item.description}</span>
          : <i>No description</i>
        }
      </div>,
      <div>
        <b>Start:</b>
        <span> {moment(item.start).format('HH:mm Do MMMM')}</span>
        <br />
        <b>End:</b>
        <span> {moment(item.end).format('HH:mm Do MMMM')}</span>
      </div>,
      <div>
        <Button block color="primary" className="popover-detail-button" onClick={this.openModal}>
          See Details
        </Button>
      </div>,
      <div className="popover-close-button-container">
        <Button
          outline
          color="secondary"
          size="sm"
          onClick={this.closePopover}
          className="popover-close-button"
        >
          Close
        </Button>
      </div>,
    ];
  }

  render() {
    const {
      key, top, item, transform, onMouseWheel, numLanes,
    } = this.props;
    const { isPopoverOpen } = this.state;
    const date = moment(item.end).format('MMM D, YYYY');
    const timeMoment = moment.utc(item.start);
    const zIndex = (31 * 24) - timeMoment.diff(timeMoment.clone().startOf('day'), 'hours');

    return (
      <Tether
        key={key}
        attachment="bottom center"
        constraints={[
          {
            to: 'scrollParent',
          },
        ]}
        style={{ zIndex }}
      >
        <Popover body={this.renderPopover()} isOpen={isPopoverOpen} preferPlace="above">
          <div ref={this.setTarget} className="runway-item-tether" style={{ top: `${top}%` }} />
        </Popover>
        {!isPopoverOpen && (
          <div onWheel={onMouseWheel}>
            <div className={cx(timelineStyles.taskPanel, timelineStyles[`runway-per-${numLanes}`])} style={{ transform }} onClick={this.openModal}>
              <div
                className={planStyles.taskHeader}
              >
                <span className={planStyles.taskHeaderTime}>{date}</span>
              </div>
              <span className={cx(planStyles.taskName, styles.taskName)}>{item.name}</span>
            </div>
          </div>
        )}
      </Tether>
    );
  }
}

Panel.propTypes = {
  key: PropTypes.string.isRequired,
  transform: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  onMouseWheel: PropTypes.func.isRequired,
  selectEvent: PropTypes.func.isRequired,
  deselectEvent: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};
