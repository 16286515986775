import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { slide as Sidebar } from 'react-burger-menu';
import classNames from 'classnames';

import EventForm from '../containers/EventForm';
import Events from '../containers/Events';
import TasksTimeline from '../containers/TasksTimeline';
import Plan from '../containers/Plan';
import Report from '../containers/Report';
import MyEvents from '../containers/MyEvents';
import AllEvents from '../containers/AllEvents';
import EventsCalendar from '../containers/EventsCalendar';
import EventPerformance from '../containers/EventPerformance';
import HQDashboard from '../containers/HQDashboard';
import CoreOperationsCompliance from './CoreOperationsCompliance';
import EventsCompliance from './EventsCompliance';
import Navigation from './Navigation';
import HQSidebar from './HQSidebar';

import hqStyles from '../styles/hq.module.scss';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.mql = window.matchMedia('(max-width: 760px)');
    this.state = { dashboardOpen: false, isMobile: this.mql.matches, hqSidebarOpen: false };
  }

  componentDidMount() {
    this.mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    this.mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.setState({ isMobile: this.mql.matches });
  }

  render() {
    const {
      logout, accountType, location,
    } = this.props;
    const { dashboardOpen, isMobile, hqSidebarOpen } = this.state;

    const needsOverflowAuto = ['/dashboard/events/new', '/dashboard/events/my'];

    const isAddEventScreen = needsOverflowAuto.indexOf(location.pathname) > -1;
    const isHQ = accountType === 'hq';
    const isManager = accountType === 'store';

    const content = (
      <Fragment>
        <Route
          exact
          path="/dashboard"
          render={() => {
            switch (accountType) {
              case 'hq':
                return <HQDashboard />;
              default:
                return <TasksTimeline />;
            }
          }}
        />
        <Route path="/dashboard/plan" render={() => <Plan />} />
        <Route path="/dashboard/report" render={() => <Report />} />
        <Route path="/dashboard/events/new" component={EventForm} />
        <Route path="/dashboard/events/my" component={MyEvents} />
        <Route path="/dashboard/events/all" component={AllEvents} />
        <Route path="/dashboard/events/calendar" component={EventsCalendar} />
        <Route path="/dashboard/reports/performance" component={EventPerformance} />
        <Route path="/dashboard/reports/coreopscompliance" component={CoreOperationsCompliance} />
        <Route path="/dashboard/reports/compliance" component={EventsCompliance} />
        <Route path="/dashboard/timeline" component={Events} />
      </Fragment>
    );

    if (isHQ) {
      if (!isMobile) {
        return (
          <div className={hqStyles.hqViewWrapper}>
            <Navigation noTabBar logout={logout} />
            <div className={hqStyles.hqView}>
              <HQSidebar />
              <div className={classNames(hqStyles.contentContainer, isAddEventScreen && hqStyles.addEvent)}>
                {content}
              </div>
            </div>
          </div>
        );
      }

      return (
        <Fragment>
          <Sidebar pageWrapId="dashboard-container" isOpen={hqSidebarOpen}>
            <HQSidebar />
          </Sidebar>
          <div className="dashboard-container">
            <Navigation noTabBar logout={logout} isManager={isManager} />
            {content}
          </div>
        </Fragment>
      );
    }

    return (
      <div className="dashboard-container">
        <Navigation logout={logout} isManager={isManager} />
        {content}
      </div>
    );
  }
}

Dashboard.propTypes = {
  logout: PropTypes.func.isRequired,
  accountType: PropTypes.string.isRequired,
};
